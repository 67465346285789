import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "vuetify/dist/vuetify.min.css";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import Vuetify from "vuetify";
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi" // default - only for display purposes
  },
  // theme: {
  //   themes: {
  //     light: {
  //       primary: process.env.VUE_APP_STYLE_COLORS_PRIMARY,
  //       secondary: process.env.VUE_APP_STYLE_COLORS_SECONDARY,
  //       error: process.env.VUE_APP_STYLE_COLORS_ERROR,
  //       info: process.env.VUE_APP_STYLE_COLORS_INFO,
  //       success: process.env.VUE_APP_STYLE_COLORS_SUCCESS,
  //       warning: process.env.VUE_APP_STYLE_COLORS_WARNING,
  //       btConfirma: process.env.VUE_APP_STYLE_COLORS_BT_CONFIRMA,
  //       btCancela: process.env.VUE_APP_STYLE_COLORS_BT_CANCELA,
  //       cardRecebiveis: process.env.VUE_APP_STYLE_COLORS_CARD_RECEBIVEIS,
  //       cardVendas: process.env.VUE_APP_STYLE_COLORS_CARD_VENDAS,
  //       cardEstornos: process.env.VUE_APP_STYLE_COLORS_CARD_ESTORNOS,
  //       cardFalhas: process.env.VUE_APP_STYLE_COLORS_CARD_FALHAS
  //     }
  //   }
  // }
});
