<template>
    <v-container>
      <DialogDadosClientePagamento
          v-if="step === 1"
          titulo="Dados para Gerar Boleto"
          :show="showDialog"
          :cliente="cobranca?.cliente"
          :isBoleto="true"
          @dadosCliente="clienteInformado"
          @fechar-dialog="cancela"
      />
        <v-dialog v-if="step === 2" v-model="showDialog" max-width="600" persistent>
            <form @submit.prevent="addEmail">
                <v-card>
                    <v-card-title class="d-flex justify-space-between">
                        Pagamento com Boleto
                        <v-btn fab small text @click="cancela">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row dense>
                        <v-col> Valor: R$ {{ $masks.dinheiro( cobranca.valor_total.toFixed(2) ) }} </v-col>
                        <v-col> 1º Vencimento: {{cobranca.data_vencimento_boleto | formatDate({ dateStyle: "short" })  }}</v-col>
                        </v-row>
                        <v-row>
                        <v-col>
                            Juros: {{ cobranca.juros_atraso_boleto }}%
                        </v-col>
                        <v-col>
                            Multa: {{ cobranca.multa_atraso_boleto }}%
                        </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <span class="font-weight-bold">Quantidade de parcelas</span>
                            </v-col>
                            <v-col>
                                <Dropdown v-model="parcelas_boleto" :options="getParcelasDisponiveisBoleto"
                                          optionLabel="text" optionValue="value"  />
                            </v-col>
                        </v-row>
                        <v-row>
                        <v-col>
                            <span style="display:block;" class="font-weight-bold">Adicione os destinatários do boleto abaixo separando por virgula.</span>
                        </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <Chips v-model="emailsBoleto" separator=","  @add="addEmail" />
                            </v-col>

                        </v-row>
                        <v-card-actions class="mt-4">
                            <v-col class="center">
                                <v-btn color="green" width="100%" max-width="20rem"
                                        :loading="loading" :disabled="loading"
                                        class="white--text" @click="gerar">
                                    Gerar Boleto
                                </v-btn>
                            </v-col>

                        </v-card-actions>
                    </v-card-text>
                </v-card>
            </form>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
import DialogDadosClientePagamento from "@/components/Dialogs/DialogDadosClientePagamento.vue";


export default {
    name: "DialogGeraBoleto",
    components: {
        Dropdown, Chips, DialogDadosClientePagamento
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        cobranca: {
            type: Object,
            default: () => ({})
        },
        cancela: Function
    },
    data(){
       return {
        step: null,
        parcelas_boleto: 1,
        emailsBoleto: [],
        loading:false,
        cliente: null,
       }
    },
    mounted(){
      this.verificandoCliente();
    },
    computed:{
        getParcelasDisponiveisBoleto(){
            if (!this.cobranca.id) return 0;

            const opcoes = [];

            const max = this.cobranca.max_parcelas_boleto;
            const maxParcelasOptions = [];
            for(let i=1; i<=12; i++){
                maxParcelasOptions.push({ value: i, text:`${i} parcelas` });
            }

            maxParcelasOptions.forEach(obj => {
                if(obj.value<= max){
                    if(obj.value > 1){
                        const valorParcela = parseFloat(this.cobranca.valor_total / obj.value).toFixed(2);
                        obj.text = `${obj.text} de R$ ${this.$masks.dinheiro(valorParcela)}`;
                    }
                    opcoes.push(obj);
                }
            });

            return opcoes;
        },
    },
    methods:{
         ...mapMutations(["openDialogMessage"]),
        addEmail(obj) {
            const patern = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+(?:\.[a-zA-Z0-9-]+)*$/)
            this.emailsBoleto = obj.value.filter(email => patern.test(email))
        },
      clienteInformado(cliente) {
        if(cliente) {
          this.cliente = cliente;
          this.emailsBoleto.push(cliente.email);
          this.step = 2;
        }
      },
        async gerar() {

            this.loading = true
            const data = {
                forma_pagamento: "BOLETO",
                destinatarios: this.emailsBoleto,
                parcelas: this.parcelas_boleto,
              cliente: this.cliente ? JSON.stringify(this.cliente) : null
            };

           await this.$axios.post(`cobranca/${this.cobranca.id}/pagar`, data)
            .then(() => {

                this.openDialogMessage({
                    type: "success",
                    title: "Boleto Gerado com sucesso",
                    text: "Boleto foi gerado com sucesso e enviado via e-mail aos destinatários informados",
                    });

               this.$emit("concluido");

            })
            .catch((error) => {

                if(error.response.data.falha){
                    this.openDialogMessage({
                        type: "error",
                        title: `Pagamento ${error.response.data.status}`,
                        text: `${error.response.data.id}: ${error.response.data.falha}`,
                    })
                }else{
                    this.openDialogMessage({
                        type: "error",
                        title: `Falha ao gerar o boleto`,
                        text: `${error.response.data}`,
                    })
                }
            }).finally(()=>{
                this.loading = false
            });

        },

        verificandoCliente() {
          if (this.cobranca.cliente && Object.keys(this.cobranca.cliente).length > 0) {
            this.emailsBoleto.push(this.cobranca.cliente.email);
            this.step = 2;
          } else {
            this.step = 1;
          }
        },
    }

}
</script>
<style lang="scss" scoped>
@import "@/assets/cartao.scss";

.center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dialog-cliente {
  overflow-x: hidden!important;
  overflow-y: hidden!important;
}

</style>
