import { RouteConfig } from "vue-router";
import Boleto from "@/views/Boleto/Boleto.vue";

const boletoRoutes: RouteConfig = {
    path: "/boleto/:hash",
    name: "Boleto",
    component: Boleto,
    props: route => ({
        hash: route.params.hash
    }),
    meta: {
        public: true
    }
}

export default boletoRoutes;