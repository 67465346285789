<template>
  <v-dialog v-model="showDialog" dense persistent max-width="500px">
    <v-card>
      <v-card-title>
        Autorização
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="validaToken">
          <v-container>
            <v-row dense>
              <v-col class="text-center">
                <tokenSvg class="svg-token" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <p>
                  {{ descricao }}
                </p>
                <p>
                  Abra o aplicativo e informe o token para autorizar a
                  transação.
                </p>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center " dense>
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Token"
                  outlined
                  v-model="token"
                  prepend-icon="mdi-shield-key"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-btn
                  color="btConfirma"
                  class="white--text pa-5"
                  depressed
                  dense
                  type="submit"
                >
                  Confirmar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="btCancela"
          text
          dense
          type="submit"
          @click="$emit('cancela-token')"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import tokenSvg from "@/components/Svgs/tokenSvg.vue";
export default {
  name: "DialogToken",
  components: {
    tokenSvg
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    descricao: String
  },
  data() {
    return {
      token: null
    };
  },
  methods: {
    async validaToken() {
      //request
      this.$emit("get-token", this.token);
    }
  }
};
</script>

<style scoped>
.svg-token {
  margin: auto auto;
  width: 300px;
  height: 200px;
}
</style>
