import FormCliente from '@/views/Cliente/FormCliente.vue';
import { Route, RouteConfig } from "vue-router";
import Assinaturas from "@/views/Assinatura/Assinaturas.vue";
import Assinatura from "@/views/Assinatura/Assinatura.vue";
import CriaAssinatura from "@/views/Assinatura/CriaAssinatura.vue";
import GenericComponent from "@/components/GenericComponent.vue";
import PagamentoAssinatura from "@/views/Assinatura/PagamentoAssinatura.vue";

const assinaturaRoutes: RouteConfig = {
  path: "/assinaturas",
  component: GenericComponent,
  children: [
    {
      path: "/",
      name: "Assinaturas",
      component: Assinaturas,
      meta: {
        breadCrumb: [
          {
            text: "Assinaturas"
          }
        ]
      }
    },
    {
      path: "/assinatura/add",
      name: "addAssinatura",
      component: CriaAssinatura,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Assinaturas",
              to: {
                name: "Assinaturas"
              }
            },
            {
              text: "Nova Assinatura"
            }
          ];
        }
      }
    },
    {
      path: "/assinatura/:idAssinatura",
      component: Assinatura,
      name: "viewAssinatura",
      props: route => ({
        mode: "view",
        idAssinatura: route.params.idAssinatura
      }),
      meta: {
        breadCrumb(route: Route) {
          const idAssinatura = route.params.idAssinatura;

          return [
            {
              text: "Assinaturas",
              to: { name: "Assinaturas" }
            },
            {
              text: idAssinatura
            }
          ];
        }
      }
    },
    {
      path: "novo-cliente",
      name: "AssinaturaNovoCliente",
      component: FormCliente,
      props: route => ({
          mode: "create",
          backRoute: route.params.backRoute,
          idEmpresa: route.params.idEmpresa
      }),
      meta: {
          breadCrumb: [
              {
                  text: "Nova assinatura",
                  to: {
                      name: "addAssinatura"
                  }
              },
              {
                  text: "Novo Cliente"
              }
          ]
      }

    },
    {
      path: "editar-cliente",
      name: "NovaAssinaturaEditarCliente",
      component: FormCliente,
      props: route => ({
          mode: "edit",
          idCliente: route.params.idCliente,
          backRoute: route.params.backRoute
      }),
      meta: {
          breadCrumb(route: Route) {
              const idCliente = route.params.idCliente;
              return [
                  {
                      text: "Nova Assinatura",
                      to: { name: "addAssinatura" }
                  },
                  {
                      text: idCliente
                  }
              ];
          }
      }
    },
    {
      path: "/assinatura/:idAssinatura/editar-cliente/:idCliente",
      name: "AssinaturaEditarCliente",
      component: FormCliente,
      props: route => ({
          mode: "edit",
          idCliente: route.params.idCliente,
          backRoute: route.params.backRoute
      }),
      meta: {
          breadCrumb(route: Route) {
              const idCliente = route.params.idCliente;
              return [
                  {
                      text: "Assinatura",
                      to: { name: "viewAssinatura" }
                  },
                  {
                      text: idCliente
                  }
              ];
          }
      }
    },
    {
      path: ":id/pagamento",
      component: PagamentoAssinatura,
      name: "PagamentoAssinatura",
      props: route => ({
          id: route.params.id
      }),
      meta: {
          public: true
      }
    },
  ]
};

export default assinaturaRoutes;
