import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Categorias from "@/views/Categoria/Categorias.vue";

const categoriaRoutes: RouteConfig = {
  path: "/categorias",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Categorias",
      component: Categorias,

      meta: {
        breadCrumb: [
          {
            text: "Categorias"
          }
        ]
      }
    },
   
  ]
};
export default categoriaRoutes;
