<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="text-left">CNPJ</th>
                <th scope="col" class="text-left">Nome Fantasia</th>
                <th scope="col" class="text-left">Razão Social</th>
                <th scope="col" class="text-end">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="empresa in usuario.empresas" :key="empresa.id">
                <td>
                  {{ empresa.cnpj }}
                </td>
                <td>
                  {{ empresa.nome_fantasia }}
                </td>
                <td>
                  {{ empresa.razao_social }}
                </td>
                <td class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="openModalRemoveEmpresa(empresa)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Remover Empresa</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    usuario: Object
  },
  methods: {
    ...mapMutations([
      "openDialogConfirm",
      "closeDialogConfirm",
      "openDialogMessage"
    ]),
    openModalRemoveEmpresa(empresa) {
      this.openDialogConfirm({
        title: "Remover Empresa",
        text: `Deseja realmente remover a empresa ${empresa.razao_social}?`,
        confirma: this.removeEmpresa.bind(this, empresa),
        cancela: this.closeDialogConfirm.bind()
      });
    },

    removeEmpresa(empresa) {
      this.$axios
        .delete(`/usuario/${this.usuario.id}/empresa/${empresa.id}`)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Remover Empresa",
            text: `Empresa ${empresa.razao_social} removida com sucesso.`
          });
          this.$emit("atualiza-dados");
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Remover Empresa",
            text: error.response.data
          });
        });
      this.closeDialogConfirm();
    }
  }
};
</script>

<style></style>
