import { RouteConfig } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
const dashboardRoutes: RouteConfig = {
  path: "/",
  name: "Dashboard",
  component: Dashboard,

};

export default dashboardRoutes;
