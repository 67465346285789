import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import FormUsuario from "@/views/Usuario/FormUsuario.vue";
import Usuarios from "@/views/Usuario/Usuarios.vue";

const usuarioRoutes: RouteConfig = {
  path: "/usuarios",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Usuarios",
      component: Usuarios,

      meta: {
        breadCrumb: [
          {
            text: "Usuarios"
          }
        ]
      }
    },

    {
      path: "/usuarios/add",
      name: "addUsuario",
      component: FormUsuario,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Usuarios",
              to: {
                name: "Usuarios"
              }
            },
            {
              text: "Novo Usuário"
            }
          ];
        }
      }
    },
    {
      path: "/usuarios/:idUsuario",
      name: "viewUsuario",
      component: FormUsuario,

      props: route => ({
        mode: "view",
        idUsuario: route.params.idUsuario
      }),
      meta: {
        breadCrumb(route: Route) {
          const idUsuario = route.params.idUsuario;

          return [
            {
              text: "Usuarios",
              to: { name: "Usuarios" }
            },
            {
              text: idUsuario
            }
          ];
        }
      }
    },

    {
      path: "/usuarios/:idUsuario/edit",
      name: "editUsuario",
      component: FormUsuario,
      props: route => ({
        mode: "edit",
        idUsuario: route.params.idUsuario
      }),
      meta: {
        breadCrumb(route: Route) {
          const idUsuario = route.params.idUsuario;

          return [
            {
              text: "Usuarios",
              to: { name: "Usuarios" }
            },
            {
              text: idUsuario,

              to: {
                name: "viewUsuario",
                params: {
                  idUsuario: idUsuario
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};
export default usuarioRoutes;
