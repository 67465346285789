<template>
  <v-dialog v-model="showDialog" persistent max-width="1000">
    <v-card>
      <v-card-title>
        Planos
        <v-spacer></v-spacer>
        <Chips
            v-model="filtros"
            separator=","
            @add="getPlanos"
            @remove="getPlanos"
            placeholder="Filtro por código e descrição"
          />
      </v-card-title>

      <v-card-text>
        <DataTable :value="planos" selectionMode="single" dataKey="id"
                  :loading="carregando"
                  loading-text="Carregando dados"
                  @row-select="onRowSelect" :selection.sync="planoSelecionado"
                  responsiveLayout="scroll" :scrollable="true" scrollHeight="450px" >

            <Column header="Código">
                <template #body="{data}">
                  <p>{{data.id}}</p>
                </template>
            </Column>
           <Column header="Descricao">
                <template #body="{data}">
                  <p>{{data.descricao}}</p>
                </template>
            </Column>
            <Column header="Dias validade">
                <template #body="{data}">
                  <p>{{data.dias_validade}}</p>
                </template>
            </Column>
            <Column header="Valor">
               <template #body="{data}">
                 <span>R$ {{ calculaTotal(data) | formatMoney({  minimumFractionDigits: 2 }) }}</span> 
               </template>
            </Column>
        </DataTable>
        <v-pagination
          v-model="pagination.page"
          :length="totalPaginas"
          :loading="carregando"
          @input="next"
          :total-visible="10">
        </v-pagination>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="btCancela" text @click="cancelaDialog"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Chips from "primevue/chips";

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
  },
  components: { DataTable , Column, Chips},
  data() {
    return {
      search: "",
      carregando: false,
      planoSelecionado: null,
      planos: [],
      filtros: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 5,
        first: 0
      }
    }
  },
  computed:{
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapState(["openDialogMessage"]),
    async getPlanos() {
      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/plano/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              plano: this.filtros.map(p => p.trim()),
              status: ['ATIVO']
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.planos = response.data.data;
          this.pagination.total = response.data.count;
        });
    },
    calculaTotal(plano) {
      let total = 0.00;
      plano.itens.forEach(item => {
          total = (parseFloat(total) + (parseFloat(item.quantidade * item.valor) - parseFloat(item.desconto)));
      });
     return total.toFixed(2);
    },
    onRowSelect(event) {
      this.$emit("onSelect",event.data);
    },
    cancelaDialog() {
      this.$emit("closeDialog");
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getPlanos();
    }
  },
  created(){
    this.getPlanos();
  }
};
</script>

<style>
tbody {
  cursor: pointer;
}
.v-data-table__selected {
  background: #3c89fc !important;
  font-weight: 600;
}
</style>
