import { RouteConfig } from "vue-router";
import MinhaConta from "@/views/MinhaConta/MinhaConta.vue";
import MinhaContaDados from "@/views/MinhaConta/MinhaContaDados.vue";
import MinhaContaEmpresas from "@/views/MinhaConta/MinhaContaEmpresas.vue";
import MinhaContaAltSenha from "@/views/MinhaConta/MinhaContaAltSenha.vue";

const minhaContaRoutes: RouteConfig =
{
    path: "/minha-conta",

    component: MinhaConta,

    meta: {
        breadCrumb: [
          
            {
                text: "Minha Conta",
            },

        ]
    },
    children: [
        {
            path: "/",
            redirect: "/minha-conta/dados"
        },

        {
            path: "/minha-conta/dados",
            name: "MinhaContaDados",
            component: MinhaContaDados,
            meta: {
                breadCrumb: [
                   
                    {
                        text: "Minha Conta",
                    },
                    {
                        text: "Dados"
                    }

                ]
            }
        },
        {
            path: "/minha-conta/empresas",
            name: "MinhaContaEmpresas",
            component: MinhaContaEmpresas,
            meta: {
                breadCrumb: [
                   
                    {
                        text: "Minha Conta",
                    },
                    {
                        text: "Empresas"
                    }

                ]
            }
        },
        {
            path: "/minha-conta/alteracao-senha",
            name: "MinhaContaAltSenha",
            component: MinhaContaAltSenha,
            meta: {
                breadCrumb: [
                   
                    {
                        text: "Minha Conta",
                    },
                    {
                        text: "Alteração Senha"
                    }

                ]
            }

        }
    ]
}

export default minhaContaRoutes;