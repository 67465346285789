<template>
  <v-container fluid>
    <div v-if="empresa.status" class="d-flex justify-end mb-3 mt-n9 div-alert">
      <div style="display: inline-block; width: auto; text-align: left;">
        <v-alert
          :type="STATUS_EMPRESA[empresa.status].type"
          dense
          text
        >
          <b>{{ STATUS_EMPRESA[empresa.status].texto }}</b>
        </v-alert>
      </div>
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-card outlined style="border-width: 2px" class="d-flex justify-center align-center card-principal">
          <v-stepper
            v-model="step"
            :width="empresa.natureza_juridica === 'MEI' || empresa.natureza_juridica === 'EIRELI' || empresa.natureza_juridica === 'EI' ? 500 : '80%'"
            alt-labels
            elevation="0"
          >
            <v-skeleton-loader
              elevation="0"
              height="80"
              :loading="loadingSkeleton && !empresa"
              type="image"
            >
              <v-stepper-header v-if="empresa.natureza_juridica === 'MEI' || empresa.natureza_juridica === 'EIRELI' || empresa.natureza_juridica === 'EI'">

                <v-stepper-step :color="step > 1 ? 'green' : 'black'" :complete="step > 1" step="1">
                  Dados Gerais
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="step > 2 ? 'green' : 'black'" :complete="step > 2" step="2">
                  Split
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  Finalizar no APP
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-header v-if="empresa.natureza_juridica !== 'MEI' && empresa.natureza_juridica !== 'EIRELI' && empresa.natureza_juridica !== 'EI'">

                <v-stepper-step :color="step > 1 ? 'green' : 'black'" :complete="step > 1" step="1">
                  Dados Gerais
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="step > 2 ? 'green' : 'black'" :complete="step > 2" step="2">
                  Sócios
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="step > 3 ? 'green' : 'black'" :complete="step > 3" step="3">
                  Documentos
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="step > 4 ? 'green' : 'black'" :complete="step > 4" step="4">
                  Fiscal
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :color="step > 5 ? 'green' : 'black'" :complete="step > 5" step="5">
                  Split
                </v-stepper-step>

              </v-stepper-header>
            </v-skeleton-loader>

          </v-stepper>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mx-auto mt-n3">
      <v-col cols="12">
        <v-card outlined class="card-principal">
          <v-stepper elevation="0" v-model="step">
            <v-stepper-items>
              <!-------------- DADOS GERAIS -------------->
              <v-stepper-content step="1">
                <form
                    action=""
                    id="form"
                    name="formEmpresa"
                    class="mt-3"
                    @submit.prevent="handleSave()"
                >
                  <v-skeleton-loader
                      :loading="loadingSkeleton"
                      type="text@10"
                  >
                    <v-row class="mx-auto">
                      <v-col cols="12" xl="2" lg="2" md="4">
                        <v-text-field
                            required
                            dense
                            ref="cnpj"
                            v-model="empresa.cnpj"
                            @blur="checkCnpj"
                            @keyup="empresa.cnpj = $masks.cnpj(empresa.cnpj)"
                            label="CNPJ"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="4">
                        <v-text-field
                            required
                            dense
                            v-model="empresa.razao_social"
                            label="Razão Social"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            required
                            dense
                            v-model="empresa.nome_fantasia"
                            label="Nome Fantasia"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto">
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            dense
                            v-model="empresa.inscricao_municipal"
                            label="Inscrição Municipal"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            dense
                            v-model="empresa.inscricao_estadual"
                            label="Inscrição Estadual"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            required
                            maxlength="13"
                            dense
                            @keyup="
                          empresa.telefone = $masks.telefone(empresa.telefone)
                        "
                            v-model="empresa.telefone"
                            label="Telefone"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" xl="3" lg="3" md="6">
                        <v-text-field
                            required
                            dense
                            type="email"
                            v-model="empresa.email"
                            label="Email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="1" lg="1" md="6">
                        <v-text-field
                            dense
                            v-model="empresa.data_abertura"
                            @keyup="
                          empresa.data_abertura = $masks.data(
                            empresa.data_abertura
                          )
                        "
                            label="Data Abertura"
                            required
                            maxlength="10"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            dense
                            v-model="empresa.faturamento_medio"
                            label="Faturamento Médio Mensal"
                            @keyup="
                          empresa.faturamento_medio = $masks.dinheiro(
                            empresa.faturamento_medio
                          )
                        "
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto">
                      <v-col cols="12" xl="2" lg="2" md="4" class="d-flex">
                        <v-text-field
                            required
                            @keydown="empresa.cep = $masks.cep(empresa.cep)"
                            dense
                            ref="cep"
                            maxlength="9"
                            v-model="empresa.cep"
                            label="CEP"
                        ></v-text-field>
                        <v-btn @click="getCep" text small>
                          <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="12" xl="6" lg="6" md="8">
                        <v-text-field
                            dense
                            v-model="empresa.endereco"
                            label="Endereço"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            required
                            ref="numero"
                            dense
                            v-model="empresa.numero"
                            label="Número"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" xl="2" lg="2" md="6">
                        <v-text-field
                            dense
                            v-model="empresa.complemento"
                            label="Complemento"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto">
                      <v-col cols="12" xl="3" lg="3" md="4">
                        <v-text-field
                            required
                            dense
                            v-model="empresa.bairro"
                            label="Bairro"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" xl="8" lg="8" md="4">
                        <v-text-field
                            required
                            dense
                            v-model="empresa.cidade"
                            label="Cidade"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" xl="1" lg="1" md="4">
                        <v-text-field
                            disabled
                            readonly
                            required
                            dense
                            v-model="empresa.uf"
                            label="UF"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto">
                      <v-col cols="12" xl="6" lg="6">
                        <v-select
                            dense
                            required
                            :items="TIPO_EMPRESA"
                            :item-text="
                          tpEmpresa => `${tpEmpresa.valor} - ${tpEmpresa.texto}`
                        "
                            item-value="valor"
                            @change="setDoctosTipoEmpresa"
                            v-model="empresa.natureza_juridica"
                            label="Natureza Jurídica"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" xl="6" lg="6">
                        <v-autocomplete
                            dense
                            required
                            :items="cnaes"
                            :item-text="
                          item => `${item.codigo} - ${item.descricao}`
                        "
                            item-value="codigo"
                            v-model="empresa.cnae"
                            label="CNAE"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row style="display: none" class="mx-auto">
                      <v-col class="text-end">
                        <v-btn dark color="primary" type="submit" ref="btnSubmit">
                          Avançar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-skeleton-loader>
                </form>
              </v-stepper-content>

              <v-stepper-content  v-if="empresa.natureza_juridica === 'MEI' || empresa.natureza_juridica === 'EIRELI' || empresa.natureza_juridica === 'EI'" step="2">
                <v-container fluid>
                  <v-row class="d-flex justify-center">
                    <v-col cols="10">
                      <v-row dense>
                        <v-col>
                          <span class="text-h5"> Splits </span>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="!user.permissoes.adm_split">
                        <v-col>
                          <v-alert colored-border type="info" elevation="2">
                            Seu usuário não possui permissão para configurar
                            splits, clique em avançar para pular esta etapa.
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col cols="10">
                          <v-autocomplete
                              :items="empresasMarketPlace"
                              item-value="id"
                              item-text="razao_social"
                              v-model="destinatarioSplit"
                              label="Destinatarios"
                              dense
                              return-object
                          >
                            <template v-slot:selection="{ item }">
                            <span>
                              {{ $masks.cnpj(item.cnpj) }} -
                              {{ item.razao_social }}
                            </span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{
                                    item.nome_fantasia
                                  }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ $masks.cnpj(item.cnpj) }} -
                                  {{ item.razao_social }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  @click="addSplit"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar Split</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left" width="450">Empresa</th>
                                <th class="text-left" width="120">
                                  Percentual
                                </th>
                                <th class="text-left">Paga Taxa</th>
                                <th class="text-left">Responsavel Divisão</th>
                                <th class="text-left">Remover</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="(split, index) in splits"
                                  :key="split.id"
                              >
                                <td>
                                  <div>
                                    <div>
                                      {{ split.recebedor.cnpj }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div>
                                      {{ split.recebedor.razao_social }}
                                    </div>
                                    <div>
                                      <span class="endereco">{{
                                          split.recebedor.enderecoCompleto
                                        }}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                      mode="decimal"
                                      :minFractionDigits="2"
                                      :maxFractionDigits="2"
                                      v-model="split.valor"
                                      suffix=" %"
                                      :max="100"
                                      :min="0"
                                      :allowEmpty="false"
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      v-model="split.paga_taxa_transacao"
                                      :label="
                                      split.paga_taxa_transacao ? 'Sim' : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      v-model="split.paga_resto_divisao_taxa"
                                      :label="
                                      split.paga_resto_divisao_taxa
                                        ? 'Sim'
                                        : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          text
                                          small
                                          @click="
                                          openModalRemoveSplit(split, index)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remover Item</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        class="d-flex align-self-center"
                        v-if="user.permissoes.adm_split"
                    >
                      <v-progress-circular
                          :rotate="360"
                          :size="200"
                          :width="10"
                          :value="totalPercSplit"
                          :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                      >
                      <span class="text-h5 font-weight-bold">
                        {{ totalPercSplit }}%
                      </span>
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content
                  v-if="empresa.natureza_juridica === 'MEI' || empresa.natureza_juridica === 'EIRELI' || empresa.natureza_juridica === 'EI'"
                  step="3"
              >
                <v-row class="mx-auto">
                  <v-col cols="12" md="6">
                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="text"
                        width="30%"
                    >
                      <v-row class="mt-1">
                        <v-col cols="12" md="12">
                          <div>
                            <v-card-subtitle class="subtitle-text"
                            >Escolha a plataforma</v-card-subtitle
                            >
                            <v-card-text class="mt-n5 text-subtitle-2">
                              <v-radio-group
                                  @change="handleChangePlataforma"
                                  v-model="plataforma"
                                  row
                              >
                                <v-radio label="Google" value="Google"></v-radio>
                                <v-radio label="Apple" value="Apple"></v-radio>
                              </v-radio-group>
                            </v-card-text>
                          </div>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="text"
                        width="50%"
                    >
                      <v-row class="mt-n15">
                        <v-col cols="12" md="6">
                          <div>
                            <v-card-subtitle class="subtitle-text"
                            >Link plataforma de aplicativos:</v-card-subtitle
                            >
                            <v-card-text class="mt-n5 text-subtitle-2 d-flex">
                              <v-text-field
                                  required
                                  dense
                                  v-model="linkPlataformaApp"
                              >
                              </v-text-field>
                              <v-icon
                                  v-if="!btnCopy"
                                  @click="handleCopy(linkPlataformaApp)"
                                  class="mt-n3 ml-3"
                              >mdi-content-copy</v-icon
                              >
                              <v-btn
                                  v-if="btnCopy"
                                  label
                                  text-color="white"
                                  color="success"
                                  class="mt-1 ml-3"
                                  small
                                  outlined
                              >
                                Link Copiado!
                              </v-btn>
                            </v-card-text>
                          </div>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="text"
                        width="30%"
                    >
                      <v-row class="mt-n10">
                        <v-col cols="12" md="12">
                          <div>
                            <v-card-subtitle class="subtitle-text"
                            >Enviar link de download</v-card-subtitle
                            >
                            <v-card-text class="mt-n5 text-subtitle-2">
                              <v-radio-group v-model="linkDownload" row>
                                <v-radio label="WhatsApp" value="WhatsApp"></v-radio>
                                <v-radio label="SMS" value="SMS"></v-radio>
                              </v-radio-group>
                            </v-card-text>
                          </div>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="text"
                        width="60%"
                    >
                      <v-row class="mt-n15">
                        <v-col cols="12" md="6">
                          <div>
                            <v-card-subtitle class="subtitle-text"
                            >Telefone:</v-card-subtitle
                            >
                            <v-card-text class="mt-n5 text-subtitle-2 d-flex">
                              <v-text-field
                                  maxlength="13"
                                  dense
                                  @keyup="empresa.telefone = $masks.telefone(empresa.telefone)"
                                  v-model="empresa.telefone"
                              ></v-text-field>
                              <div style="cursor: pointer" @click="handleEnviarLinkDownload()">
                                <v-icon color="success" class="ml-3">
                                  mdi-send
                                </v-icon>
                              </div>
                            </v-card-text>
                          </div>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>
                  </v-col>

                  <v-divider
                      inset
                      vertical
                      class="my-4 custom-divider-vertical-app"
                  ></v-divider>

                  <v-col cols="12" md="6">

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="text"
                    >
                      <v-row v-if="plataforma" class="mt-4">
                        <v-col cols="12" md="12" class="text-center">
                          <v-card-text class="mt-n5 subtitle-text">
                            Baixe o SisBank na sua plataforma de aplicativos e conclua o seu cadastro
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="image"
                    >
                      <v-row class="mt-n3">
                        <v-col cols="12" md="12" class="text-center">
                          <div v-if="plataforma === 'Google'" class="card-qrcode mx-auto mb-3 px-5 py-5">
                            <div>
                              <VueQr :size="210" :text="linkPlataformaApp" />
                            </div>
                            <div class="mt-5">
                              <v-img
                                  class="white--text align-end img-plataforma"
                                  height="61px"
                                  :src="require('@/assets/img/googlePlay.png')"
                              ></v-img>
                            </div>
                          </div>

                          <div v-if="plataforma === 'Apple'" class="card-qrcode mx-auto mb-3 px-5 py-5">
                            <div>
                              <VueQr :size="210" :text="linkPlataformaApp" />
                            </div>
                            <div class="mt-5">
                              <v-img
                                  class="white--text align-end img-plataforma"
                                  height="61px"
                                  :src="require('@/assets/img/appStore.png')"
                              ></v-img>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                  </v-col>
                </v-row>
              </v-stepper-content>

              <!-------------- SÓCIOS-------------->
              <v-stepper-content
                  v-if="empresa.natureza_juridica !== 'MEI' && empresa.natureza_juridica !== 'EIRELI' && empresa.natureza_juridica !== 'EI'"
                  step="2"
              >
                <v-row class="mx-auto">
                  <v-col cols="12" md="6">

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                        :loading="loadingSkeleton"
                        type="heading"
                    >
                      <v-row class="mx-auto">
                        <v-col col="12" sm="12">
                          <v-card-title>Sócios</v-card-title>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <v-row v-if="empresa.socios.length > 0" class="mx-auto mt-n4">
                      <v-col
                          v-for="(socio, index) of empresa.socios"
                          :key="index"
                          cols="12"
                      >
                        <v-skeleton-loader
                            :loading="loadingSkeleton"
                            type="image"
                            height="70"
                        >
                          <v-card
                              outlined
                              @click="getDetalhesSocio(socio)"
                              class="d-flex card-socio card-principal"
                              :class="
                            socio.id === detalhesSocio.id
                              ? 'custom-card-ativo'
                              : 'custom-card'
                          "
                          >
                            <v-icon
                                :color="
                              socio.id === detalhesSocio.id
                                ? '#0875ee'
                                : '#a2a2a2'
                            "
                                size="50px"
                            >mdi-account-circle</v-icon
                            >
                            <div class="d-flex flex-column justify-center ml-3 text-caption">
                              <div>{{ socio.nome }}</div>
                              <div v-if="socio.administrador">
                                Administrador
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn icon class="mt-1 mr-2">
                              <v-icon
                                  @click="openDialogSocio('edit', detalhesSocio)"
                                  :color="
                                socio.id === detalhesSocio.id
                                  ? '#0875ee'
                                  : '#a2a2a2'
                              "
                              >mdi-pencil</v-icon
                              >
                            </v-btn>
                            <v-btn icon class="mt-1">
                              <v-icon
                                  @click="openModalRemoveSocio(socio)"
                                  :color="
                                socio.id === detalhesSocio.id
                                  ? '#0875ee'
                                  : '#a2a2a2'
                              "
                              >mdi-delete</v-icon
                              >
                            </v-btn>
                          </v-card>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <v-row v-else class="mx-auto">
                      <v-col cols="12">
                        <v-skeleton-loader
                            :class="loadingSkeleton ? 'mt-n2 ml-n3' : ''"
                            :loading="loadingSkeleton"
                            type="text"
                        >
                          <div class="d-flex justify-center mt-n1">
                          <span class="text-subtitle-2 px-2 py-2"
                          >Nenhum sócio cadastrado.</span
                          >
                          </div>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto mb-3 justify-center">
                      <v-col cols="12" class="text-center">
                        <v-skeleton-loader
                            :class="loadingSkeleton ? 'mt-n2 ml-n3' : ''"
                            :loading="loadingSkeleton"
                            type="button"
                        >
                          <v-btn
                              @click="openDialogSocio('create')"
                              x-small
                              fab
                              dark
                              color="green"
                              class="elevation-3"
                          >
                            <v-icon size="30">mdi-plus</v-icon>
                          </v-btn>
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>

                    <v-row class="mx-auto mb-3 justify-center">
                      <v-col cols="12" class="text-center">
                        <v-divider
                            inset
                            class="mx-10 custom-divider-horizontal"
                        ></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-divider
                      inset
                      vertical
                      class="my-4 custom-divider-vertical"
                  ></v-divider>

                  <v-col cols="12" md="6">

                    <v-skeleton-loader
                        :class="loadingSkeleton ? 'mt-8 mb-8' : ''"
                        :loading="loadingSkeleton"
                        type="heading"
                    >
                      <v-row class="mx-auto">
                        <v-col col="12" sm="12" class="d-flex">
                          <v-card-title class="mt-1">Sócio</v-card-title>
                        </v-col>
                      </v-row>
                    </v-skeleton-loader>

                    <div v-if="detalhesSocio.id">
                      <v-row class="mt-n5">
                        <v-col cols="12" xl="3" md="6">
                          <v-skeleton-loader
                              :loading="loadingSkeleton"
                              type="text"
                          >
                            <div v-if="detalhesSocio.tipo === 'PESSOA_JURIDICA'" class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >CNPJ</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">
                                <span>{{ detalhesSocio.cnpj }}</span>
                              </v-card-text>
                            </div>
                            <div v-else class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >CPF</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">
                                <span>{{ detalhesSocio.cpf }}</span>
                              </v-card-text>
                            </div>
                          </v-skeleton-loader>

                        </v-col>
                        <v-col v-if="detalhesSocio.tipo === 'PESSOA_JURIDICA'">
                          <v-skeleton-loader
                              :loading="loadingSkeleton"
                              type="text"
                          >
                            <div class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >Razão Social</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">{{
                                  detalhesSocio.razao_social
                                }}</v-card-text>
                            </div>
                          </v-skeleton-loader>

                        </v-col>
                        <v-col cols="12" xl="6" md="6">
                          <v-skeleton-loader
                              :loading="loadingSkeleton"
                              type="text"
                          >
                            <div class="mt-n5">
                              <v-card-subtitle v-if="detalhesSocio.tipo === 'PESSOA_JURIDICA'" class="subtitle-text"
                              >Nome Fantasia</v-card-subtitle
                              >
                              <v-card-subtitle v-else class="subtitle-text"
                              >Nome</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">{{
                                  detalhesSocio.nome
                                }}</v-card-text>
                            </div>
                          </v-skeleton-loader>

                        </v-col>
                      </v-row>

                      <v-skeleton-loader
                          :loading="loadingSkeleton"
                          type="text"
                      >
                        <v-row class="mt-n5">
                          <v-col cols="12" xl="3" lg="6" md="6">
                            <div class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >Email</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">
                                <span>{{ detalhesSocio.email }}</span>
                              </v-card-text>
                            </div>
                          </v-col>
                          <v-col cols="12" xl="6" lg="6" md="6">
                            <div class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >Telefone</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">{{
                                  detalhesSocio.telefone_celular
                                }}</v-card-text>
                            </div>
                          </v-col>
                        </v-row>
                      </v-skeleton-loader>

                      <v-skeleton-loader
                          :loading="loadingSkeleton"
                          type="text"
                          width="50%"
                      >
                        <v-row class="mt-n5">
                          <v-col cols="12" lg="12">
                            <div class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >Endereço</v-card-subtitle
                              >
                              <v-card-text class="mt-n5">
                                {{detalhesSocio.endereco }},
                                {{detalhesSocio.numero }}
                                {{detalhesSocio.bairro }},
                                {{detalhesSocio.cidade }} -
                                {{detalhesSocio.uf }},
                                {{detalhesSocio.cep }}
                              </v-card-text>
                            </div>
                          </v-col>
                        </v-row>
                      </v-skeleton-loader>

                      <v-skeleton-loader
                          :loading="loadingSkeleton"
                          type="text"
                      >
                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="mt-n10 ml-3">
                              <v-switch
                                  disabled
                                  color="green"
                                  class="ml-2"
                                  label="Administrador"
                                  v-model="detalhesSocio.administrador"
                              ></v-switch>
                            </div>
                          </v-col>
                        </v-row>
                      </v-skeleton-loader>

                      <v-skeleton-loader
                          :loading="loadingSkeleton"
                          type="list-item-avatar"
                      >
                        <v-row class="mt-n5">
                          <v-col cols="12" sm="12">
                            <div class="mt-n5">
                              <v-card-subtitle class="subtitle-text"
                              >Documentos</v-card-subtitle
                              >
                              <v-card-text
                                  v-if="detalhesSocio.documentos.length > 0"
                                  class="mr-10"
                              >
                                <v-row class="mx-auto justify-start">
                                  <v-col
                                      v-for="(documento,
                                  index) in detalhesSocio.documentos"
                                      :key="index"
                                      cols="12"
                                      xl="2"
                                      lg="2"
                                      md="3"
                                      sm="4"
                                  >
                                    <div class="icon-text-container">
                                      <a
                                          :href="documento.url"
                                          target="_blank"
                                          class="py-0"
                                      >
                                        <img
                                            :src="
                                          require('@/assets/img/icones/Doc_lupa_icon.svg')
                                        "
                                            alt="icon"
                                        />
                                      </a>
                                      <p
                                          style="white-space: nowrap;"
                                      >
                                        {{ TIPO_DOCUMENTO[documento.tipo].texto }}
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-card-text>
                              <v-card-text v-else class="mr-10">
                                <div class="d-flex justify-center">
                                <span class="text-subtitle-2 px-2 py-2"
                                >Nenhum Documento.</span
                                >
                                </div>
                              </v-card-text>
                            </div>
                          </v-col>
                        </v-row>
                      </v-skeleton-loader>


                    </div>

                    <div v-else class="mt-2">
                      <v-skeleton-loader
                          :class="loadingSkeleton ? 'mt-10 mb-10' : ''"
                          :loading="loadingSkeleton"
                          type="text"
                      >
                        <v-row class="mx-auto">
                          <v-col cols="12">
                            <div class="d-flex justify-center">
                            <span class="text-subtitle-2 px-2 py-2"
                            >Nenhum sócio selecionado.</span
                            >
                            </div>
                          </v-col>
                        </v-row>
                      </v-skeleton-loader>

                    </div>
                  </v-col>

                  <div v-if="empresa.id">
                    <DialogSocio
                        :showDialog="socioDialog.showDialog"
                        :empresaId="empresa.id"
                        :id="socioDialog.id"
                        :mode="socioDialog.mode"
                        @salva-dados="saveSocio"
                        @cancela-dialog="socioDialog.showDialog = false"
                    />
                  </div>
                </v-row>
              </v-stepper-content>

              <v-stepper-content v-if="empresa.natureza_juridica !== 'MEI' && empresa.natureza_juridica !== 'EIRELI' && empresa.natureza_juridica !== 'EI'" step="3">
                <v-row
                    class="mx-auto align-center mt-3"
                    dense
                    v-for="(documento, index) of doctosTipoEmpresa"
                    :key="documento"
                >
                  <v-col cols="12" sm="2">
                    <v-file-input
                        @change="(e) => getDoc(e, documento)"
                        label="Arquivo"
                        dense
                        :accept="TIPO_DOCUMENTO[documento].formato"
                        truncate-length="20"
                        v-model="currentDocument[index]"
                    ></v-file-input>
                  </v-col>
                  <v-col
                      cols="6"
                      sm="6"
                      class="text-subtitle font-weight-bold d-flex justify-space-between"
                  >
                    {{ TIPO_DOCUMENTO[documento].texto }} em
                    {{ TIPO_DOCUMENTO[documento].tipo }}
<!--                    <i-->
<!--                        v-if="getEmpresaDocto(documento)"-->
<!--                        class="fa-style fas fa-check green&#45;&#45;text mr-3"-->
<!--                    ></i>-->
                    <v-icon v-if="getEmpresaDocto(documento)" class="green--text mr-3"> mdi-check </v-icon>
                  </v-col>
                  <v-col cols="12" sm="2">
                    {{
                      getEmpresaDocto(documento)
                          ? ""
                          : "Documento Pendente"
                    }}
                    <v-btn
                        icon
                        fab
                        small
                        v-if="getEmpresaDocto(documento)"
                        :href="getEmpresaDocto(documento).url"
                        target="_blank"
                    >
<!--                      <i class="fa-style fas fa-search"></i>-->
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="4">

                <v-skeleton-loader
                    :loading="loadingSkeleton"
                    type="text"
                    width="30%"
                >
                  <v-row class="mx-auto mt-n3">
                    <v-col cols="12" xl="2" lg="2" md="3">
                      <v-switch
                          color="green"
                          v-model="empresa.emite_nfse"
                          label="Emissão de NFS-e"
                      >
                      </v-switch>
                      <v-switch
                          color="green"
                          class="mt-n3"
                          v-model="empresa.emite_nfce"
                          label="Emissão de NFC-e"
                      >
                      </v-switch>
                    </v-col>
                    <v-col class="coluna-simpesnacional-incentivadorcultural" cols="12" md="3">
                      <v-switch
                          color="green"
                          v-model="empresa.optante_simples_nacional"
                          label="Simples Nacional"
                      >
                      </v-switch>
                      <v-switch
                          color="green"
                          class="mt-n3"
                          v-model="empresa.incentivador_cultural"
                          label="Incentivador Cultural"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-skeleton-loader>

                <v-row v-if="empresa.emite_nfce" class="mx-auto">
                  <v-skeleton-loader
                      :loading="loadingSkeleton"
                      type="text"
                      width="20%"
                  >
                    <v-col cols="12" md="12">
                      <v-text-field
                          required
                          dense
                          v-model="empresa.id_token_nfce"
                          label="Token NFC-e"
                          maxlength="8"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                          required
                          dense
                          v-model="empresa.csc_nfce"
                          label="Código de Segurança NFC-e"
                      ></v-text-field>
                    </v-col>
                  </v-skeleton-loader>

                </v-row>



                <v-skeleton-loader
                    :loading="loadingSkeleton"
                    type="text"
                    width="20%"
                >
                  <v-row class="mx-auto">
                    <v-col cols="12" md="2">
                      <v-text-field
                          required
                          dense
                          v-model="empresa.serie_rps"
                          label="Série RPS"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="loadingSkeleton"
                    type="text"
                    width="25%"
                >
                  <v-row class="mx-auto">
                    <v-col cols="12" md="3">
                      <v-select
                          :items="regime_tributario"
                          required
                          dense
                          item-text="texto"
                          item-value="valor"
                          v-model="empresa.regime_tributario"
                          label="Regime Tributário"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="loadingSkeleton"
                    type="text"
                    width="25%"
                >
                  <v-row class="mx-auto">
                    <v-col cols="12" md="3">
                      <v-select
                          :items="regime_especial_tributacao"
                          item-text="texto"
                          item-value="valor"
                          required
                          dense
                          v-model="empresa.regime_especial_tributacao"
                          label="Regime Especial Tributação"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-skeleton-loader>

                <v-skeleton-loader
                    :loading="loadingSkeleton"
                    type="text"
                    width="25%"
                >
                  <v-row class="mx-auto">
                    <v-col cols="12" md="3">
                      <v-select
                          :items="tributacao_rps"
                          item-text="texto"
                          item-value="valor"
                          required
                          dense
                          v-model="empresa.tributacao_rps"
                          type="number"
                          label="Tributação RPS"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-skeleton-loader>


              </v-stepper-content>

              <v-stepper-content v-if="empresa.natureza_juridica !== 'MEI' && empresa.natureza_juridica !== 'EIRELI' && empresa.natureza_juridica !== 'EI'" step="5">
                <v-container fluid>
                  <v-row class="d-flex justify-center">
                    <v-col cols="10">
                      <v-row dense>
                        <v-col>
                          <span class="text-h5"> Splits </span>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="!user.permissoes.adm_split">
                        <v-col>
                          <v-alert colored-border type="info" elevation="2">
                            Seu usuário não possui permissão para configurar
                            splits, clique em avançar para pular esta etapa.
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col cols="10">
                          <v-autocomplete
                              :items="empresasMarketPlace"
                              item-value="id"
                              item-text="razao_social"
                              v-model="destinatarioSplit"
                              label="Destinatarios"
                              dense
                              return-object
                          >
                            <template v-slot:selection="{ item }">
                            <span>
                              {{ $masks.cnpj(item.cnpj) }} -
                              {{ item.razao_social }}
                            </span>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{
                                    item.nome_fantasia
                                  }}</v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ $masks.cnpj(item.cnpj) }} -
                                  {{ item.razao_social }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  fab
                                  dark
                                  small
                                  color="primary"
                                  @click="addSplit"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Adicionar Split</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row dense v-if="user.permissoes.adm_split">
                        <v-col>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th class="text-left" width="450">Empresa</th>
                                <th class="text-left" width="120">
                                  Percentual
                                </th>
                                <th class="text-left">Paga Taxa</th>
                                <th class="text-left">Responsavel Divisão</th>
                                <th class="text-left">Remover</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="(split, index) in splits"
                                  :key="split.id"
                              >
                                <td>
                                  <div>
                                    <div>
                                      {{ split.recebedor.cnpj }} -
                                      <span class="nome-fantasia">{{
                                          split.recebedor.nome_fantasia
                                        }}</span>
                                    </div>
                                    <div>
                                      {{ split.recebedor.razao_social }}
                                    </div>
                                    <div>
                                      <span class="endereco">{{
                                          split.recebedor.enderecoCompleto
                                        }}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <InputNumber
                                      mode="decimal"
                                      :minFractionDigits="2"
                                      :maxFractionDigits="2"
                                      v-model="split.valor"
                                      suffix=" %"
                                      :max="100"
                                      :min="0"
                                      :allowEmpty="false"
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      v-model="split.paga_taxa_transacao"
                                      :label="
                                      split.paga_taxa_transacao ? 'Sim' : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-switch
                                      v-model="split.paga_resto_divisao_taxa"
                                      :label="
                                      split.paga_resto_divisao_taxa
                                        ? 'Sim'
                                        : 'Não'
                                    "
                                  />
                                </td>
                                <td>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          text
                                          small
                                          @click="
                                          openModalRemoveSplit(split, index)
                                        "
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Remover Item</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                        cols="2"
                        class="d-flex align-self-center"
                        v-if="user.permissoes.adm_split"
                    >
                      <v-progress-circular
                          :rotate="360"
                          :size="200"
                          :width="10"
                          :value="totalPercSplit"
                          :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                      >
                      <span class="text-h5 font-weight-bold">
                        {{ totalPercSplit }}%
                      </span>
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>

    <v-row  v-if="step === 3 && (empresa.natureza_juridica === 'EIRELI' || empresa.natureza_juridica === 'MEI' || empresa.natureza_juridica === 'EI')" class="mx-auto">
      <v-col class="text-end">
        <v-btn
          class="mr-6"
          color="gray"
          v-show="!appLoading"
          @click="handleBack"
        >
          Voltar
        </v-btn>
        <v-btn
          dark
          color="green"
          v-show="!appLoading"
          @click="handleConcluir"
        >
          Concluir
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-else class="mx-auto">
      <v-col class="text-end">
        <v-btn
          class="mr-6"
          color="gray"
          v-show="!appLoading"
          @click="handleBack"
        >
          Voltar
        </v-btn>
        <v-btn
          v-if="step !== 5"
          dark
          color="primary"
          v-show="!appLoading"
          @click="submit()"
        >
          Avançar
        </v-btn>
        <v-btn
          v-else
          dark
          color="green"
          v-show="!appLoading"
          @click="handleSave(true)"
        >
          Enviar para análise
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import DialogSocio from "@/components/Dialogs/DialogSocio.vue";
import VueQr from "vue-qr";
import CryptoJS from "crypto-js";
import InputNumber from "primevue/inputnumber";

const estadoInicialSocioSelecionado = {
  id: null,
  cpf: null,
  nome: null,
  administrador: false,
  email: null,
  telefone_celular: null,
  cep: null,
  endereco: null,
  numero: null,
  complemento: null,
  bairro: null,
  cidade: null,
  UF: null,
  documentos: []
};

export default {
  name: "CadastroEmpresa",
  components: {
    InputNumber,
    DialogSocio,
    VueQr
  },
  props: {
    mode: String,
    idEmpresa: {
      type: String
    }
  },
  data() {
    return {
      loadingSkeleton: false,
      step: 1,
      plataforma: "Google",
      linkDownload: "WhatsApp",
      linkPlataformaApp: this.$store.state.config.url_app_anroid,
      btnCopy: false,
      empresa: {
        tab: null,
        id: null,
        cnpj: "",
        conta_correntes: [],
        taxas: [],
        telefone: "",
        data_abertura: null,
        faturamento_medio: null,
        socios: [],
        documentos: [],
        splits: [],
        status: null,
        cnae: null,
        cep: null,
        natureza_juridica: "LTDA",
        emite_nfce: false,
        emite_nfse: false,
        id_token_nfce: null,
        csc_nfce: null,
      },
      cnaes: [],
      regime_tributario: [
        { texto: "Simples Nacional", valor: "SIMPLES_NACIONAL" },
        {
          texto: "Simples Nacional Excesso",
          valor: "SIMPLES_NACIONAL_EXCESSO"
        },
        { texto: "Regime normal", valor: "REGIME_NORMAL" }
      ],
      regime_especial_tributacao: [
        { texto: "Microempresa municipal", valor: "MICROEMPRESA_MUNICIPAL" },
        { texto: "Estimativa", valor: "ESTIMATIVA" },
        {
          texto: "Sociedade de profissionais",
          valor: "SOCIEDADE_PROFISSIONAIS"
        },
        { texto: "Cooperativa", valor: "COOPERATIVA" },
        { texto: "MEI - Simples Nacional", valor: "MEI" },
        { texto: "ME EPP - Simples Nacional", valor: "ME" },
        {
          texto: "EIRELI - Empresário Individual de Resp. Limitada",
          valor: "EIRELI"
        }
      ],
      tributacao_rps: [
        { texto: "T - Tributado em São Paulo", valor: "T" },
        { texto: "F - Tributado Fora de São Paulo", valor: "F" },
        { texto: "A - Tributado em São Paulo (Isento)", valor: "A" },
        { texto: "B - Tributado Fora de São Paulo (Isento)", valor: "B" },
        { texto: "M - Tributado em São Paulo (Imune)", valor: "M" },
        { texto: "N - Tributado Fora de São Paulo (Imune)", valor: "N" },
        {
          texto: "X - Tributado em São Paulo (Exigibilidade Suspensa)",
          valor: "X"
        },
        {
          texto: "V - Tributado Fora de São Paulo (Exigibilidade Suspensa)",
          valor: "V"
        },
        { texto: "P - Exportação de Serviços", valor: "P" },
        { texto: "C – Isenta de ISS", valor: "C" },
        { texto: "E – Não Incidência no Município", valor: "E" },
        { texto: "F – Imune", valor: "F" },
        { texto: "K – Exigibilidd Susp.Dec.J/Proc.A", valor: "K" },
        { texto: "N – Não tributável", valor: "N" },
        { texto: "T – Tributável", valor: "T" },
        { texto: "G – Tributável Fixo", valor: "G" },
        { texto: "H – Tributável S.N.", valor: "H" },
        { texto: "M – Micro Empreendedor Individual (MEI)", valor: "M" }
      ],
      doctosTipoEmpresa: [],
      currentDocument: [],
      socioDialog: {
        id: null,
        showDialog: false,
        mode: ""
      },
      detalhesSocio: {
        id: null,
        cpf: null,
        nome: null,
        administrador: false,
        email: null,
        telefone_celular: null,
        cep: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        UF: null,
        documentos: []
      },
      documentosSocioSelecionado: [],
      files: [],
      empresasMarketPlace: [],
      destinatarioSplit: null,
      splits: [],
    };
  },
  computed: {
    ...mapState(["user", "appLoading", "config"]),
    totalPercSplit() {
      if (!this.splits) return 0;

      const total = this.splits
          .reduce((accumulator, obj) => {
            return accumulator + this.parseToFloat(obj.valor ? obj.valor : 0);
          }, 0.0)
          .toFixed(2);

      return total == 100.0 ? 100 : total;
    },

    avancaStepSplit() {
      if (!this.user.permissoes.adm_split) return { msg: "", check: true };

      if (this.splits == null || this.splits.length == 0) return { msg: '', check: true };

      if (this.totalPercSplit > 100.0 || this.totalPercSplit < 100.0) {
        return { msg: `A % tem que estar em 100%!`, check: false };
      }
      let pagaTaxa = false;
      let pagaDivisao = false;
      this.splits.forEach(split => {
        pagaTaxa = split.paga_taxa_transacao ? true : pagaTaxa;
        pagaDivisao = split.paga_resto_divisao_taxa ? true : pagaDivisao;
      });

      if (!pagaTaxa || !pagaDivisao) return { msg: `Preencha Paga Taxa e Responsável Divisão!`, check: false };

      return { msg: '', check: true };
    },

    avancaStepSocios() {
      return this.empresa.socios.some(item => item.administrador);
    },

    avancaStepDocumentos() {
      const contratoSocialJaEnviado = this.empresa.documentos.some(file => file.tipo === "PAGINA_CONTRATO_SOCIAL");

      if(!contratoSocialJaEnviado) {
        return this.files.some(file => file.documento === "PAGINA_CONTRATO_SOCIAL");
      } else {
        return true;
      }
    }

  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getEmpresasMarketPLace() {
      if (!this.idEmpresa && !this.empresa.id) {
        this.empresasMarketPlace = [];
        return;
      }

      const id = this.idEmpresa ? this.idEmpresa : this.empresa.id;

      return this.$axios
        .get(`/empresa/${id}/marketplace?status=ATIVA`)
        .then(response => {
          this.empresasMarketPlace = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });

          //Acrescentando a empresa que estamos cadastrando no select do split
          this.empresasMarketPlace.unshift(this.empresa);

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },
    getEmpresa() {
      this.loadingSkeleton = true;
      this.$axios
        .get(`/empresa/${this.idEmpresa}`)
        .then(response => {
          // this.checkPermissao(response.data);
          this.empresa = Object.assign(this.empresa, response.data);
          this.empresa.cnpj = this.$masks.cnpj(this.empresa.cnpj);
          const dataFormatada = this.$options.filters.formatDate(
            this.empresa.data_abertura
          );
          if (dataFormatada) this.empresa.data_abertura = dataFormatada;

          if (this.empresa.faturamento_medio) {
            this.empresa.faturamento_medio = this.$masks.dinheiro(
              this.empresa.faturamento_medio.toFixed(2)
            );
          }

          this.empresa.cep = this.$masks.cep(this.empresa.cep);
          this.empresa.telefone = this.$masks.telefone(this.empresa.telefone);

          if(this.empresa.socios.length > 0) {
            this.detalhesSocio = Object.assign(
                this.detalhesSocio,
                this.empresa.socios[0]
            );
          }

          if(this.splits.length === 0 && this.empresa.splits.length > 0) {
            this.splits = this.empresa.splits;
          }

          this.setDoctosTipoEmpresa();
          this.getEmpresasMarketPLace();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao consultar Empresa",
            text: error.response.data
          });

          this.$router.push({
            name: "Empresas"
          });
        });
    },

    checkCnpj() {
      if (!this.empresa.cnpj) return;

      if (!this.validarCNPJ(this.empresa.cnpj)) {
        this.empresa.cnpj = "";
        this.openDialogMessage({
          type: "error",
          title: "Erro Validação",
          text: "CNPJ inválido"
        });
        this.$refs["cnpj"].focus();
        return;
      }

      const cnpj = this.$masks.unMask(this.empresa.cnpj);

      this.$axios
        .get(`/empresa/documento/${cnpj}`)
        .then(response => {
        const empresaEditavel = this.verificarStatus(response.data.status);

        if(empresaEditavel) {

          this.openDialogMessage({
            type: "warning",
            title: "Aviso!",
            text: `CNPJ já cadastrado.<br /> Atenção você está agora no modo edição desta empresa.`
          });

          this.empresa = Object.assign(this.empresa, response.data);
          this.empresa.cnpj = this.$masks.cnpj(this.empresa.cnpj);
          const dataFormatada = this.$options.filters.formatDate(
            this.empresa.data_abertura
          );
          if (dataFormatada) this.empresa.data_abertura = dataFormatada;

          if (this.empresa.faturamento_medio) {
            this.empresa.faturamento_medio = this.$masks.dinheiro(
              this.empresa.faturamento_medio.toFixed(2)
            );
          }

          this.empresa.cep = this.$masks.cep(this.empresa.cep);
          this.empresa.telefone = this.$masks.telefone(this.empresa.telefone);

          if(this.empresa.socios.length > 0) {
            this.detalhesSocio = Object.assign(
              this.detalhesSocio,
              this.empresa.socios[0]
            );
          }

          this.setDoctosTipoEmpresa();

          this.$router.push({
            name: "editEmpresa",
            params: { idEmpresa: response.data.id }
          });

        } else {

          this.openDialogMessage({
            type: "warning",
            title: "Aviso!",
            text: `CNPJ já cadastrado.<br /> Atenção você está agora no modo visualização desta empresa.`
          });

          this.$router.push({
            name: "viewEmpresa",
            params: { idEmpresa: response.data.id }
          });
        }

      });

    },

    verificarStatus(status) {
      return (
        status === "AGUARDANDO_INFORMACOES" ||
        status === "AGUARDANDO_CORRECAO" ||
        status === "AGUARDANDO_CORRECAO_SOCIOS"
      );
    },

    getEmpresaDocto(tpDocto) {
      const docto = this.empresa.documentos.find(el => el.tipo === tpDocto);
      if (docto) return docto;
    },

    getDoc(e, documento) {
      if(e) {
        this.files.push({
          documento: documento,
          file: e
        });
      }
    },

    addDocumentos() {
      for(let i = 0; i < this.files.length; i++) {
        const tpDocumento = this.files[i].documento;
        const document = this.files[i].file;
        if(this.TIPO_DOCUMENTO[tpDocumento].formato !== document.type) {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Adicionar Documento",
            text: "Formato de arquivo inválido para esse tipo de documento",
          });

          return;
        }

        const boundaryTest = CryptoJS.SHA1(document.name).toString();

        const formData = new FormData();
        formData.append("file", document);

        this.$axios
          .post(
            `/empresa/${this.empresa.id}/documento?tipo=${tpDocumento}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "file-token": boundaryTest
              }
            }
          )
          .then(response => {
            this.empresa.documentos = response.data;
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Envio Documento",
              text: error.response.data
            });
          });

      }

      this.$toast.add({
        severity: "success",
        summary: `Documentos adicionados com sucesso!`,
        life: 2000
      });

      this.getEmpresa();
      this.step = this.step + 1;
      this.files = [];

    },

    getCep() {
      if (!this.empresa.cep) return;
      this.empresa.cep = this.empresa.cep.replaceAll(".", "");
      if (this.empresa.cep.length <= 8) {
        this.openDialogMessage({
          type: "error",
          title: "Erro Validação",
          text: "CEP inválido"
        });
        this.empresa.cep = "";
        return;
      }

      this.$axios
        .get(`/public/endereco/${this.empresa.cep}`)
        .then(response => {
          if (response.data.erro) return;
          const data = {
            endereco: response.data.logradouro,
            bairro: response.data.bairro,
            cidade: response.data.localidade,
            uf: response.data.uf,
            codigo_ibge: response.data.ibge
          };
          this.empresa = Object.assign({}, this.empresa, data);
          this.$refs["numero"].focus();
        })
        .catch(() => {
          this.openDialogMessage({
            type: "error",
            title: "Erro Validação",
            text: "CEP não encontrado"
          });

          this.empresa.cep = "";
          this.$refs["cep"].focus();
          this.empresa.endereco = "";
          this.empresa.bairro = "";
          this.empresa.cidade = "";
          this.empresa.uf = "";
          this.empresa.codigo_ibge = "";
        });
    },

    getCNAES() {
      this.$axios
        .get("/public/cnae")
        .then(response => {
          this.cnaes = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao consultar CNAE",
            text: error.response.data
          });
        });
    },

    openDialogSocio(mode, item) {
      this.socioDialog = {
        id: item ? item.id : null,
        showDialog: true,
        mode: mode
      };
    },

    saveSocio(socio) {
      // Verifica se o sócio já está inserido na lista para atualizar
      const iSocioJaNaLista = this.empresa.socios.findIndex(
        pSocio => pSocio.id === socio.id
      );
      if (iSocioJaNaLista === -1) {
        this.empresa.socios.push(socio);
      } else {
        this.empresa.socios.splice(iSocioJaNaLista, 1, socio);
      }

      this.detalhesSocio = { ...estadoInicialSocioSelecionado };

      this.socioDialog.showDialog = false;
    },

    openModalRemoveSocio(socio) {
      this.openDialogConfirm({
        title: "Remover Sócio",
        text: `Deseja realmente remover o sócio ${socio.nome}?`,
        confirma: this.removeSocio.bind(this, socio),
        cancela: this.closeDialogConfirm.bind()
      });
    },

    async removeSocio(socio) {

      this.closeDialogConfirm();

      await this.$axios.delete(`/empresa/socio/${socio.id}`)
        .then(()=>{

          this.openDialogMessage({
            type: "success",
            title: "Atualizar Sócio",
            text: "Sócio deletado com sucesso.",
          });

          const indexSocio = this.empresa.socios.findIndex(item => item.id === socio.id);
          if (indexSocio !== -1) {
            this.empresa.socios.splice(indexSocio, 1);
            this.detalhesSocio = { ...estadoInicialSocioSelecionado };
          }
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Remover empresa do usuário",
            text: error.response.data
          });
        });
    },

    setDoctosTipoEmpresa() {
      if (this.naturezaJuridica === "MEI") {
        this.doctosTipoEmpresa = ["CCMEI", "COMPROVANTE_ENDERECO"];
      } else if (this.naturezaJuridica === "SA") {
        this.doctosTipoEmpresa = [
          "PAGINA_ESTATUTO_SOCIAL",
          "DECLARACAO_FATURAMENTO",
          "COMPROVANTE_ENDERECO"
        ];
      } else {
        this.doctosTipoEmpresa = [
          "PAGINA_CONTRATO_SOCIAL",
          "DECLARACAO_FATURAMENTO",
          "COMPROVANTE_ENDERECO"
        ];
      }
    },

    // addDocumento(tpDocumento, document) {
    //   if (!document) return;
    //   if (this.TIPO_DOCUMENTO[tpDocumento].formato !== document.type) {
    //     this.openDialogMessage({
    //       title: "Erro Documento",
    //       type: "error",
    //       text: "Formato inválido para o tipo de documento"
    //     });
    //     return;
    //   }
    //
    //   const boundaryTest = CryptoJS.SHA1(document.name).toString();
    //   const formData = new FormData();
    //   formData.append("file", document);
    //
    //   this.$axios
    //     .post(
    //       `/empresa/${this.empresa.id}/documento?tipo=${tpDocumento}`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           "file-token": boundaryTest
    //         }
    //       }
    //     )
    //     .then(response => {
    //       this.openDialogMessage({
    //         type: "success",
    //         title: "Envio Documento",
    //         text: "Documento enviado com sucesso."
    //       });
    //       this.empresa.documentos = response.data;
    //     })
    //     .catch(error => {
    //       this.openDialogMessage({
    //         type: "error",
    //         title: "Erro - Envio Documento",
    //         text: error.response.data
    //       });
    //     });
    // },

    getDetalhesSocio(item) {
      if (!("documentos" in item)) {
        // Se não tiver, cria a propriedade 'documentos' com um array vazio como valor
        item.documentos = [];
      }

      this.detalhesSocio = Object.assign(this.detalhesSocio, item);
    },

    submit() {
      if(this.step === 1) {
        this.$refs.btnSubmit.$el.click();
      } else {
        this.handleSave();
      }
    },

    async handleSave(solicitacaoAnalise) {
      // if (this.checkPermissao(this.empresa));
      // Copia o objeto para empresaObj
      // para desfazer formatações sem afetar os campos
      // que o usuário vê

      //Verificando se o split está preenchido corretamente;
      if ((this.empresa.natureza_juridica === 'MEI' || this.empresa.natureza_juridica === 'EIRELI' || this.empresa.natureza_juridica === 'EI') && this.step === 2 || this.step === 5) {
        const checkSplit = this.avancaStepSplit;

        if(!checkSplit.check) {
          this.$toast.add({
            severity: "error",
            summary: checkSplit.msg,
            life: 2000
          });
          return;
        }
      }

      //Verificando se foi criado o sócio ADM
      if((this.empresa.natureza_juridica !== 'MEI' && this.empresa.natureza_juridica !== 'EIRELI' && this.empresa.natureza_juridica !== 'EI') && this.step === 2) {
        const checkSocio = this.avancaStepSocios;

        if(!checkSocio) {
          this.$toast.add({
            severity: "error",
            summary: "Defina o Sócio Administrador",
            life: 2000
          });
          return;
        }

      }

      //Verificando se foi enviado contrato social
      if((this.empresa.natureza_juridica !== 'MEI' && this.empresa.natureza_juridica !== 'EIRELI' && this.empresa.natureza_juridica !== 'EI') && this.step === 3) {
        const checkDocumento = this.avancaStepDocumentos;

        if(!checkDocumento) {
          this.$toast.add({
            severity: "error",
            summary: "Contrato social obrigatório!",
            life: 2000
          });
          return;
        }

      }

      const empresaObj = { ...this.empresa };
      empresaObj.cnpj = this.$masks.unMask(empresaObj.cnpj);
      empresaObj.data_abertura = empresaObj.data_abertura
          .split("/")
          .reverse()
          .join("-");
      empresaObj.faturamento_medio = this.parseToFloat(
          empresaObj.faturamento_medio
      );

      empresaObj.marketplace = { id: this.config.id_marketplace };
      empresaObj.usuarios = [
        {
          id: this.user.id
        }
      ];

      //Enviando indice splits somente quando estiver na tela do split
      if ((this.empresa.natureza_juridica === 'MEI' || this.empresa.natureza_juridica === 'EIRELI' || this.empresa.natureza_juridica === 'EI') && this.step === 2 || this.step === 5) {
        empresaObj.splits = this.splits;
      }

      this.mode === "create" && !this.empresa.id
          ? await this.save(empresaObj)
          : await this.edit(empresaObj, solicitacaoAnalise);

    },

    handleBack() {
      if (this.step === 1) {
        this.$router.push({
          name: "Empresas"
        });
      } else {
        this.step = this.step - 1;
      }
    },

    handleConcluir() {
      this.$router.push({
        name: "Empresas"
      });
    },

    async enviarParaAnalise(obj) {
      await this.$axios
        .post(`/empresa/${obj.id}/analisar`)
        .then(response => {
          this.getEmpresa();

          this.openDialogMessage({
            type: "success",
            title: "Solicitação",
            text: response.data
          });

          this.$router.push({
            name: "Empresas"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Solicitação",
            text: error.response.data
          });
        });
    },

    async save(empresaObj) {
      await this.$axios
        .post("/empresa", empresaObj)
        .then(async response => {
          this.$toast.add({
            severity: "success",
            summary: `Empresa Cadastrada com sucesso!`,
            life: 2000
          });
          this.empresa.id = response.data.id;
          this.$router.push({
            name: "editEmpresa",
            params: { idEmpresa: response.data.id }
          });
          await this.getEmpresasMarketPLace();
          this.step = 2;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Cadastrar",
            text: error.response.data
          });
        });
    },

    async edit(obj, solicitacaoAnalise) {
      await this.$axios
        .put("/empresa", obj)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Empresa Atualizada com sucesso!`,
            life: 2000
          });

          if (solicitacaoAnalise) {
            this.enviarParaAnalise(obj);
            return;
          } else {

            //Verificando se tem documento enviado para salvar.
            if(this.files.length > 0) {
              this.addDocumentos();
            } else {
              this.getEmpresa();
              this.step = this.step + 1;
            }
          }

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Atualizar",
            text: error.response.data
          });
        });
    },

    handleChangePlataforma(item) {
      if (item === "Google") {
        this.linkPlataformaApp =
            this.$store.state.config.url_app_anroid;
      }

      if (item === "Apple") {
        this.linkPlataformaApp =
            this.$store.state.config.url_app_ios;
      }
    },

    handleCopy(item) {
      navigator.clipboard
        .writeText(item)
        .then(() => {
          this.btnCopy = true;
          setTimeout(this.changeButtonCopy, 1500);
        })
        .catch(err => {
          alert("Erro ao copiar o LINK:");
          console.error("Erro ao copiar o LINK:", err);
        });
    },

    changeButtonCopy() {
      this.btnCopy = false;
    },

    async handleEnviarWhatsapp() {
      const idAppStore = this.$store.state.config.url_app_ios.split("id")[1];
      const idGooglePlay = this.$store.state.config.url_app_anroid.split("id=")[1];
      await this.$axios
        .post("/mensageria/whatsapp", {
          titulo: this.$store.state.config.nome,
          template: "msg_abertura_conta",
          destinatario: this.empresa.telefone.replace(/\D/g, ""),
          parametros: [this.$store.state.config.nome],
          botoes: [idGooglePlay, `sisbank/id${idAppStore}`]
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Mensagem enviada com sucesso!`,
            life: 2000
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Atualizar",
            text: error.response.data
          });
        });
    },

    async handleEnviarSms() {
      await this.$axios
        .post("/mensageria/sms", {
          identificacao: this.empresa.id,
          destinatario: this.empresa.telefone.replace(/\D/g, ""),
          mensagem: `Olá empreendedor ${this.$store.state.config.nome}\nBaixe agora o app do banco SISBANK e efetue a abertura de conta para concluir a ativação do seu cadastro.\nEsta conta será utilizada para o pagamento de todas as suas vendas.\nAppleStore: ${this.$store.state.config.url_app_ios}\nPlayStore: ${this.$store.state.config.url_app_anroid}\n`
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Mensagem enviada com sucesso!`,
            life: 2000
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Atualizar",
            text: error.response.data
          });
        });
    },

    handleEnviarLinkDownload() {
      if (this.linkDownload === "SMS") {
        this.handleEnviarSms();
      } else {
        this.handleEnviarWhatsapp();
      }
    },

    addSplit() {
      if (!this.destinatarioSplit || !this.destinatarioSplit.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Selecione a empresa de destino do split"
        });
        return;
      }

      const findSplit = this.splits.some(
          split => split.recebedor.id === this.destinatarioSplit.id
      );
      if (findSplit) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Empresa já está adicionada à lista de splits."
        });
        return;
      }
      const splitData = {
        recebedor: {
          id: this.destinatarioSplit.id,
          razao_social: this.destinatarioSplit.razao_social,
          nome_fantasia: this.destinatarioSplit.nome_fantasia,
          cnpj: this.destinatarioSplit.cnpj,
          enderecoCompleto: this.destinatarioSplit.enderecoCompleto
        },
        valor: 0,
        metodo: "PERCENTUAL",
        paga_taxa_transacao: this.destinatarioSplit.id == this.idEmpresa,
        paga_resto_divisao_taxa:
            this.destinatarioSplit.id == this.idEmpresa
      };
      this.splits.push(splitData);
      this.destinatarioSplit = null;
    },

    openModalRemoveSplit(split, index) {
      this.openDialogConfirm({
        title: "Remover Split",
        text: `Deseja realmente o split da empresa ${split.recebedor.razao_social}?`,
        confirma: this.removeSplit.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },

    removeSplit(index) {
      this.splits.splice(index, 1);
      this.closeDialogConfirm();
    },

  },
  async mounted() {
    this.getCNAES();
    if (this.mode !== "create") this.getEmpresa();
  }
};
</script>
<style scoped>
.card-principal {
  border: 0.5px solid #ebebeb !important;
}

.subtitle-text {
  color: #939393;
  font-size: 0.7rem;
}

.custom-card-ativo {
  background-color: white !important;
  border: 0.5px solid #0875ee !important;
  color: #0875ee !important;
  border-radius: 10px !important;
  padding: 8px !important;
}

.custom-card {
  background-color: white !important;
  border: 0.5px solid #ebebeb !important;
  border-radius: 10px !important;
  padding: 8px !important;
}

.custom-divider-vertical,
.custom-divider-vertical-app {
  background-color: #ebebeb !important;
}

.custom-divider-horizontal {
  display: none;
  height: 3px !important;
  max-height: 3px !important;
  background-color: #939393 !important;
}

.icon-text-container img {
  margin-left: 18px;
  width: 70px;
}

.card-qrcode {
  background-color: black;
  border-radius: 20px;
  width: 250px;
}

@media (max-width: 959px) {
  .custom-divider-vertical-app {
    display: none;
  }

  .coluna-simpesnacional-incentivadorcultural {
    margin-top: -50px;
  }

  .custom-divider-vertical {
    display: none;
  }

  .custom-divider-horizontal {
    display: block;
  }
}

@media (max-width: 645px) {
  .div-alert {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .div-alert div {
    margin-top: 15px;
  }

}

@media (max-width: 400px) {
  .card-socio {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .card-qrcode {
    width: 200px;
  }

  .img-qrcode {
    height: 160px !important;
  }

  .img-plataforma {
    height: 50px !important;
  }
}

@media (max-width: 330px) {
  .card-qrcode {
    width: 180px;
  }

  .img-qrcode {
    height: 140px !important;
  }

  .img-plataforma {
    height: 40px !important;
  }
}


</style>
