<template>
  <v-container>
    <v-row>
      <v-col>
        <router-view
          @atualiza-dados="getUsuario"
          :usuario="usuario"
        ></router-view>
      </v-col>
      <v-col cols="3">
        <v-row dense>
          <v-col>
            <v-card>
              <v-list dense>
                <v-subheader class="text-subtitle-1 font-weight-bold ">
                  Ações</v-subheader
                >
                <v-divider></v-divider>
                <v-list-item-group v-model="itemMenu" color="primary">
                  <v-list-item
                    class="item-list"
                    @click="
                      acao.link
                        ? $router.push({
                            name: acao.link
                          })
                        : null
                    "
                    link
                    :disabled="!acao.link"
                    v-for="acao of acoes"
                    :key="acao.icon"
                  >
                    <v-list-item-avatar>
<!--                      <i class="fa-style" :class="acao.icon"></i>-->
                      <v-icon>mdi-magnify</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="text-subtitle-2">
                      {{ acao.text }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MinhaConta",
  data() {
    return {
      usuario: {},
      acoes: [
        {
          text: "Dados",
          icon: "mdi-account",
          link: "MinhaContaDados"
        },
        {
          text: "Empresas",
          icon: "mdi-home-modern",
          link: "MinhaContaEmpresas"
        },
        {
          text: "Alterar Senha",
          icon: "mdi-lock",
          link: "MinhaContaAltSenha"
        }
      ],
      itemMenu: 0
    };
  },

  mounted() {
    this.getUsuario();
  },
  computed: mapState(["user", "openDialogMessage"]),
  methods: {
    getUsuario() {
      this.$axios
        .get(`/usuario/${this.user.id}`)
        .then(response => {
          this.usuario = response.data;
          this.usuario.cpf = this.$masks.cpf(this.usuario.cpf);
          this.usuario.telefone = this.$masks.telefone(this.usuario.telefone);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Usuário",
            text: error.response.data
          });
        });
    },
    setItemMenu() {
      const path = this.$route.fullPath.split("/").reverse()[0];

      switch (path) {
        case "extrato":
          this.itemMenu = 0;
          break;

        case "tef":
          this.itemMenu = 1;
          break;

        case "red":
          this.itemMenu = 2;
          break;
        case "pagamentos":
          this.itemMenu = 3;
          break;
      }
    }
  }
};
</script>

<style scoped>
.border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
