<template>
  <v-container fluid>
    <v-row>
      <div class="espacado">
        <MultiSelect
          v-model="filtros.status"
          :options="status"
          @change="getAssinaturas(true)"
          optionLabel="name"
          placeholder="Filtro por status"
          display="chip"
        />
      </div>
      <div class="espacado">
        <Chips
          ref="chipcomponente"
          v-model="filtros.titular"
          separator=","
          @add="getAssinaturas(true)"
          @remove="getAssinaturas(true)"
          placeholder="Filtro por protocolo, nome, telefone, email"
        />
      </div>
      <div class="espacado">
        <BotaoBuscarListas @buscar="buscar" />
      </div>

      <v-col class="text-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              small
              color="primary"
              :to="{ name: 'addAssinatura' }"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Nova assinatura</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              dense
              :fixed-header="true"
              :headers="headers"
              :items="assinaturas"
              :loading="carregando"
              :calculate-widths="true"
              loading-text="Carregando dados"
              :search="search"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                  class="ma-2 text-center"
                  :color="STATUS_ASSINATURA[item.status].cor"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
              >
                {{ STATUS_ASSINATURA[item.status].texto }}
              </v-chip>
            </template>
            <template v-slot:[`item.data_cadastro`]="{ item }">
              {{ item.data_cadastro | formatDate({ dateStyle: "short" }) }}
            </template>
            <template v-slot:[`item.data_validade`]="{ item }">
              {{ item.data_validade | formatDate({ dateStyle: "short" }) }}
            </template>
            <template v-slot:[`item.titular`]="{ item }">
              {{ item.titular.cpf || item.titular.cnpj }}
            </template>
            <template v-slot:[`item.plano`]="{ item }">
              {{
                valorPlano(item.plano) | formatMoney({ minimumFractionDigits: 2 })
              }}
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <v-btn
                  icon
                  :to="{
                name: 'viewAssinatura',
                params: { idAssinatura: item.id.toString() }
              }"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-skeleton-loader>
        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
        <v-pagination
          v-model="pagination.page"
          :length="totalPaginas"
          @input="next"
          :total-visible="10"
        >
        </v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  data() {
    return {
      loadingSkeleton: false,
      assinaturas: [],
      carregando: false,
      search: null,
      headers: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Protocolo",
          align: "center",
          sortable: false,
          value: "protocolo"
        },
        {
          text: "Método",
          align: "center",
          sortable: false,
          value: "forma_pagamento"
        },
        {
          text: "Cadastro",
          align: "center",
          sortable: false,
          value: "data_cadastro"
        },
        {
          text: "Validade",
          align: "center",
          sortable: false,
          value: "data_validade"
        },
        {
          text: "Documento",
          align: "center",
          sortable: false,
          value: "titular"
        },
        {
          text: "Nome Titular",
          align: "center",
          sortable: false,
          value: "titular.nome"
        },

        {
          text: "Nome Plano",
          align: "center",
          sortable: false,
          value: "plano.descricao"
        },
        {
          text: "Valor",
          align: "center",
          sortable: false,
          value: "plano"
        },
        {
          text: "Ver",
          value: "acoes"
        }
      ],
      filtros: {
        status: this.$store.state.filtrosAssinaturas.status,
        titular: this.$store.state.filtrosAssinaturas.titular
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first: 0
      },
      status: [
        { name: "Ativa", code: "ATIVA" },
        { name: "Pendente", code: "PENDENTE" },
        { name: "Cancelada", code: "CANCELADA" },
        { name: "Encerrada", code: "ENCERRADA" },
        { name: "Falha no Pagamento", code: "FALHA_PAGAMENTO" },
        { name: "Degustação", code: "DEGUSTACAO" }
      ]
    };
  },
  mounted() {
    this.getAssinaturas();
  },
  components: {
    MultiSelect,
    Chips,
    BotaoBuscarListas
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getAssinaturas(filtro = false) {

      this.loadingSkeleton = true;

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosAssinaturas", {
        status: this.filtros.status,
        titular: this.filtros.titular
      });

      this.$axios
        .get(`/assinatura/empresa/${this.empresaAtual.id}`, {
          headers: {
            filtros: JSON.stringify({
              titular: this.filtros.titular,
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.assinaturas = response.data.data;
          //this.pagination.page = 1;
          this.pagination.total = response.data.count;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Assinatura",
            text: error.response.data
          });
        });
    },
    valorPlano(plano) {
      if (!plano || !plano.itens) return 0;

      let total = 0.0;

      plano.itens.forEach(item => {
        total =
          parseFloat(total) +
          (parseFloat(item.quantidade * item.valor) - item.desconto);
      });

      return total.toFixed(2);
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getAssinaturas();
    },
    exportar() {
      this.$axios
        .get(`/assinatura/empresa/${this.empresaAtual.id}`, {
          headers: {
            filtros: JSON.stringify({
              titular: this.filtros.titular,
              status: this.filtros.status.map(m => m.code)
            })
          }
        })
        .then(response => {
          const copy = response.data.data.map(item => {
            const obj = {};
            obj.empresa_cnpj = item.empresa.cnpj;
            obj.empresa_nome_fantasia = item.empresa.nome_fantasia;
            obj.assinatura_id = item.id;
            obj.assinatura_status = item.status;
            obj.assinatura_protocolo = item.protocolo;
            obj.assinatura_data_cadastro = item.data_cadastro.split("T")[0];
            obj.assinatura_data_atualizada = item.data_atualizada.split("T")[0];
            obj.assinatura_data_validade = item.data_validade.split("T")[0];
            obj.assinatura_data_dia_vencimento = item.dia_vencimento;
            obj.assinatura_forma_pagamento = item.forma_pagamento;
            obj.assinatura_titular_cpf = item.titular.cpf;
            obj.assinatura_titular_cnpj = item.titular.cnpj;
            obj.assinatura_titular_nome = item.titular.nome;
            obj.assinatura_titular_razao_social = item.titular.razao_social;
            obj.assinatura_titular_telefone_celular =
              item.titular.telefone_celular;
            obj.assinatura_titular_email = item.titular.email;
            obj.assinatura_titular_cep = item.titular.cep;
            obj.assinatura_plano_id = item.plano.id;
            obj.assinatura_plano_descricao = item.plano.descricao;
            obj.assinatura_plano_valor = item.plano.valorTotal;

            return obj;
          });

          this.toCSVeDownload("assinaturas.csv", copy);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar assinaturas",
            text: error.response.data
          });
        });
    },
    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.titular.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
  }
};
</script>

<style></style>
