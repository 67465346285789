<template>
  <div>
    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="table"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <form @submit.prevent="handleSave">
        <v-row dense>
          <v-col cols="12" md="12">
            <div class="d-flex justify-start">
              <v-switch
                  :disabled="mode === 'view'"
                  v-model="plano.status"
                  :label="plano.status ? 'Ativo' : 'Inativo'"
              ></v-switch>
              <v-switch
                  class="ml-3"
                  :disabled="mode === 'view'"
                  v-model="plano.disponivel_ecommerce"
                  label="Disponível ecommerce"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
                :readonly="mode == 'view'"
                label="Descrição"
                v-model="plano.descricao"
                required
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                :disabled="mode == 'view'"
                label="Dias de validade"
                type="number"
                :min="1"
                v-model="plano.dias_validade"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
                :disabled="mode == 'view'"
                label="Degustação"
                type="number"
                v-model="plano.dias_degustacao"
            >
            </v-text-field>
          </v-col>
          <v-col cols="10">
            <v-textarea
              outlined
              rows="5"
              counter="150"
              :readonly="mode == 'view'"
              label="Detalhes"
              v-model="plano.detalhes"
            >
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p class="text-subtitle-2 text-center">Itens</p>

            <div class="text-end">
              <v-tooltip bottom v-if="mode != 'view'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      dark
                      small
                      color="primary"
                      @click="openDialogItemPlano"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar Item</span>
              </v-tooltip>
            </div>

            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th scope="col" class="text-left">Descrição</th>
                  <th scope="col" class="text-left">Quantidade</th>
                  <th scope="col" class="text-left">Valor Un.</th>
                  <th scope="col" class="text-left">Valor Bruto</th>
                  <th scope="col" class="text-left">Desconto</th>
                  <th scope="col" class="text-left">Total</th>
                  <th scope="col" class="text-end" v-if="mode != 'view'">
                    Ações
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in plano.itens" :key="item.id">
                  <td v-if="item.servico">
                    {{ item.servico?.sku }} - {{ item.servico?.nome }}
                  </td>
                  <td v-if="item.produto">
                    {{ item.produto?.nome }}
                  </td>
                  <td>
                    {{ item.quantidade }}
                  </td>
                  <td>
                    {{ item.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                  </td>
                  <td>
                    {{
                      (item.quantidade * item.valor)
                          | formatMoney({ minimumFractionDigits: 2 })
                    }}
                  </td>
                  <td>
                    {{
                      item.desconto | formatMoney({ minimumFractionDigits: 2 })
                    }}
                  </td>
                  <td>
                    {{
                      totalItem(item)
                          | formatMoney({ minimumFractionDigits: 2 })
                    }}
                  </td>
                  <td class="text-end" v-if="mode != 'view'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            small
                            color="primary"
                            @click="openConfirmDeleteItemPlano(index)"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Remover Empresa</span>
                    </v-tooltip>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-end">
            <p class="valor">R$ {{ calculaTotal }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-end">
            <v-btn color="primary" type="submit" v-if="mode !== 'view'">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </form>
      <DialogItemPlano
          :showDialog="dialogItemPlano.showDialog"
          @salva-dados="saveDialogItemPlano"
          @cancela-dialog="cancelaDialogItemPlano"
      />
    </v-container>
  </div>
</template>

<script>
import DialogItemPlano from "@/components/Dialogs/DialogItemPlano.vue";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    DialogItemPlano
  },
  props: {
    mode: String,
    idPlano: Number
  },
  data() {
    return {
      loadingSkeleton: false,
      plano: {
        status: true,
        disponivel_ecommerce: true,
        itens: []
      },
      dialogItemPlano: {
        showDialog: false
      }
    };
  },
  mounted() {
    if (this.mode != "create") this.getPlano();
  },
  computed: {
    ...mapState(["empresaAtual"]),
    calculaTotal() {
      let total = 0.0;

      this.plano.itens.forEach(item => {
        total =
          parseFloat(total) +
          parseFloat(item.quantidade * item.valor) -
          item.desconto;
      });

      return total.toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getPlano() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/plano/${this.idPlano}`)
        .then(response => {
          this.plano = response.data;
          this.plano.status = this.plano.status === "ATIVO" ? true : false;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Plano",
            text: error.response.data
          });
        });
    },
    handleSave() {
      const plano = { ...this.plano };

      plano.status = plano.status ? "ATIVO" : "INATIVO";
      plano.empresa_id = this.empresaAtual.id;
      this.mode === "create" ? this.save(plano) : this.edit(plano);
    },
    save(plano) {
      if (plano.dias_degustacao == "") {
        delete plano.dias_degustacao;
      }

      this.$axios
        .post("/plano", plano)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Plano cadastrado com sucesso.`,
            life: 2000
          });

          this.$router.push({ name: "Planos" });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar Plano",
            text: error.response.data
          });
        });
    },
    edit(plano) {
      if (plano.dias_degustacao == "") {
        delete plano.dias_degustacao;
      }

      this.$axios
        .put("/plano", plano)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: `Plano atualizado com sucesso.`,
            life: 2000
          });
          this.$router.push({ name: "Planos" });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Plano",
            text: error.response.data
          });
        });
    },
    openDialogItemPlano() {
      this.dialogItemPlano.showDialog = true;
    },
    cancelaDialogItemPlano() {
      this.dialogItemPlano.showDialog = false;
    },
    saveDialogItemPlano(item) {

      let adicionadoServico = [];
      let adicionadoProduto = [];

      if (item.servico) {
        adicionadoServico = this.plano.itens.filter(obj => obj.servico?.id === item.servico?.id).length;
      }

      if (item.produto) {
        adicionadoProduto = this.plano.itens.filter(obj => obj.produto?.id === item.produto?.id).length;
      }

      if(adicionadoServico > 0){
        this.openDialogMessage({
            type: "warning",
            title: "Atenção",
            text: "Este serviço já foi adicionado"
          });
          return
      }

      if(adicionadoProduto > 0){
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Este produto já foi adicionado"
        });
        return
      }

      this.plano.itens.push(item);
      this.dialogItemPlano.showDialog = false;
    },

    openConfirmDeleteItemPlano(index) {
      this.openDialogConfirm({
        title: "Deletar Item",
        text: "Tem certeza que deseja excluir esse item?",
        confirma: this.deleteItemPlano.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    deleteItemPlano(index) {
      this.plano.itens.splice(index, 1);
      this.closeDialogConfirm();
    },
    totalItem(item) {
      if (!item && !item.servico && !item.produto) return 0;

      const qtd = this.parseToFloat(item.quantidade ? item.quantidade : 1);
      const valorServico = this.parseToFloat(item.valor);
      const totalBruto = qtd * valorServico;

      return totalBruto - item.desconto;
    }
  }
};
</script>

<style>
.valor {
  font-size: 2rem;
  margin: 0.5rem;
}
</style>
