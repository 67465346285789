<template>
  <!-- SKELETON -->
  <v-container v-if="loadingSkeleton" fluid>
    <v-row>
      <v-col cols="6">
        <v-row class="grey lighten-5 px-16 mt-10">
          <v-col cols="6">
            <v-skeleton-loader
                :loading="true"
                type="heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text@4"
            ></v-skeleton-loader>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6">
            <v-skeleton-loader
                :loading="true"
                type="heading"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text@4"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
        <v-row class="px-16 mt-5">
          <v-col>
            <v-skeleton-loader
                :loading="true"
                type="text"
            ></v-skeleton-loader>
            <v-skeleton-loader
                :loading="true"
                type="image"
                height="50"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-skeleton-loader
            :loading="true"
            type="table"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else fluid>
    <v-row>
      <v-col>
        <p class="mr-2 font-weight-regular">Mesa de antecipação</p>
        <v-row>
          <v-col>
            <CardRecebiveisBruto @total-bruto-recebivel="setMaximoRecebivel" />
          </v-col>
          <v-col>
            <CardRecebiveisLiquido :totais="totais" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="centralizado">

            <label class="d-flex titulo" for="minmaxfraction">
              Valor desejado
            </label>

            <span class="p-input-icon-left p-input-icon-right">
                <i style="width: fit-content;">R$</i>
                  <InputNumber
                    class="input-big"
                    inputmode="numeric"
                    mode="decimal"
                    v-model="valorDesejado"
                    :minFractionDigits="2"
                    :maxFractionDigits="2"
                    :max="maximoRecebivel"
                    aria-describedby="help"
                    @keyup="onInput"
                  />
              </span>

              <small class="d-flex" id="help">Valor total que será antecipado</small>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="centralizado">

            <Button label="Confirmar"
                    :loading="executando"
                    :disabled="executando || valorDesejado <= 5"
                    class="p-button-success"
                    @click="openModalConfirmSolicitacao"/>


          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <p class="mr-2 font-weight-regular">Pedidos</p>

        <DataTable :value="pedidos" responsiveLayout="stack" removableSort
                  sortField="data_criacao" :sortOrder="-1"
                  breakpoint="960px" scrollHeight="flex-row"
                  @row-select="onRowSelect" selectionMode="single"
                  dataKey="id">

            <Column field="data_criacao" header="Solicitado" sortable>
              <template #body="slot">
                  {{  slot.data.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }}
              </template>
            </Column>
            <Column field="data_liquidacao" header="Liquidação" sortable>
              <template #body="slot">
                  {{  slot.data.data_liquidacao | formatDate({ dateStyle: "short" }) }}
              </template>
            </Column>

            <Column field="valor_bruto_antecipado" header="Antecipado">
              <template #body="slot">
               R$ {{ slot.data.valor_bruto_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
              </template>
            </Column>

            <Column field="valor_taxa_antecipacao" header="Taxa">
              <template #body="slot">
               R$ {{ slot.data.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2 })}}
              </template>
            </Column>

            <Column field="valor_liquido_antecipado" header="Liquidação">
              <template #body="slot">
                R$ {{ slot.data.valor_liquido_antecipado | formatMoney({ minimumFractionDigits: 2 })}}
              </template>
            </Column>

            <template #empty>
                <p class="centralizado">Nenhum pedido de antecipação pontual encontrado. </p>
            </template>
        </DataTable>
      </v-col>
    </v-row>
    <DialogPedidoAntecipacao :pedido="pedido" :fechar="()=> pedido = null" />
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import CardRecebiveisBruto from "@/components/Cards/CardRecebiveisBruto.vue";
import CardRecebiveisLiquido from "@/components/Cards/CardRecebiveisLiquido.vue";
import DialogPedidoAntecipacao from '@/components/Dialogs/DialogPedidoAntecipacao';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from "primevue/inputnumber";
import Button from 'primevue/button';
import { debounce } from 'lodash';


function resetTotais(){
  return {
    valor_bruto: 0,
    valor_liquido: 0,
    total_taxas: 0
  }
}

export default {


  components: {
    CardRecebiveisBruto,
    CardRecebiveisLiquido,
    DialogPedidoAntecipacao,
    DataTable,
    Column,
    InputNumber,
    Button
  },
  data() {
    return {
      loadingSkeleton: true,
      pedidos: [],
      pedido: null,
      maximoRecebivel: 0,
      valorDesejado: 0,
      totais: resetTotais(),
      executando: false,
      onInput : null
    };
  },
  computed: mapState(["empresaAtual"]),
  beforeUpdate() {
    if (this.valorDesejado >= this.maximoRecebivel) {
      this.valorDesejado = this.maximoRecebivel;
    }
  },
  mounted(){
    this.getPedidos();
  },
  created(){
    this.onInput = debounce((event)=>{
      if(event.key == 'Backspace' && this.valorDesejado <= 0){
        this.totais = resetTotais();
        return
      }
      this.getCalculaAntecipacao();

    }, 1500)
  },
  methods: {
    ...mapMutations(["openDialogMessage","openDialogConfirm","closeDialogConfirm"]),
    setMaximoRecebivel(value) {
      this.maximoRecebivel = value;
      this.valorDesejado = value;
      this.getCalculaAntecipacao();
    },
    onRowSelect(event){
      this.pedido = event.data;
    },
    atualizarDados(){
      this.$emit('reload')
      this.getPedidos();
    },
    getCalculaAntecipacao() {
      if (this.valorDesejado < 5) {
        this.totais = resetTotais()
        return;
      }
      this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/calcula-antecipacao?valor=${this.valorDesejado}`
        )
        .then(response => {
          this.totais = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Consultar os recebíveis",
            text: error.response.data
          });
        });
    },
    getPedidos() {
      this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/pedidos-antecipacao`
        )
        .then(response => {
          this.pedidos = response.data.data;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel consultar os pedidos anteriores.",
            text: error.response.data
          });
        });
    },
    openModalConfirmSolicitacao() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Deseja realmente solicitar a antecipação do valor total de R$ ${this.valorDesejado.toFixed(2)}? Esta ação não poderá ser desfeita.`,
        confirma: () => {
          this.solicitaAntecipacao();
          this.closeDialogConfirm();
        },
        cancela: this.closeDialogConfirm,
      });
    },
    solicitaAntecipacao() {
      this.executando = true;
      this.$axios
        .post(`/recebivel/empresa/${this.empresaAtual.id}/pedido-antecipacao`, {
          valor: this.valorDesejado
        })
        .then(response => {
          this.pedido = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel solicitar a antecipação ",
            text: error.response.data
          });
        })
        .finally(() => {
          this.executando = false;
          this.atualizarDados();
        });
    }
  }
};
</script>

<style scoped>
@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
.titulo {
  font-size: 1.5rem;
}

.p-button-success{
  background: #33b35b !important;
  border: 1px solid #33b35b !important;
  width: 100%;
  max-width: 20rem;
}

</style>
