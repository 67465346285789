<template>
  <div>
    <v-container v-if="loadingSkeleton" fluid text-center>
      <v-row style="justify-content: center;">
        <v-col fluid cols="5">
          <v-row>
            <v-skeleton-loader
                :loading="true"
                type="heading"
                width="50%"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text@4"
                width="50%"
            ></v-skeleton-loader>
          </v-row>
          <v-row>
            <v-skeleton-loader
                :loading="true"
                type="heading"
                width="50%"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text"
                width="50%"
            ></v-skeleton-loader>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>
          <v-row>
            <v-skeleton-loader
                class="mt-2"
                :loading="true"
                type="heading"
                width="50%"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text@4"
                width="50%"
            ></v-skeleton-loader>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>

          <v-row>
            <v-skeleton-loader
                class="mt-2"
                :loading="true"
                type="heading"
                width="50%"
            ></v-skeleton-loader>
            <v-skeleton-loader
                class="mt-3"
                :loading="true"
                type="text@4"
                width="50%"
            ></v-skeleton-loader>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>

          <v-row>
            <v-col class="text-box">
              <v-skeleton-loader
                  class="mt-2"
                  :loading="true"
                  type="text@5"
              ></v-skeleton-loader>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="contrato.id && !loadingSkeleton" fluid text-center>
      <v-row style="justify-content: center;">
        <v-col fluid cols="5">
          <v-row>
            <v-col class="text-box">
            <span>
              Contrato
            </span>
            </v-col>
            <v-col class="text-box-end">
              <span> #{{ contrato.numero }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-box">
              <span>STATUS</span>
            </v-col>
            <v-col class="text-box-end">
              <v-chip
                  class="ma-2 text-center"
                  v-if="contrato.status"
                  :color="STATUS_CONTRATO[contrato.status].cor"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
              >
                {{ STATUS_CONTRATO[contrato.status].texto }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>
          <v-row>
            <v-col class="text-box">
              <span> Emissor </span>
            </v-col>
            <div class="text-box-end">
              <span> {{ contrato.recebedor.nome_fantasia }} </span><br />
              <span class="text-caption">
              {{ contrato.recebedor.razao_social }}
            </span>
              <p>
                CNPJ {{ $masks.cnpj(contrato.recebedor.cnpj) + " | " }} TEL
                {{ $masks.telefone(contrato.recebedor.telefone) }}
                <br />
                {{ contrato.recebedor.endereco }},
                {{ contrato.recebedor.numero }} -
                {{ contrato.recebedor.cidade }} ,
                {{ contrato.recebedor.uf }}
              </p>
            </div>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>

          <v-row>
            <v-col class="text-box">
              <span> Pagador </span>
            </v-col>
            <div class="text-box-end">
              <div>
                <span> {{ contrato.pagador.nome_fantasia }} </span>
              </div>
              <div>
              <span class="text-caption">
                {{ contrato.pagador.razao_social }}
              </span>
              </div>
              <p>
                CNPJ {{ $masks.cnpj(contrato.pagador.cnpj) + " | " }} TEL
                {{ $masks.telefone(contrato.pagador.telefone) }}
                <br />
                {{ contrato.pagador.endereco }}, {{ contrato.pagador.numero }} -
                {{ contrato.pagador.cidade }} ,
                {{ contrato.pagador.uf }}
              </p>
            </div>
          </v-row>
          <v-row>
            <v-divider horizontal></v-divider>
          </v-row>
          <v-row class="bg">
            <v-col class="text-box">
              <span>Data de inicio</span>
            </v-col>
            <v-col class="text-box-end">
            <span>
              {{
                contrato.data_inicio_contrato
                    | formatDate({ dateStyle: "short" })
              }}
            </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-box">
              <span>Data de Termino</span>
            </v-col>
            <v-col class="text-box-end">
            <span>
              {{
                contrato.data_termino_contrato
                    | formatDate({ dateStyle: "short" })
              }}
            </span>
            </v-col>
          </v-row>

          <v-row class="bg">
            <v-col class="text-box">
              <span>Modalidade</span>
            </v-col>
            <v-col class="text-box-end">
            <span>
              {{ OPCOES_PAGTO[contrato.modalidade].texto }}
            </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-box">
              <span>Valor total</span>
            </v-col>
            <v-col class="text-box-end">
            <span>
              R$
              {{
                contrato.valor_total_contrato
                    | formatMoney({ minimumFractionDigits: 2 })
              }}
            </span>
            </v-col>
          </v-row>

          <v-row class="bg">
            <v-col class="text-box">
              <span>Parcelas</span>
            </v-col>
            <v-col class="text-box-end">
            <span>
              {{ contrato.qtd_parcelas + "x" }}
              R$
              {{
                contrato.valor_parcela
                    | formatMoney({ minimumFractionDigits: 2 })
              }}
            </span>
            </v-col>
          </v-row>
          <v-row v-if="contrato.status == 'AGUARDANDO_ACEITE' && !isSocioLogado">
            <v-col>
              <Message severity="error" :closable="false">
                Somente sócios da empresa podem aprovar este contrato.
              </Message>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="botoes">
              <v-btn outlined :to="{ name: 'viewContrato' }">
                Voltar
              </v-btn>

              <v-btn
                  class="error botao_recusar"
                  @click="openModalCancelaContrato()"
                  v-if="contrato.status == 'AGUARDANDO_ACEITE' && isSocioLogado"
              >
                Recusar
              </v-btn>

              <v-btn
                  class="success"
                  @click="openModalAceiteContrato()"
                  v-if="contrato.status == 'AGUARDANDO_ACEITE' && isSocioLogado"
              >
                Aceitar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Message from "primevue/message";

export default {
  name: "AceiteDeContrato",
  components: {
    Message
  },
  props: {
    idContrato: String
  },
  computed: {
    ...mapState(["config", "user"]),
    isSocioLogado() {
      return this.sociosPagador.find(
        socio =>
          socio.cpf.replace(/\D/g, "") === this.user.cpf.replace(/\D/g, "")
      );
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      contrato: {
        id: null
      },
      sociosPagador: []
    };
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getContrato() {
      return this.$axios
        .get(`/contrato/${this.idContrato}`)
        .then(async response => {
          this.contrato = response.data;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar contrato",
            text: error.response.data
          });
          this.showDialogEditPagto = false;
        });
    },
    openModalCancelaContrato() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Deseja realmente recusar o contrato? Está ação não pode ser desfeita!`,
        confirma: this.cancelar,
        cancela: this.closeDialogConfirm
      });
    },
    openModalAceiteContrato() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Você realmente autoriza à ativação desses contratos ? os débitos referentes as essas parcelas não poderam ser extornados!`,
        confirma: this.confirmaContrato,
        cancela: this.closeDialogConfirm
      });
    },
    confirmaContrato() {
      this.closeDialogConfirm();
      this.$axios
        .put(`/contrato/${this.contrato.id}/aceitar`)
        .then(() => {
          this.$router.push({
            name: "viewContrato",
            params: { idContrato: this.contrato.id }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Contrato",
            text: error.response.data
          });
        });
    },
    cancelar() {
      this.closeDialogConfirm();
      this.$axios
        .put(`/contrato/${this.contrato.id}/cancelar`)
        .then(() => {
          this.$router.push({
            name: "viewContrato",
            params: { idContrato: this.contrato.id }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Contrato",
            text: error.response.data
          });
        });
    },
    populaSociosPagadorContrato(id) {
      return this.$axios
        .get(`/empresa/${id}`)
        .then(response => {
          this.sociosPagador = response.data.socios;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel buscar os socios da empresa",
            text: error.response.data
          });
        });
    }
  },
  async created() {
    await this.getContrato();
    if (this.contrato.id) {
      await this.populaSociosPagadorContrato(this.contrato.pagador.id);
    }
  }
};
</script>
<style scoped>
.logo {
  width: 200px;
  height: 80px;
}
.logo img {
  width: 100%;
  height: auto;
}
.bg {
  background-color: #d3d3d3;
}
.text-box {
  text-align: initial;
}
.text-box-end {
  text-align: end;
}
.pi-times-circle {
  display: none !important;
}
.botoes {
  display: flex;
  justify-content: space-between;
}
.botao_recusar {
  margin-left: 150px;
}
@media (max-width: 480px) {
  .col,
  .col-5,
  .col-6 {
    flex: none;
    max-width: 25rem;
  }
  .botao_recusar {
    margin-left: 0px;
  }
}
</style>
