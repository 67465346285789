import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import FormCliente from "@/views/Cliente/FormCliente.vue";
import Cobrancas from "@/views/Cobrancas/Cobrancas.vue";
import Cobranca from "@/views/Cobrancas/Cobranca.vue";
import PagamentoCobranca from "@/views/Cobrancas/PagamentoCobranca.vue";
import DetalhesCobranca from "@/views/Cobrancas/DetalhesCobranca.vue";

const cobrancaRoutes: RouteConfig = {
    path: "/cobrancas",
    component: GenericComponent,
    children: [
        {
            path: "/",
            component: Cobrancas,
            name: "Cobrancas",
            meta: {
                breadCrumb: [
                    {
                        text: "Cobranças"
                    }
                ]
            }

        },
        {
            path: "add",
            component: Cobranca,
            name: "CriaCobranca",
            props: route => ({
                cliente: route.params.cliente,
                id: route.params.id
            }),
            meta: {
                breadCrumb: [
                    {
                        text: "Cobranças",
                        to: {
                            name: "Cobrancas"
                        }
                    },
                    {
                        text: "Nova cobrança"
                    }
                ]
            }
        },
        {
            path: ":id",
            component: DetalhesCobranca,
            name: "VerCobranca",
            props: route => ({
                id: route.params.id
            }),
            meta: {
                breadCrumb(route: Route) {
                    const id  = route.params.id
                return [
                    {
                        text: "Cobranças",
                        to: {
                            name: "Cobrancas"
                        }
                    },
                    {
                        text: id
                    }
                ];
            }
            }
        },
        {
            path: "novo-cliente",
            name: "CobrancaNovoCliente",
            component: FormCliente,
            props: route => ({
                mode: "create",
                backRoute: route.params.backRoute,
                idEmpresa: route.params.idEmpresa
            }),
            meta: {
                breadCrumb: [
                    {
                        text: "Nova cobrança",
                        to: {
                            name: "CriaCobranca"
                        }
                    },
                    {
                        text: "Novo Cliente"
                    }
                ]
            }

        },
        {
            path: "editar-cliente",
            name: "CobrancaEditarCliente",
            component: FormCliente,
            props: route => ({
                mode: "edit",
                idCliente: route.params.idCliente,
                backRoute: route.params.backRoute
            }),
            meta: {
                breadCrumb(route: Route) {
                    const idCliente = route.params.idCliente;

                    return [
                        {
                            text: "Nova cobrança",
                            to: { name: "CriaCobranca" }
                        },
                        {
                            text: idCliente
                        }
                    ];
                }
            }

        },
        {
            path: ":id/pagamento",
            component: PagamentoCobranca,
            name: "PagamentoCobranca",
            props: route => ({
                id: route.params.id
            }),
            meta: {
                public: true
            }
        },
    ]
}

export default cobrancaRoutes;
