<template>
  <v-container
    ><v-row>
      <v-col class="d-flex justify-center">
        <v-card
          width="420px"
          class="ma-4"
          hover
          shaped
          :to="{ name: 'Monitoramento' }"
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col cols="3">
<!--                <i class="report-icon fas fa-chart-bar"></i>-->
                <v-icon class="report-ico"> mdi-chart-bar </v-icon>
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="text-bold text-h6">Monitoramento</span>

                Total detalhado de movimentação diária de um intervalo
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          width="420px"
          class="ma-4"
          hover
          shaped
          :to="{ name: 'ExtratoRecebiveis' }"
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col cols="3">
<!--                <i class="report-icon fas fa-receipt"></i>-->
                <v-icon class="report-ico"> mdi-receipt </v-icon>
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="text-bold text-h6">Extrato Recebíveis</span>

                Valores de liquidação dos recebíveis por dia de liquidação
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Relatorios",
};
</script>

<style scoped>
.report-icon {
  font-size: 80px;
  color: #3498db;
}

@media (max-width: 900px) {
  .col,
  .col-3,
  .col-4 {
    flex: none;
    max-width: 25rem;
    flex-wrap: wrap;
  }
}
</style>
