import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import FormServico from "@/views/Servico/FormServico.vue";
import Servicos from "@/views/Servico/Servicos.vue";

const servicoRoutes: RouteConfig = {
  path: "/servicos",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Servicos",
      component: Servicos,

      meta: {
        breadCrumb: [
          {
            text: "Servicos"
          }
        ]
      }
    },
    {
      path: "/servicos/add",
      name: "addServico",
      component: FormServico,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Servicos",
              to: {
                name: "Servicos"
              }
            },
            {
              text: "Novo Serviço"
            }
          ];
        }
      }
    },
    {
      path: "/servicos/:idServico",
      name: "viewServico",
      component: FormServico,

      props: route => ({
        mode: "view",
        idServico: route.params.idServico
      }),
      meta: {
        breadCrumb(route: Route) {
          const idServico = route.params.idServico;

          return [
            {
              text: "Servicos",
              to: { name: "Servicos" }
            },
            {
              text: idServico
            }
          ];
        }
      }
    },

    {
      path: "/servicos/:idServico/edit",
      name: "editServico",
      component: FormServico,
      props: route => ({
        mode: "edit",
        idServico: route.params.idServico
      }),
      meta: {
        breadCrumb(route: Route) {
          const idServico = route.params.idServico;

          return [
            {
              text: "Servicos",
              to: { name: "Servicos" }
            },
            {
              text: idServico,

              to: {
                name: "viewServico",
                params: {
                  idServico: idServico
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};
export default servicoRoutes;
