<template>
  <v-dialog v-model="showDialog" dense persistent max-width="500px">
    <v-card>

      <v-toolbar flat dense>
          <v-btn icon  @click="close" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-toolbar>

      <v-card-title class="justify-center">Escolha a forma de pagamento</v-card-title>

      <v-card-text>

        <v-container>
          <v-row class="d-flex justify-center " dense>
            <v-col class="d-flex justify-center" cols="4">
              <Button label="Boleto" @click="boleto"
                      icon="pi pi-bars" class="p-button-outlined"/>
            </v-col>
            <v-col class="d-flex justify-center" cols="4">
              <Button label="Cartão" @click="cartao"
                      icon="pi pi-credit-card" class="p-button-outlined" />
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: "DialogFormaPagamento",
  components: {
    Button
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      token: null
    };
  },
  methods: {
    boleto() {
      this.$emit("onSelected", 'BOLETO');
    },
    cartao() {
      this.$emit("onSelected", 'CARTAO');
    },
    close(){
      this.$emit("onSelected", null);
    }
  }
};
</script>

<style scoped>
.svg-token {
  margin: auto auto;
  width: 300px;
  height: 200px;
}
</style>
