<template>
  <v-breadcrumbs class="breadCrumb" :items="breadCrumbs" v-if="breadCrumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        @click.prevent="item.goBack ? goBack(item.backHref) : null"
        class="text-subtitle-2 crumb-item"
        :disabled="item.disabled"
        exact
      >
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {
      items: {}
    };
  },

  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    }
  }
};
</script>

<style>
.v-breadcrumbs__item {
  color: #3c89fc !important;
  cursor: pointer;
}
.breadCrumb {
  display: inline-block;
  padding: 0 !important;
}
</style>
