<template>
  <v-dialog v-model="params.showDialog" max-width="600" persistent>
    <v-card>
      <v-card-title class="text-caption">{{ params.title }}</v-card-title>
      <v-card-text class="text-"
        ><v-alert
          v-if="params.type"
          :type="params.type"
          style="font-size:.875rem"
        >
          <span v-html="params.text"></span>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="closeDialogMessage">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    params: Object
  },

  methods: mapMutations(["closeDialogMessage"])
};
</script>

<style></style>
