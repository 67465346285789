<template>
  <v-container fluid>
    <TabView @tab-change="tabChange" :activeIndex="tab">

      <TabPanel header="Emitidos">
        <v-row>
            <div class="espacado">
              <MultiSelect
                v-model="filtros.status"
                :options="status"
                @change="getContratosEmitidos(true)"
                optionLabel="name"
                placeholder="Filtro por status"
                display="chip"
              />
            </div>
            <div class="espacado">
              <Chips
                ref="chipcomponenteEmitidos"
                v-model="filtros.pagador"
                separator=","
                @add="getContratosEmitidos(true)"
                @remove="getContratosEmitidos(true)"
                placeholder="Filtro por nome, telefone, email"
              />
            </div>
          <div class="espacado">
            <BotaoBuscarListas @buscar="buscarEmitidos" />
          </div>
            <v-col class="text-end">
              <v-tooltip bottom v-if="true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    dark
                    small
                    color="primary"
                    :to="{ name: 'addContrato' }"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Novo contrato</span>
              </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
                :loading="loadingSkeleton"
                type="table"
            >
              <v-data-table
                  dense
                  :fixed-header="true"
                  :headers="headersPagador"
                  :items="contratosEmitidos"
                  :loading="carregando"
                  :calculate-widths="true"
                  loading-text="Carregando dados"
                  :search="search"
                  :options="{ itemsPerPage: pagination.limit }"
                  hide-default-footer
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                      class="ma-2 text-center"
                      :color="STATUS_CONTRATO[item.status].cor"
                      style="justify-content: center; min-width: 100px"
                      label
                      text-color="white"
                  >
                    {{ STATUS_CONTRATO[item.status].texto }}
                  </v-chip>
                </template>

                <template v-slot:[`item.modalidade`]="{ item }">
                  {{ OPCOES_PAGTO[item.modalidade].texto }}
                </template>

                <template v-slot:[`item.data_inicio_contrato`]="{ item }">
                  {{
                    item.data_inicio_contrato
                        | formatDate({ dateStyle: "short" })
                  }}
                </template>
                <template v-slot:[`item.data_termino_contrato`]="{ item }">
                  {{
                    item.data_termino_contrato
                        | formatDate({ dateStyle: "short" })
                  }}
                </template>

                <template v-slot:[`item.valor_total_contrato`]="{ item }">
                  {{
                    item.valor_total_contrato
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.valor_parcela`]="{ item }">
                  R$
                  {{
                    item.valor_parcela
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>
                <template v-slot:[`item.total_pago`]="{ item }">
                  R$
                  {{
                    item.total_pago
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.saldo_devedor`]="{ item }">
                  R$
                  {{
                    item.saldo_devedor
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.acoes`]="{ item }">
                  <v-btn
                      icon
                      :to="{
                    name: 'viewContrato',
                    params: { idContrato: item.id.toString() }
                  }"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-skeleton-loader>
            <div class="text-end">
              <v-btn type="button" @click="exportarEmitidos" icon>
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </div>

            <v-pagination
              v-model="pagination.page"
              :length="totalPaginas"
              @input="next"
              :total-visible="10"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </TabPanel>

      <TabPanel header="Recebidos">
        <v-row>
          <div class="espacado">
            <MultiSelect
              v-model="filtros.status"
              :options="status"
              @change="getContratosRecebidos(true)"
              optionLabel="name"
              placeholder="Filtro por status"
              display="chip"
            />
          </div>
          <div class="espacado">
            <Chips
              ref="chipcomponenteRecebidos"
              v-model="filtros.emissor"
              separator=","
              @add="getContratosRecebidos(true)"
              @remove="getContratosRecebidos(true)"
              placeholder="Filtro por nome, telefone, email"
            />
          </div>
          <div class="espacado">
            <BotaoBuscarListas @buscar="buscarRecebidos" />
          </div>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
                :loading="loadingSkeleton"
                type="table"
            >
              <v-data-table
                  dense
                  :fixed-header="true"
                  :headers="headersEmissor"
                  :loading="carregando"
                  :calculate-widths="true"
                  :items="contratosRecebidos"
                  loading-text="Carregando dados"
                  :search="search"
                  :options="{ itemsPerPage: pagination.limit }"
                  hide-default-footer
              >
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                      class="ma-2 text-center"
                      :color="STATUS_CONTRATO[item.status].cor"
                      style="justify-content: center; min-width: 100px"
                      label
                      text-color="white"
                  >
                    {{ STATUS_CONTRATO[item.status].texto }}
                  </v-chip>
                </template>

                <template v-slot:[`item.modalidade`]="{ item }">
                  {{ OPCOES_PAGTO[item.modalidade].texto }}
                </template>

                <template v-slot:[`item.data_inicio_contrato`]="{ item }">
                  {{
                    item.data_inicio_contrato
                        | formatDate({ dateStyle: "short" })
                  }}
                </template>
                <template v-slot:[`item.data_termino_contrato`]="{ item }">
                  {{
                    item.data_termino_contrato
                        | formatDate({ dateStyle: "short" })
                  }}
                </template>

                <template v-slot:[`item.valor_total_contrato`]="{ item }">
                  {{
                    item.valor_total_contrato
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.valor_parcela`]="{ item }">
                  R$
                  {{
                    item.valor_parcela
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>
                <template v-slot:[`item.total_pago`]="{ item }">
                  R$
                  {{
                    item.total_pago
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.saldo_devedor`]="{ item }">
                  R$
                  {{
                    item.saldo_devedor
                        | formatMoney({ minimumFractionDigits: 2 })
                  }}
                </template>

                <template v-slot:[`item.acoes`]="{ item }">
                  <v-btn
                      icon
                      :to="{
                    name: 'viewContrato',
                    params: { idContrato: item.id.toString() }
                  }"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-skeleton-loader>
            <div class="text-end">
              <v-btn type="button" @click="exportarRecebidos" icon>
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </div>
            <v-pagination
              v-model="pagination.page"
              :length="totalPaginas"
              @input="nextRecebidos"
              :total-visible="10"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </TabPanel>

    </TabView>

  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  components: {
    MultiSelect,
    Chips,
    TabView,
    TabPanel,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      tab: this.$store.state.filtrosContratos.tab,
      contratosEmitidos: [],
      contratosRecebidos: [],
      carregando: false,
      search: null,
      headersPagador: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Contrato",
          align: "center",
          sortable: false,
          value: "numero"
        },
        {
          text: "Modalidade",
          align: "center",
          sortable: false,
          value: "modalidade"
        },
        {
          text: "Pagador",
          align: "center",
          sortable: false,
          value: "pagador.nome_fantasia"
        },
        {
          text: "Valor Total",
          align: "center",
          sortable: false,
          value: "valor_total_contrato"
        },
        {
          text: "Total parcelas",
          align: "center",
          sortable: false,
          value: "qtd_parcelas"
        },
        {
          text: "Parcela",
          align: "center",
          sortable: false,
          value: "valor_parcela"
        },
        {
          text: "Total Pago",
          align: "center",
          sortable: false,
          value: "total_pago"
        },
        {
          text: "Saldo devedor",
          align: "center",
          sortable: false,
          value: "saldo_devedor"
        },
        {
          text: "Inicio",
          align: "center",
          sortable: false,
          value: "data_inicio_contrato"
        },
        {
          text: "Termino",
          align: "center",
          sortable: false,
          value: "data_termino_contrato"
        },

        {
          text: "Ver",
          value: "acoes"
        }
      ],
      headersEmissor: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Contrato",
          align: "center",
          sortable: false,
          value: "numero"
        },
        {
          text: "Modalidade",
          align: "center",
          sortable: false,
          value: "modalidade"
        },
        {
          text: "Emissor",
          align: "center",
          sortable: false,
          value: "recebedor.nome_fantasia"
        },
        {
          text: "Valor Total",
          align: "center",
          sortable: false,
          value: "valor_total_contrato"
        },
        {
          text: "Total parcelas",
          align: "center",
          sortable: false,
          value: "qtd_parcelas"
        },
        {
          text: "Parcela",
          align: "center",
          sortable: false,
          value: "valor_parcela"
        },
        {
          text: "Total Pago",
          align: "center",
          sortable: false,
          value: "total_pago"
        },
        {
          text: "Saldo devedor",
          align: "center",
          sortable: false,
          value: "saldo_devedor"
        },
        {
          text: "Inicio",
          align: "center",
          sortable: false,
          value: "data_inicio_contrato"
        },
        {
          text: "Termino",
          align: "center",
          sortable: false,
          value: "data_termino_contrato"
        },

        {
          text: "Ver",
          value: "acoes"
        }
      ],
      filtros: {
        status: this.$store.state.filtrosContratos.status,
        pagador: this.$store.state.filtrosContratos.pagador,
        emissor: this.$store.state.filtrosContratos.emissor
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first: 0
      },
      status: [
        { name: "Ativo", code: "ATIVO" },
        { name: "Atrasado", code: "ATRASADO" },
        { name: "Cancelado", code: "CANCELADO" },
        { name: "Quitado", code: "QUITADO" },
        { name: "Aguardando aceite", code: "AGUARDANDO_ACEITE" }
      ]
    };
  },
  mounted() {
    if(this.tab === 0) {
      this.getContratosEmitidos();
    } else {
      this.getContratosRecebidos();
    }
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getContratosEmitidos(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosContratos", {
        status: this.filtros.status,
        pagador: this.filtros.pagador,
        emissor: this.filtros.emissor,
        tab: 0
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;
      this.$axios
        .get(`/contrato/empresa/${this.empresaAtual.id}/emitidos`, {
          headers: {
            filtros: JSON.stringify({
              pagador: this.filtros.pagador,
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.contratosEmitidos = response.data.data;
          this.pagination.total = response.data.count;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar contratos",
            text: error.response.data
          });
        });
    },
    getContratosRecebidos(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosContratos", {
        status: this.filtros.status,
        pagador: this.filtros.pagador,
        emissor: this.filtros.emissor,
        tab: 1
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/contrato/empresa/${this.empresaAtual.id}/recebidos`, {
          headers: {
            filtros: JSON.stringify({
              emissor: this.filtros.emissor,
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.contratosRecebidos = response.data.data;
          this.pagination.total = response.data.count;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar contratos",
            text: error.response.data
          });
        });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getContratosEmitidos();
    },
    nextRecebidos(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getContratosRecebidos();
    },
    exportarEmitidos() {
      this.$axios
        .get(`/contrato/empresa/${this.empresaAtual.id}/emitidos`, {
          headers: {
            filtros: JSON.stringify({
              pagador: this.filtros.pagador,
              status: this.filtros.status.map(m => m.code)
            })
          }
        })
        .then(response => {
          this.geraArquivo("contratos-emitidos.csv", response.data.data);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar contratos",
            text: error.response.data
          });
        });
    },
    exportarRecebidos() {
      this.$axios
        .get(`/contrato/empresa/${this.empresaAtual.id}/recebidos`, {
          headers: {
            filtros: JSON.stringify({
              emissor: this.filtros.emissor,
              status: this.filtros.status.map(m => m.code)
            })
          }
        })
        .then(response => {
          this.geraArquivo("contratos-recebidos.csv", response.data.data);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar contratos recebidos",
            text: error.response.data
          });
        });
    },
    tabChange(value){
      if(value.index == 0){
        this.getContratosEmitidos(true);
      }else if(value.index == 1){
        this.getContratosRecebidos(true);
      }
    },
    geraArquivo(nome,dados){
      const copy = dados.map(item => {
            const obj = {};
              obj.id = item.id;
              obj.modalidade = item.modalidade;
              obj.tipo = item.tipo;
              obj.status = item.status;
              obj.numero = item.numero;
              obj.qtd_parcelas = item.qtd_parcelas;
              obj.valor_total_contrato = item.valor_total_contrato;
              obj.valor_parcela = item.valor_parcela;
              obj.data_inicio_contrato = item.data_inicio_contrato;
              obj.data_termino_contrato = item.data_termino_contrato;
              obj.saldo_devedor = item.saldo_devedor;
              obj.total_pago = item.total_pago;

              obj.pagador_id = item.pagador.id;
              obj.pagador_cnpj = item.pagador.cnpj;
              obj.pagador_nome_fantasia = item.pagador.nome_fantasia;
              obj.pagador_razao_social = item.pagador.razao_social;

              obj.recebedor_id = item.recebedor.id;
              obj.recebedor_cnpj = item.recebedor.cnpj;
              obj.recebedor_nome_fantasia = item.recebedor.nome_fantasia;
              obj.recebedor_razao_social = item.recebedor.razao_social;

              obj.usuario_criacao_id = item.usuario_criacao?.id;
              obj.usuario_criacao_nome = item.usuario_criacao?.nome;

              obj.usuario_aceite_id = item.usuario_aceite?.id;
              obj.usuario_aceite_nome = item.usuario_aceite?.nome;

              obj.usuario_cancelamento_id = item.usuario_cancelamento?.id;
              obj.usuario_cancelamento_nome = item.usuario_cancelamento?.nome;

            return obj;
          });

          this.toCSVeDownload(nome, copy);
    },

    buscarEmitidos() {
      const chip = this.$refs.chipcomponenteEmitidos;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.pagador.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

    buscarRecebidos() {
      const chip = this.$refs.chipcomponenteRecebidos;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.emissor.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
  }
};
</script>

<style></style>
