import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Transacoes from "@/views/Transacao/Transacoes.vue";
import Transacao from "@/views/Transacao/Transacao.vue";

const transacaoRoutes: RouteConfig = {
  path: "/transacoes",
  component: GenericComponent,
  props: true,
  children: [
    {
      path: "/",
      name: "Transacoes",
      component: Transacoes,
      meta: {
        breadCrumb: [
          {
            text: "Transações"
          }
        ]
      }
    },

    {
      path: "/transacoes/:idTransacao",
      component: Transacao,
      props: true,
      name: "DetalheTransacao",
      meta: {
        breadCrumb: function(route: Route) {
          const idTransacao = route.params.idTransacao;
          const back = route.params.callback;

          switch(back){
            case "dashboard":

              return [ { text: "DashBoard", to: { name:"Dashboard" }},
                       { text: idTransacao } ];

            case "cobranca":

              return [ { text: "Cobranças", to: { name:"Cobrancas" }},
                       { text: idTransacao } ];
            default:

              return [ { text: "Transações", to: { name:"Transacoes" }},
                       { text: idTransacao } ];

          }
          
        }
      }
    }
  ]
};

export default transacaoRoutes;
