import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import VendasPdv from "@/views/VendasPdv/VendasPdv.vue";
import CriarVenda from "@/views/VendasPdv/CriarVenda.vue";

const vendasPdvRoutes: RouteConfig = {
  path: "/vendas-pdv",
  component: GenericComponent,
  children: [
    {
      path: "/",
      component: VendasPdv,
      name: "VendasPdv",
      meta: {
        breadCrumb: [
          {
            text: "Vendas PDV"
          }
        ]
      }
    },
    {
      path: "/vendas-pdv/add",
      name: "addVendasPdv",
      component: CriarVenda,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Vendas PDV",
              to: {
                name: "VendasPdv"
              }
            },
            {
              text: "Nova Venda"
            }
          ];
        }
      }
    },
    {
      path: "/vendas-pdv/:idVenda/edit",
      name: "editVenda",
      component: CriarVenda,
      props: route => ({
        mode: "edit",
        idVenda: route.params.idVenda
      }),
      meta: {
        breadCrumb(route: Route) {
          const idVenda = route.params.idVenda;

          return [
            {
              text: "Vendas PDV",
              to: { name: "VendasPdv" }
            },
            {
              text: idVenda
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    },
    {
      path: "/vendas-pdv/:idVenda",
      name: "viewVenda",
      component: CriarVenda,
      props: route => ({
        mode: "view",
        idVenda: route.params.idVenda
      }),
      meta: {
        breadCrumb(route: Route) {
          const idVenda = route.params.idVenda;

          return [
            {
              text: "Vendas PDV",
              to: { name: "VendasPdv" }
            },
            {
              text: idVenda
            }
          ];
        }
      }
    }
  ]
};

export default vendasPdvRoutes;
