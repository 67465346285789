<template>
  <div class="pa-2" :style="{ borderLeft: `2px solid ${color}` }">
    <v-row no-gutters>
      <v-col>
<!--        <i class="dash-icon" :class="icon" :style="{ color: color }"></i>-->
        <v-icon :color="color"> {{ icon }} </v-icon>
        <span
          class="font-italic  text-subtitle-1 text-start"
          :style="{ color: color }"
        >
          {{ titulo }}
        </span></v-col
      >
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center">
        <span class="text-caption">R$ </span>
        <span class="dash-number ">
          {{ tkm | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
        <p class="text-subtitle-2 pa-0">TKM / Dia</p>
        <span class="text-caption">Mês </span>
        <span class="text-caption ">
          R$ {{ mes | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="text-center">
        <span class="text-caption">R$ </span>
        <span class="dash-number ">
          {{ semana | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
        <p class="text-subtitle-2">Semana</p>
        <span class="text-caption">Ano </span>
        <span class="text-caption ">
          R$ {{ total | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      titulo: "Projeções",
      icon: "mdi-chart-line",
      color: "#1b907d",
      tkm: 0,
      semana: 0,
      mes: 0,
      total: 0
    };
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  mounted() {
    this.getProjecoes();
  },
  methods: {
    async getProjecoes() {
      if (!this.empresaAtual || !this.empresaAtual.id) return;

      const hoje = moment();

      const inicioSemana = moment().startOf("week");
      const fimSemana = moment().endOf("week");
      const diasSemanaRestante = fimSemana.diff(hoje, "days");

      const inicioMes = moment().startOf("month");
      const fimMes = moment().endOf("month");
      const diasMesRestante = fimMes.diff(hoje, "days");

      const inicioAno = moment().startOf("year");
      const fimAno = moment().endOf("year");
      const diasAnoRestante = fimAno.diff(hoje, "days");

      this.tkm = await this.$axios
        .get(`/relatorio/transacao/empresa/${this.empresaAtual.id}/tkm-dia`)
        .then(response => {
          return parseFloat(response.data.tkm);
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-vendas`,
          {
            params: {
              "data-inicio": inicioSemana.format("YYYY-MM-DD"),
              "data-fim": fimSemana.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          const total = parseFloat(response.data.vendas.totalLiquido);
          this.semana = total + this.tkm * diasSemanaRestante;
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-vendas`,
          {
            params: {
              "data-inicio": inicioMes.format("YYYY-MM-DD"),
              "data-fim": fimMes.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          const total = parseFloat(response.data.vendas.totalLiquido);
          this.mes = total + this.tkm * diasMesRestante;
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-vendas`,
          {
            params: {
              "data-inicio": inicioAno.format("YYYY-MM-DD"),
              "data-fim": fimAno.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          const total = parseFloat(response.data.vendas.totalLiquido);
          this.total = total + this.tkm * diasAnoRestante;
        });
    }
  }
};
</script>
<style>
.dash-number {
  font-size: 20px;
}
</style>
