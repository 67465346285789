import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Estandes from "@/views/Estande/Estandes.vue";
import FormEstande from "@/views/Estande/FormEstande.vue";
import FormEvento from "@/views/Evento/FormEvento.vue";

const estandeRoutes: RouteConfig = {
    path: "/estandes",
    component: GenericComponent,
    children: [
        {
            path: "/",
            component: Estandes,
            name: "Estandes",
            meta: {
                breadCrumb: [
                    {
                        text: "Estandes"
                    }
                ]
            }
        },
        {
            path: "/estandes/add",
            name: "addEstande",
            component: FormEstande,
            props: {
                mode: "create"
            },
            meta: {
                breadCrumb(route: Route) {
                    return [
                        {
                            text: "Estandes",
                            to: {
                                name: "Estandes"
                            }
                        },
                        {
                            text: "Novo Estande"
                        }
                    ];
                }
            }
        },
        {
            path: "/estandes/:idEstande",
            component: FormEstande,
            name: "viewEstande",
            props: route => ({
                mode: "view",
                idEstande: route.params.idEstande
            }),
            meta: {
                breadCrumb(route: Route) {
                    const idEstande = route.params.idEstande;

                    return [
                        {
                            text: "Estandes",
                            to: { name: "Estandes" }
                        },
                        {
                            text: idEstande
                        }
                    ];
                }
            }
        },
        {
            path: "/estandes/:idEstande/edit",
            name: "editEstande",
            component: FormEstande,
            props: route => ({
                mode: "edit",
                idEstande: route.params.idEstande
            }),
            meta: {
                breadCrumb(route: Route) {
                    const idEstande = route.params.idEstande;

                    return [
                        {
                            text: "Estandes",
                            to: { name: "Estandes" }
                        },
                        {
                            text: idEstande,

                            to: {
                                name: "viewEstande",
                                params: {
                                    idUsuario: idEstande
                                }
                            }
                        },
                        {
                            text: "Editar"
                        }
                    ];
                }
            }
        }
    ]
};

export default estandeRoutes;
