import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Remessas from "@/views/Remessa/Remessas.vue";

const remessaRoutes: RouteConfig = {
    path: "/remessas",
    component: GenericComponent,
    children: [
        {
            path: "/",
            component: Remessas,
            name: "Remessas",
            meta: {
                breadCrumb: [
                    {
                        text: "Remessas"
                    }
                ]
            }

        },
        
    ]
}

export default remessaRoutes;