import { RouteConfig } from "vue-router";


const authRoutes: RouteConfig = {
  path: "/login",
  name: "Login",
  component: () => import(/* webpackChunkName: "lazy-chunk" */ "@/views/Login.vue"),
  meta: {
    public: true
  }
};

export default authRoutes;
