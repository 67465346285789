<template>
  <v-dialog v-model="showDialog" dense persistent max-width="1200">
    <v-card>
      <form @submit.prevent="handleSave">
        <div class="d-flex">
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" class="mt-2 mr-2" icon @click="cancelar">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card outlined class="px-3 py-3 mt-n5">
            <v-row dense class="mx-auto mt-3">
              <v-col cols="12" sm="2">
                <v-combobox
                    label="Status"
                    v-model="cliente.status"
                    dense
                    :items="['ATIVO', 'INATIVO', 'BLOQUEADO']"
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    dense
                    :items="TIPO_PESSOA"
                    item-text="texto"
                    item-value="valor"
                    v-model="cliente.tipo"
                    :readonly="!isDocumentEditable"
                    @change="handleChangeTipoPessoa"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                    v-if="cliente.tipo === 'PESSOA_FISICA'"
                    required
                    dense
                    :readonly="!isDocumentEditable"
                    v-model="cliente.cpf"
                    @keyup="cliente.cpf = $masks.cpf(cliente.cpf)"
                    @blur="checkCpfCnpj(cliente.cpf, 'cpf')"
                    label="CPF"
                    :maxlength="14"
                    :error-messages="errors.cpf"
                >
                </v-text-field>
                <v-text-field
                    v-if="cliente.tipo === 'PESSOA_JURIDICA'"
                    :readonly="!isDocumentEditable"
                    required
                    dense
                    v-model="cliente.cnpj"
                    @keyup="cliente.cnpj = $masks.cnpj(cliente.cnpj)"
                    label="CNPJ"
                    @blur="checkCpfCnpj(cliente.cnpj, 'cnpj')"
                    :maxlength="18"
                    :error-messages="errors.cnpj"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-switch
                    :disabled="mode === 'view'"
                    v-model="cliente.pep"
                    label="PEP ou Orgão Publico"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row dense v-if="cliente.tipo === 'PESSOA_JURIDICA'">
              <v-col cols="4">
                <v-text-field
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.inscricao_municipal"
                    label="Inscrição Municipal"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.inscricao_estadual"
                    label="Inscrição Estadual"
                />
              </v-col>
            </v-row>
            <v-row dense v-if="cliente.tipo === 'PESSOA_JURIDICA'">
              <v-col>
                <v-text-field
                    required
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.razao_social"
                    label="Razão Social"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    required
                    :readonly="mode == 'view'"
                    dense
                    v-model="cliente.nome"
                    label="Nome"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.email"
                    label="Email"
                    type="email"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    required
                    dense
                    v-on:input="
                  cliente.telefone_celular = $masks.telefone(
                    cliente.telefone_celular
                  )
                "
                    :readonly="mode == 'view'"
                    v-model="cliente.telefone_celular"
                    label="Telefone Celular"
                    :maxLength="13"
                >
                </v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                    dense
                    v-on:input="
                  cliente.telefone_fixo = $masks.telefone(cliente.telefone_fixo)
                "
                    :readonly="mode == 'view'"
                    v-model="cliente.telefone_fixo"
                    label="Telefone Fixo"
                    :maxLength="13"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="2">
                <v-text-field
                    dense
                    @keyup="cliente.cep = $masks.cep(cliente.cep)"
                    :readonly="mode == 'view'"
                    v-model="cliente.cep"
                    @blur="consultaCEP"
                    label="Cep"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.endereco"
                    label="Endereço"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                    id="numero"
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.numero"
                    label="Número"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.bairro"
                    label="Bairro"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.complemento"
                    label="Complemento"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.cidade"
                    label="Cidade"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.uf"
                    label="UF"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="cliente.codigo_ibge"
                    label="COD. IBGE"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-textarea
                  outlined
                  counter="255"
                  :readonly="mode == 'view'"
                  label="Observações do cliente"
                  v-model="cliente.observacoes"
              >
              </v-textarea>
            </v-row>

          </v-card>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="btConfirma" text type="submit" v-if="mode !== 'view'" > Salvar </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";

function initialState() {
  return {
    tipo_cliente: "NAO_INFORMADO",
    cliente: {
      status: "ATIVO",
      tipo: "PESSOA_FISICA",
      telefone_fixo: "",
      telefone_celular: ""
    },
    errors: {
      cpf: null,
      cnpj: null,
    },
  };
}

export default {
  props: {
    showDialog: Boolean,
    id: String,
    idEmpresa: String,
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    title() {
      let title;
      switch (this.mode) {
        case "create":
          title = "Adicionar Cliente";
          break;

        case "edit":
          title = "Editar Cliente";
          break;
        case "view":
          title = "Consultar Cliente";
          break;
      }
      return title;
    },
    isDocumentEditable(){
      return this.mode === "create" || (this.mode === "edit" && this.tipo_cliente === 'NAO_INFORMADO')
    }
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showDialog(newValue, oldValue) {
      if (newValue && this.mode !== "create") this.getCliente();
    },
  },
  data() {
    return initialState();
  },
  mounted() {
    if (this.idEmpresa) {
      this.cliente.empresa_id = this.idEmpresa;
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    async handleSave() {
      if(Object.values(this.errors).every(value => value === null)) {
        this.mode === "create" ? await this.save() : await this.edit();
      }
    },
    async save() {
      delete this.cliente.documento;
      delete this.cliente.primeiroNome;
      delete this.cliente.ultimoNome;

      await this.$axios
          .post("/cliente/", this.cliente)
          .then(response => {
            this.$toast.add({
              severity: "success",
              summary: `Cliente cadastrado com sucesso.`,
              life: 2000
            });
            this.$emit("salva-dados", response.data);
            this.resetDados();
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Salvar Cliente",
              text: error.response.data
            });
          });
    },
    async edit() {
      delete this.cliente.documento;
      delete this.cliente.primeiroNome;
      delete this.cliente.ultimoNome;
      this.cliente.id = this.id;
      await this.$axios
          .put("/cliente/", this.cliente)
          .then(response => {
            this.$toast.add({
              severity: "success",
              summary: `Cliente atualizado com sucesso.`,
              life: 2000
            });
            this.$emit("salva-dados", response.data);
            this.resetDados();
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Atualizar Cliente",
              text: error.response.data
            });
          });
    },

    handleChangeTipoPessoa() {
      this.cliente.cpf = null;
      this.cliente.cnpj = null;
      this.errors.cpf = null;
      this.errors.cnpj = null;
    },

    checkCpfCnpj(value, tipo) {
      if(value && this.mode === "create") {

        this.errors.cpf = null;
        this.errors.cnpj = null;

        if(tipo === 'cpf' && value.length !== 14) {
          this.errors.cpf = "CPF inválido!";
          return;
        }

        if(tipo === 'cnpj' && value.length !== 18) {
          this.errors.cnpj = "CNPJ inválido!";
          return;
        }

        this.$axios
            .get(`/cliente/empresa/${this.empresaAtual.id}/listar`, {
              headers: {
                filtros: JSON.stringify({
                  cliente: [value]
                })
              },
            })
            .then(response => {
              if(response.data.data.length > 0) {

                if(tipo === 'cpf') {
                  this.errors.cpf = "Já existe cliente com esse CPF!";
                }

                if(tipo === 'cnpj') {
                  this.errors.cnpj = "Já existe cliente com esse CNPJ!";
                }

              }

            })
            .catch(error => {
              this.openDialogMessage({
                type: "error",
                title: "Erro - Consultar Clientes",
                text: error.response.data
              });
            });

      }
    },
    consultaCEP() {
      if (!this.cliente.cep) return;
      this.getCEP(this.cliente.cep)
          .then(response => {
            this.cliente = Object.assign({}, this.cliente, response);
            const number = document.getElementById('numero');
            number.focus();
          })
          .catch(() => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Consultar CEP",
              text: "CEP não encontrado."
            });
          });
    },
    getCliente() {
      this.$axios
          .get(`/cliente/${this.id}`)
          .then(response => {
            this.cliente = response.data;
            this.tipo_cliente = this.cliente.tipo;

            if (this.cliente.cpf) {
              this.cliente.cpf = this.$masks.cpf(this.cliente.cpf);
            }
            if (this.cliente.cnpj) {
              this.cliente.cnpj = this.$masks.cnpj(this.cliente.cnpj);
            }

            if (this.cliente.telefone_fixo) {
              this.cliente.telefone_fixo = this.$masks.telefone(
                  this.cliente.telefone_fixo
              );
            }
            if (this.cliente.telefone_celular) {
              this.cliente.telefone_celular = this.$masks.telefone(
                  this.cliente.telefone_celular
              );
            }
            if (this.cliente.cep) {
              this.cliente.cep = this.$masks.cep(this.cliente.cep);
            }

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Consultar Cliente",
              text: error.response.data
            });
          });
    },

    cancelar() {
      this.resetDados();
      this.$emit("cancela-dialog");
    },

    resetDados() {
      Object.assign(this.$data, initialState());
    },
  }
};
</script>
