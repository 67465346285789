<template>
  <v-container>
    <form @submit.prevent="alterarSenha">
      <v-row>
        <v-col>
          <v-text-field
            label="Nova Senha"
            v-model="senha"
            type="password"
            prepend-icon="mdi-key"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-key"
            label="Confirme a senha"
            type="password"
            required
            v-model="confirmSenha"
          >
            <span slot="append" v-if="confirmSenha">
              <v-tooltip bottom v-if="checkPassword" color="green">
                <template v-slot:activator="{ on, attrs }">
<!--                  <i-->
<!--                    v-on="on"-->
<!--                    v-bind="attrs"-->
<!--                    class="fas fa-check green&#45;&#45;text"-->
<!--                  ></i>-->
                   <v-icon v-on="on" v-bind="attrs" class="green--text"> mdi-check </v-icon>
                </template>
                <span>Senhas conferem</span>
              </v-tooltip>
              <v-tooltip bottom v-else color="red">
                <template v-slot:activator="{ on, attrs }">
<!--                  <i-->
<!--                    v-on="on"-->
<!--                    v-bind="attrs"-->
<!--                    class="fas fa-times red&#45;&#45;text"-->
<!--                  ></i>-->
                  <v-icon v-on="on" v-bind="attrs" class="red--text"> mdi-close </v-icon>
                </template>
                <span>Senhas não conferem</span>
              </v-tooltip>
            </span>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="7">
          <ul>
            <transition-group name="fade" tag="div">
              <li
                v-for="rule of analyzePassword"
                :key="rule.description"
                class="text-caption red--text"
              >
                {{ rule.description }}
              </li>
            </transition-group>
          </ul>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-end">
          <v-btn
            color="primary"
            type="submit"
            :disabled="!checkPassword || analyzePassword.length > 0"
          >
            Alterar
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    usuario: Object
  },
  data() {
    return {
      senha: null,
      confirmSenha: null,
      rules: [
        {
          description: "A senha deve possuir pelo menos uma letra Maiúscula",
          regex: /[A-Z]/
        },
        {
          description: "A senha deve possuir tamanho entre 8 a 12 digitos",
          regex: /^.{8,12}$/
        },
        {
          description: "A senha deve possuir pelo menos um caracter especial",
          regex: /[^A-Za-z0-9]/
        }
      ]
    };
  },
  computed: {
    checkPassword() {
      if (!this.senha) return false;

      return this.senha === this.confirmSenha ? true : false;
    },
    analyzePassword() {
      return this.rules.filter(rule => {
        return !rule.regex.test(this.senha);
      });
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    alterarSenha() {
      const data = {
        senha: this.senha
      };
      this.$axios
        .put(`/usuario/${this.usuario.id}/alterar-senha`, data)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Alterar Senha",
            text: "Sua senha foi alterada com sucesso."
          });
          this.$store.commit("logout");
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Alterar Senha",
            text: error.response.data
          });
        });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
