import { Route, RouteConfig } from "vue-router";
import Planos from "@/views/Plano/Planos.vue";
import FormPlano from "@/views/Plano/FormPlano.vue";
import GenericComponent from "@/components/GenericComponent.vue";

const planoRoutes: RouteConfig = {
  path: "/planos",
  component: GenericComponent,
  children: [
    {
      path: "/",
      name: "Planos",
      component: Planos,
      meta: {
        breadCrumb: [
          {
            text: "Planos"
          }
        ]
      }
    },
    {
      path: "/planos/add",
      name: "addPlano",
      component: FormPlano,
      props: {
        mode: "create"
      },
      meta: {

        breadCrumb() {
          return [
            {
              text: "Planos",
              to: {
                name: "Planos"
              }
            },
            {
              text: "Novo Plano"
            }
          ];
        }
      }
    },
    {
      path: "/planos/:idPlano",
      component: FormPlano,
      name: "viewPlano",
      props: route => ({
        mode: "view",
        idPlano: route.params.idPlano
      }),
      meta: {
        breadCrumb(route: Route) {
          const idPlano = route.params.idPlano;

          return [
            {
              text: "Planos",
              to: { name: "Planos" }
            },
            {
              text: idPlano
            }
          ];
        }
      }
    },
    {
      path: "/planos/:idPlano/edit",
      name: "editPlano",
      component: FormPlano,
      props: route => ({
        mode: "edit",
        idPlano: route.params.idPlano
      }),
      meta: {
        breadCrumb(route: Route) {
          const idPlano = route.params.idPlano;

          return [
            {
              text: "Planos",
              to: { name: "Planos" }
            },
            {
              text: idPlano,

              to: {
                name: "viewPlano",
                params: {
                  idUsuario: idPlano
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};

export default planoRoutes;
