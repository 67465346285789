<template>
  <v-container fluid>

    <div v-if="loadingSkeleton">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col>
              <v-skeleton-loader
                  :loading="true"
                  type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-skeleton-loader
                  :loading="true"
                  type="image"
              ></v-skeleton-loader>
            </v-col>
            <v-col>
              <v-skeleton-loader
                  :loading="true"
                  type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-skeleton-loader
                  height="80"
                  :loading="true"
                  type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
              height="128"
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="3">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
              height="128"
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="6">
          <v-skeleton-loader
              :loading="true"
              type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <div class="mt-n4" v-else>
      <v-row class="no-gutters">
        <v-col cols="6">
          <v-row class="no-gutters">
            <v-col>
              <v-card elevation="0" outlined class="py-3 billing-container">
                <span class="billing-title text-caption">Cobrança #{{ cobranca.numero }}</span>
                <v-row class="no-gutters mb-n5">
                  <v-col cols="12" lg="4">
                    <v-row>
                    <v-col>
                      <v-chip :color="STATUS_COBRANCA[cobranca.status].cor" class="white--text mr-3" v-if="cobranca.status">
                        {{ STATUS_COBRANCA[cobranca.status].texto }}
                      </v-chip>
                    </v-col>
                  </v-row>
                    <v-row>
                      <v-col cols="12" lg="12">
                        <v-text-field
                            label="Data emissão"
                            v-model="cobranca.data_criacao"
                            type="date"
                            dense
                            outlined
                            disabled
                        />
                      </v-col>
                      <v-col class="mt-n10" cols="12" lg="12">
                        <v-text-field
                            label="Validade"
                            v-model="cobranca.data_limite_pagamento"
                            type="date"
                            dense
                            outlined
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="8">
                    <v-row class="no-gutters">
                      <v-col class="d-flex justify-end coluna-botoes-cobranca">
                        <Button
                            icon="pi pi-times"
                            label="Cancelar"
                            v-if="
                              cobranca.status != 'CANCELADO' &&
                                cobranca.status != 'QUITADO' &&
                                user.permissoes.cancelar_cobranca
                            "
                            @click="openModalCancelaCobranca"
                            class="p-button-outlined p-button-danger btn-cancelar"
                        />

                        <Button
                            icon="pi pi-copy"
                            label="Copy link"
                            @click="copyToClipBoard"
                            class="p-button-outlined btn-copy-link"
                        />

                        <Button
                            icon="pi pi-search"
                            label="Ver"
                            @click="openUrl(link)"
                            class="p-button-outlined p-button-secondary btn-ver"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="no-gutters mt-n3 ml-1">
                      <v-col>
                        <v-card style="min-height: 90px; width: 98%" elevation="0" outlined class="billing-container">
                          <span class="billing-title text-caption">Observações</span>
                          <p>{{ cobranca.observacoes }}</p>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="cobranca.itens && cobranca.itens.length" class="no-gutters mt-n7">
            <v-col>
              <v-card elevation="0" outlined class="py-3 billing-container">
                <span class="billing-title text-caption">Itens</span>
                <v-row class="mx-auto">
                  <v-col>
                    <DataTable
                        :value="cobranca.itens"
                        responsiveLayout="stack"
                        breakpoint="960px"
                        scrollHeight="flex-row"
                    >
                      <Column field="quantidade" header="QTD" />
                      <Column header="Item">
                        <template #body="slot">
                          <div v-if="slot.data.servico">
                            <p>{{ slot.data.servico.sku }} - {{ truncateNomeItem(slot.data.servico.nome) }}</p>
                            <p class="text-caption">{{ truncateNomeItem(slot.data.servico.descricao) }}</p>
                          </div>
                          <div v-if="slot.data.produto">
                            <p>{{ slot.data.produto.codigo_barras }} - {{ truncateNomeItem(slot.data.produto.nome) }}</p>
                            <p class="text-caption">{{ truncateNomeItem(slot.data.produto.descricao) }}</p>
                          </div>
                        </template>
                      </Column>
                      <Column header="Valor">
                        <template #body="slot">
                          {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                        </template>
                      </Column>
                      <Column header="Total">
                        <template #body="slot">
                          {{
                            (slot.data.quantidade * slot.data.valor)
                                | formatMoney({ minimumFractionDigits: 2 })
                          }}
                        </template>
                      </Column>
                    </DataTable>

                    <div
                        style="border-top: 1px solid gray"
                        class="text-end mt-4"
                    >
                                          <span class="text-caption green--text" style="display: inline-block;"
                                          >R$</span
                                          >
                      <span class="text-h3 green--text">{{
                          totalItens | formatMoney({ minimumFractionDigits: 2 })
                        }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="cobranca.transacoes && cobranca.transacoes.length" class="no-gutters mt-n7">
            <v-col>
              <v-card elevation="0" outlined class="py-3 billing-container">
                <span class="billing-title text-caption">Transações</span>
                <v-row class="mx-auto">
                  <v-col>
                    <DataTable
                        :value="cobranca.transacoes"
                        responsiveLayout="stack"
                        breakpoint="1500px"
                        scrollHeight="flex-row"
                    >
                      <Column field="id" header="NSU"></Column>
                      <Column field="status" header="Status">
                        <template #body="slot">
                          <v-chip
                              :color="statusStyle(slot.data.status)"
                              text-color="white"
                          >
                            {{ slot.data.status.toLowerCase() }}
                          </v-chip>
                        </template>
                      </Column>
                      <Column field="tipo" header="Tipo">
                        <template #body="slot">
                          {{ TIPO_TRANSACAO[slot.data.tipo] }}
                        </template>
                      </Column>
                      <Column field="vencimento_boleto" header="Vencimento">
                        <template #body="slot">
                          {{
                            slot.data.vencimento_boleto
                                | formatDate({ dateStyle: "short" })
                          }}
                        </template>
                      </Column>
                      <Column header="Total">
                        <template #body="slot">
                          {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                        </template>
                      </Column>
                      <Column field="nota_fiscal" header="NFS-e">
                        <template #body="slot">
                          <Button
                              icon="pi pi-file"
                              v-if="slot.data.nota_fiscal"
                              @click="verNota(slot.data.nota_fiscal)"
                              class="p-button-rounded p-button-outlined p-button-secondary"
                          />
                        </template>
                      </Column>
                      <Column header="Enviar e-mail">
                        <template #body="slot">
                          <Button
                              icon="pi pi-send"
                              @click="enviarEmail(slot.data)"
                              class="p-button-rounded p-button-outlined p-button-secondary"
                          />
                        </template>
                      </Column>
                      <Column header="Ver">
                        <template #body="slot">
                          <Button
                              icon="pi pi-search"
                              @click="abrirTransacao(slot.data.id)"
                              class="p-button-rounded p-button-outlined"
                          />
                        </template>
                      </Column>
                    </DataTable>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row class="no-gutters">
            <v-col v-if="cobranca.empresa" cols="6" class="col-emissor">
              <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                <span class="billing-title text-caption">Emissor</span>
                <v-row dense>
                  <v-col>
                    <v-card-title class="text-caption"><b>{{ cobranca.empresa.nome_fantasia }}</b></v-card-title>
                    <v-card-subtitle class="text-caption">
                      <span>{{ cobranca.empresa.razao_social }}</span><br>
                      <span>{{ $masks.cnpj(cobranca.empresa.cnpj) }}</span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col v-if="cobranca.cliente" cols="6" class="col-pagador">
              <v-card elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
                <span class="billing-title text-caption">Pagador</span>
                <v-row dense>
                  <v-col>
                    <v-card-title class="text-caption"><b>{{ cobranca.cliente.nome }}</b></v-card-title>
                    <v-card-subtitle class="text-caption">
                      <span>{{ $masks.cpf(cobranca.cliente.cpf) }} | {{ cobranca.cliente.email }} | {{ $masks.telefone(cobranca.cliente.telefone_celular) }}</span><br>
                      <span class="text-caption" v-if="cobranca.cliente.cep">
                                  {{ cobranca.cliente.endereco }},
                                  {{ cobranca.cliente.numero }} -
                                  {{ cobranca.cliente.cidade }} ,
                                  {{ cobranca.cliente.uf }}
                                </span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="no-gutters mt-n7">
            <v-col>
              <v-card elevation="0" outlined class="billing-container">
                <span class="billing-title text-caption">Formas de Pagamento</span>
                <v-row dense>
                  <v-col cols="4" v-if="ativaPagamentoCom('BOLETO')">
                    <div class="border-forma-pagamento">
                      <v-card elevation="0" :disabled="cobranca.forma_pagamento && cobranca.forma_pagamento !== 'BOLETO'">
                        <v-card-text>
                          <i class="fa fa-barcode"></i>
                          <span class="titulo"> Boleto </span>
                          <v-chip color="green" small class="white--text ml-3 mb-2" v-if="cobranca.forma_pagamento && cobranca.forma_pagamento === 'BOLETO'">
                            Ativo
                          </v-chip>

                          <v-row dense>
                            <v-col>
                              <div style="height: 60px">
                              <span class="text-caption">
                                  Vencimento:
                                  {{
                                  cobranca.data_vencimento_boleto
                                      | formatDate({ dateStyle: "short" })
                                }}
                                </span>
                                <span class="text-caption">
                                  Max Parcelas:
                                  {{ cobranca.max_parcelas_boleto }}
                                </span>
                                <span class="text-caption">
                                  Multa: {{ cobranca.multa_atraso_boleto }}%
                                  Juros: {{ cobranca.juros_atraso_boleto }}%
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row v-if="mostrarBotaoPagamento">
                            <v-col class="center">
                              <v-btn
                                  small
                                  color="green"
                                  width="100%"
                                  max-width="20rem"
                                  class="white--text"
                                  @click="pagarComBoleto"
                              >
                                GERAR BOLETOS
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="4" v-if="ativaPagamentoCom('CARTAO')">
                    <div
                        class="border-forma-pagamento"
                    >
                      <v-card elevation="0" :disabled="cobranca.forma_pagamento && cobranca.forma_pagamento !== 'CARTAO'">
                        <v-card-text>
                          <i class="pi pi-credit-card"></i>
                          <span class="titulo"> Crédito </span>
                          <v-chip color="green" small class="white--text ml-3 mb-2" v-if="cobranca.forma_pagamento && cobranca.forma_pagamento === 'CARTAO'">
                            Ativo
                          </v-chip>
                          <v-row dense>
                            <v-col>
                              <div style="height: 60px;">
                              <span class="text-caption">
                                  Max Parcelas :
                                  {{ cobranca.max_parcelas_cartao }}
                                </span>
                                <v-row dense>
                                  <img
                                      :height="20"
                                      :src="require(`@/assets/img/VISA.jpeg`)"
                                  />
                                  <img
                                      :height="20"
                                      :src="require(`@/assets/img/MASTERCARD.jpeg`)"
                                  />
                                  <img
                                      :height="20"
                                      :src="require(`@/assets/img/ELO.jpeg`)"
                                  />
                                  <img
                                      :height="20"
                                      :src="
                                  require(`@/assets/img/American_Express.jpeg`)
                                "
                                  />
                                </v-row>
                              </div>

                            </v-col>
                          </v-row>

                          <v-row v-if="mostrarBotaoPagamento">
                            <v-col class="center">
                              <v-btn
                                  small
                                  color="green"
                                  width="100%"
                                  max-width="20rem"
                                  class="white--text"
                                  @click="openUrl(link)"
                              >
                                GERAR LINK
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="4" v-if="ativaPagamentoCom('PIX')">
                    <div>
                      <v-card elevation="0" :disabled="cobranca.forma_pagamento && cobranca.forma_pagamento !== 'PIX'">
                        <v-card-text>
                          <i class="fa fa-qrcode"></i>
                          <span class="titulo"> PIX </span>
                          <v-chip color="green" small class="white--text ml-3 mb-2" v-if="cobranca.forma_pagamento && cobranca.forma_pagamento === 'PIX'">
                            Ativo
                          </v-chip>
                          <v-row dense>
                            <v-col>
                              <div style="height: 60px">
                              <span class="text-caption">
                                  A validade do QR Code gerado será até as 23h
                                  do mesmo dia.
                                </span>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row v-if="mostrarBotaoPagamento">
                            <v-col class="center">
                              <v-btn
                                  small
                                  color="green"
                                  width="100%"
                                  max-width="20rem"
                                  class="white--text"
                                  @click="escolhaPix"
                              >
                                GERAR QR CODE
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>

              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="cobranca.splits && cobranca.splits.length" class="no-gutters mt-n7">
            <v-col>
              <v-card elevation="0" outlined class="py-3 billing-container">
                <span class="billing-title text-caption">Recebedores</span>
                <v-row>
                  <v-col class="text-center">
                    <div>
                      <div
                          style="width: 250px"
                          class="green white--text rounded-lg pa-3"
                      >
                  <span style="font-size: 2rem">
                    R$
                    {{ cobranca.valor_total | formatMoney({ minimumFractionDigits: 2 }) }}
                  </span>
                      </div>

                      <v-timeline dense>
                        <v-timeline-item
                            color="green"
                            v-for="split in cobranca.splits"
                            :key="split.recebedor.id"
                        >
                          <template v-slot:icon></template>
                          <v-card>
                            <v-card-text>
                              <v-row>
                                <v-col class="text-center d-flex flex-column">
                            <span class="font-weight-bold text-caption">
                              {{ split.recebedor.nome_fantasia }}
                            </span>
                                  <span class="text-caption font-weight-bold">
                              {{ split.recebedor.razao_social }}
                            </span>
                                  <span class="text-caption font-weight-bold">
                              {{ $masks.cnpj(split.recebedor.cnpj) }}
                            </span>
                                </v-col>
                              </v-row>


                              <v-row dense>
                                <v-col class="col-3 text-start">
                                  <span class="text-caption">Paga Taxa Transação </span>
                                </v-col>
                                <v-col class="text-start">
                                  <span class="font-weight-bold text-caption">{{ split.paga_taxa_transacao | formatBoolean }}</span>
                                </v-col>
                                <v-col class="col-3 text-start">
                                  <span class="text-caption">Paga Resto Divisão </span>
                                </v-col>
                                <v-col class="text-start">
                                  <span class="font-weight-bold text-caption">{{ split.paga_resto_divisao_taxa | formatBoolean }}</span>
                                </v-col>
                                <v-col class="text-start">
                                  <span class="text-caption">Valor </span>
                                </v-col>
                                <v-col class="text-start">
                            <span class="font-weight-bold text-caption">
                              {{ split.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                              {{ split.metodo === "PERCENTUAL" ? "%" : null }}
                            </span>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-timeline-item>
                      </v-timeline>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <DialogGeraBoleto
        :cobranca="cobranca"
        v-if="showPagamentoBoleto"
        :showDialog="showPagamentoBoleto"
        :cancela="() => (showPagamentoBoleto = false)"
        @concluido="boletoGerado"
    />

    <DialogNotaFiscal
        :nota="nota"
        v-if="showNota"
        :showDialog="showNota"
        :close="() => (showNota = false)"
    />

    <DialogDadosClientePagamento
        v-if="showDialogDadosCliente"
        :show="showDialogDadosCliente"
        titulo="Dados para Gerar do Qr Code"
        :cliente="cobranca?.cliente"
        @dadosCliente="clienteInformado"
        @fechar-dialog="(showDialogDadosCliente = false)"
    />


    <v-snackbar v-model="snackbar" top right>
      Link copiado para a área de transferência
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DialogGeraBoleto from "@/components/Dialogs/DialogGeraBoleto.vue";
import DialogNotaFiscal from "@/components/Dialogs/DialogNotaFiscal.vue";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import DialogDadosClientePagamento from "@/components/Dialogs/DialogDadosClientePagamento.vue";

export default {
  name: "DetalhesCobranca",
  props: {
    cliente: Object,
    id: String
  },
  components: {
    Button, DataTable, Column,
    DialogGeraBoleto,
    DialogNotaFiscal,
    DialogDadosClientePagamento
  },
  data() {
    return {
      loadingSkeleton: true,
      cobranca: {},
      nota: {},
      showNota: false,
      snackbar: false,
      showPagamentoBoleto: false,
      showDialogDadosCliente: false,
    };
  },
  async created() {
    if (this.id) {
      await this.getCobrancaById(this.id);
      return;
    }

  },
  computed: {
    ...mapState(["user", "empresaAtual"]),
    link() {
      return `https://${window.location.host}/cobrancas/${this.cobranca.id}/pagamento`;
    },
    totalItens() {
      if (!this.cobranca.itens) return 0;

      const total = this.cobranca.itens.reduce(
          (prev, curr) => (prev += curr.quantidade * curr.valor),
          0
      );
      return total.toFixed(2);
    },

    mostrarBotaoPagamento() {

      if (this.cobranca.forma_pagamento) {
        return false;
      }

      return !(this.cobranca.status === "CANCELADO" || this.cobranca.status === "QUITADO");

    }

  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),

    truncateNomeItem(nome) {
      if (nome.length > 100) {
        return nome.substring(0, 100) + '...';
      } else {
        return nome;
      }
    },

    clienteInformado(cliente) {

      if(cliente) {
        this.showDialogDadosCliente = false;
        this.cobranca.cliente = cliente;

        this.openDialogConfirm({
          title: "Pagar com PIX",
          text: `Confirma a escolha por pagar com PIX ? A forma de pagamento não poderá ser alterada.`,
          confirma: this.pagamentoPix,
          cancela: this.closeDialogConfirm
        });

      }
    },

    async getCobrancaById(id) {
      this.$axios
          .get(`/cobranca/${id}`)
          .then(response => {
            this.cobranca = response.data;
            this.loadingSkeleton = false;
            this.cobranca.data_criacao = this.cobranca.data_criacao.substring(0, 10);
            this.cobranca.data_limite_pagamento = this.cobranca.data_limite_pagamento.substring(0, 10);
          })
          .catch(() => {
            this.$router.push({ name: "TemaNaoConfigurado" });
          });
    },
    boletoGerado() {
      this.showPagamentoBoleto = false;
      this.getCobrancaById(this.cobranca.id);
    },
    pagarComBoleto() {
      if (this.cobranca.status != "ATIVO") return;
      this.showPagamentoBoleto = true;
    },

    async pagamentoPix() {
      this.closeDialogConfirm();

      const data = {
        forma_pagamento: "PIX",
        parcelas: 1,
        cliente: JSON.stringify(this.cobranca.cliente)
      };

      await this.$axios
          .post(`cobranca/${this.cobranca.id}/pagar`, data)
          .then(response => {
            this.cobranca = response.data;
          })
          .catch(error => {
            if(error.response.data.falha){
              this.openDialogMessage({
                type: "error",
                title: `Pagamento ${error.response.data.status}`,
                text: `${error.response.data.id}: ${error.response.data.falha}`,
              })
            }else{
              this.openDialogMessage({
                type: "error",
                title: `Falha ao gerar o QR PIX`,
                text: `${error.response.data}`,
              })
            }

          });
    },
    escolhaPix() {

      if(this.cobranca.cliente) {
        this.openDialogConfirm({
          title: "Pagar com PIX",
          text: `Confirma a escolha por pagar com PIX ? A forma de pagamento não poderá ser alterada.`,
          confirma: this.pagamentoPix,
          cancela: this.closeDialogConfirm
        });
      } else {
        this.showDialogDadosCliente = true;
      }

    },



    copyToClipBoard() {
      navigator.clipboard.writeText(this.link);
      this.snackbar = true;
    },
    ativaPagamentoCom(metodo) {
      if (!this.cobranca.id) return false;

      return this.cobranca.opcoes_pagamento.filter(forma => forma == metodo)
          .length;
    },
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir o boleto");
      }
    },
    openModalCancelaCobranca() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Ao cancelar a cobrança todos os boletos pendentes serão cancelados, deseja realmente cancelar?`,
        confirma: this.cancelarCobranca,
        cancela: this.closeDialogConfirm
      });
    },
    cancelarCobranca() {
      this.closeDialogConfirm();

      this.$axios
          .post(`/cobranca/${this.cobranca.id}/cancelar`)
          .then(() => {
            this.getCobrancaById(this.cobranca.id);

            this.openDialogMessage({
              type: "success",
              title: "Sucesso",
              text: "Cobrança cancelada com sucesso!"
            });
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Ops! ocorreu um problema ao cancelar",
              text: error.response.data
            });
          });
    },
    abrirTransacao(id) {
      const url = {
        name: "DetalheTransacao",
        params: {
          idTransacao: id.toString(),
          callback: "cobranca"
        }
      };
      this.$router.push(url);
    },
    verNota(nota) {
      this.nota = nota;
      this.showNota = true;
    },
    enviarEmail(obj) {
      this.$axios
          .post(`/transacao/${obj.id}/enviar-email`)
          .then(() => {
            this.openDialogMessage({
              type: "success",
              title: "Sucesso",
              text: "E-mail enviado!"
            });
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Enviar e-mail",
              text: error.response.data
            });
          });
    },
  },
};
</script>

<style scoped>


.text-caption {
  display: block;
}

.titulo {
  font-size: 1.3rem;
}

i {
  font-size: 1.3rem;
}

.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.btn-ver, .btn-cancelar {
  width: 8rem;
  margin: 0.5rem;
  height: 2rem;
}

.btn-copy-link {
  width: 10rem;
  margin: 0.5rem;
  height: 2rem;
}

.card-recebedores {
  max-height: 460px;
  overflow-y: auto;
}

.card-recebedores::-webkit-scrollbar {
  width: 8px;
  background-color: #e1e1e1;
}

.card-recebedores::-webkit-scrollbar-thumb {
  background-color: #bebebe;
  border-radius: 10px;
}

.border-forma-pagamento {
  border-right: 1px solid #e1e1e1;
}

@media (max-width: 850px) {
  .border-forma-pagamento {
    border-bottom: 1px solid #e1e1e1;
    border-right: none;
  }

  .col-pagador {
    margin-top: -30px;
  }
}

@media (max-width: 847px) {
  .col-emissor {
    margin-top: -30px;
  }
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}

@media (max-width: 940px) {
  .coluna-botoes-cobranca {
    display: flex;
    flex-direction: column;
  }

  .btn-copy-link {
    width: 8rem;
  }
}

</style>
