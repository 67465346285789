import { RouteConfig } from "vue-router";
import TemaNaoConfigurado from "@/views/Erro/TemaNaoConfigurado.vue";
const erroRoutes: RouteConfig = {
    path: "/configerror",
    name: "TemaNaoConfigurado",
    component: TemaNaoConfigurado,
    meta: {
        public: true
    }
}

export default erroRoutes;