<template>
  <div>
    <!-- SKELETON -->
    <v-card v-if="loadingSkeleton" flat>
      <v-skeleton-loader
          :loading="true"
          type="image@2"
          class="py-12"
      ></v-skeleton-loader>
    </v-card>

    <v-card v-else flat>
      <v-card-text>
        <v-row>
          <v-col class="text-center">
            <span class="text-subtitle-1">Monitoramento Diário</span>
          </v-col>
        </v-row>

        <Chart type="line" :data="grafico" :options="options" />
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import { mapState } from "vuex";
import Chart from "primevue/chart";

export default {
  components: {
    Chart
  },
  data() {
    return {
      loadingSkeleton: true,
      grafico: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true
      }
    };
  },
  mounted() {
    if (this.empresaAtual) this.getData();
  },
  computed: mapState(["empresaAtual"]),
  methods: {
    getData() {
      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/monitoramento?intervalo=15`
        )
        .then(response => {
          const data = response.data;
          this.grafico.labels = data.dados.map(obj => {
            return obj.data.split("-")[2];
          });

          this.grafico.datasets.push({
            label: "Vendas",
            data: data.dados.map(obj => {
              return obj.vendas.totalBruto;
            }),
            borderColor: "#FFA726",
            tension: 0.4
          });
          this.grafico.datasets.push({
            label: "Recebiveis",
            data: data.dados.map(obj => {
              return obj.recebiveis.totalBruto;
            }),
            borderColor: "#42A5F5",
            tension: 0.4
          });

          this.loadingSkeleton = false;

        });
    }
  }
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
