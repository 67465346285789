var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.title != null)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm.title))])])],1):_vm._e(),_c('DataTable',{attrs:{"value":_vm.transacoesPaginadas,"responsiveLayout":"stack","sortField":"data_criacao","sortOrder":-1,"breakpoint":"960px","scrollHeight":"flex-row"}},[_c('Column',{attrs:{"field":"id","header":"ID"}}),_c('Column',{attrs:{"field":"data_criacao","header":"Data"},scopedSlots:_vm._u([{key:"body",fn:function(slot){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(slot.data.data_criacao,{ dateStyle: "short", timeStyle: "short" }))+" ")]}}])}),_c('Column',{attrs:{"field":"tipo","header":"Tipo"},scopedSlots:_vm._u([{key:"body",fn:function(slot){return [_vm._v(" "+_vm._s(_vm.TIPO_TRANSACAO[slot.data.tipo])+" ")]}}])}),_c('Column',{attrs:{"field":"valor","header":"Valor"},scopedSlots:_vm._u([{key:"body",fn:function(slot){return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(slot.data.valor,{ minimumFractionDigits: 2 }))+" ")]}}])}),_c('Column',{attrs:{"field":"status","header":"Status"},scopedSlots:_vm._u([{key:"body",fn:function(slot){return [_c('v-chip',{staticClass:"ma-2 text-center",staticStyle:{"justify-content":"center","min-width":"100px"},attrs:{"color":_vm.statusStyle(slot.data.status),"text-color":"white"}},[_vm._v(" "+_vm._s(slot.data.status.toLowerCase())+" ")])]}}])}),_c('Column',{attrs:{"header":"Ver"},scopedSlots:_vm._u([{key:"body",fn:function(slot){return [_c('v-btn',{attrs:{"icon":"","to":{
              name: 'DetalheTransacao',
              params: {
                idTransacao: slot.data.id.toString(),
                callback: 'dashboard'
              }
            }}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])})],1),_c('v-divider'),_c('v-pagination',{staticClass:"mt-5",attrs:{"length":_vm.totalPaginas,"total-visible":10},on:{"input":_vm.setPagina},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }