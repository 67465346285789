<template>
  <v-container>
    <v-dialog v-model="showDialog" max-width="500" persistent>
      <form @submit.prevent="addEmail">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            Nota Fiscal
            <v-btn fab small text @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-chip
                  class="white--text"
                  :color="STATUS_NOTA[nota.status].cor"
                  :key="nota.id">
                  {{ STATUS_NOTA[nota.status].texto }}
                </v-chip>
              </v-col>
              <v-col cols="8">
                {{ nota.msg_erro }}
                {{ nota.mensagem_sefaz }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <span>Emissão</span>
                <p>
                  {{
                    nota.data_emissao
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                  }}
                </p>
              </v-col>
              <v-col cols="2" v-if="nota.tipo == 'NFSE'">
                  <span>Série</span>
                  <p>{{ nota.serie_rps }}</p>  
              </v-col>
              <v-col>
                <div v-if="nota.tipo == 'NFSE'">
                  <span>N° RPS</span>
                  <p>{{ nota.numero_rps }}</p>
                </div>
                <div v-else>
                  <span>Protocolo</span>
                  <p>{{ nota.protocolo }}</p>
                </div>
              </v-col>
              <v-col>
                <div v-if="nota.numero">
                  <span>Número</span>
                  <p>{{ nota.numero }}</p>
                </div>
              </v-col>
             
            </v-row>
            <v-row v-if="nota.empresa" dense>
              <v-col>
                <span>Emissor</span>
                <p>{{ nota.empresa?.nome_fantasia }}</p>
              </v-col>
              <v-col>
                <span>CNPJ</span>
                <p>{{ $masks.cnpj(nota?.empresa?.cnpj) }}</p>
              </v-col>
            </v-row>

            <v-row v-if="nota.tomador" dense>
              <v-col>
                <span>Tomador</span>
                <p>{{ nota?.tomador?.nome }}</p>
                <span>E-mail</span>
                <p>{{ nota?.tomador?.email }}</p>
              </v-col>
              <v-col>
                <span>CPF/CNPJ</span>
                <p>
                  {{ $masks.cpf(nota.tomador?.cpf) }}
                  {{ $masks.cnpj(nota.tomador?.cnpj) }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <span>Valor Bruto</span>
                <p>
                  R$ {{ nota.valorTotalBruto | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              <v-col>
                <span>Valor líquido</span>
                <p>
                  R$ {{ nota.valorTotalLiquido | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              <v-col v-if="nota.valor_total_tributos">
                <span>Tributos</span>
                <p>
                  R$ {{ nota.valor_total_tributos | formatMoney({ minimumFractionDigits: 2 }) }}
                </p>
              </v-col>
              
            </v-row>
           
            <v-row dense>
              <v-col>
                <span>Itens</span>
                <v-list-item two-line v-for="(item, i) in nota.itens" :key="i" >

                  <v-list-item-content v-if="item.servico">
                        <v-list-item-title>
                          {{ item.quantidade }} X {{ item.servico.nome }} R$
                          {{ valorItem(item) | formatMoney({ minimumFractionDigits: 2 }) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.servico.descricao }}
                        </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                        <v-list-item-title>
                          {{ item.quantidade }} X {{ item.produto.nome }} R$
                          {{ valorItem(item) | formatMoney({ minimumFractionDigits: 2 }) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.produto.descricao }}
                        </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-col>
            </v-row>
            <v-card-actions class="mt-4">
              <v-btn
                color="red"
                width="50%"
                class="white--text"
                v-if="
                  nota.status != 'CANCELADO' &&
                    user.permissoes &&
                    user.permissoes.cancelar_nfs
                "
                @click="dialogConfirmaCancelamento"
              >
                Cancelar
              </v-btn>
              <v-btn
                color="green"
                width="50%"
                class="white--text"
                v-if="nota.numero"
                @click="openUrl(nota.url)"
              >
                Ver
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </form>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DialogNotaFiscal",
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    nota: {
      type: Object,
      default: () => ({})
    },
    close: Function
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user"]),
    totalNota() {
      if (!this.nota.itens) return 0;

      const total = this.nota.itens.reduce(
        (prev, curr) => (prev += curr.quantidade * curr.valor - curr.desconto),
        0
      );
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    openUrl(url) {
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir a nota");
      }
    },
    dialogConfirmaCancelamento() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Esta ação não pode ser desfeita, deseja realmente cancelar?`,
        confirma: this.cancelar,
        cancela: this.closeDialogConfirm
      });
    },
    cancelar() {
      this.closeDialogConfirm();
      this.$axios
        .delete(`/notafiscal/${this.nota.id}/cancela`)
        .then(obj => {
          this.nota.status = obj.status;
          this.close();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar Nota Fiscal",
            text: error.response.data
          });
        });
    },
    valorItem(item) {
      return (item.quantidade * item.valor - item.desconto).toFixed(2);
    }
  }
};
</script>
<style scoped></style>
