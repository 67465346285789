import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import authRoutes from "./authRoutes";
import dashboardRoutes from "./dashboardRoutes";
import transacaoRoutes from "./transacaoRoutes";
import empresaRoutes from "./empresaRoutes";
import clienteRoutes from "./clienteRoutes";
import usuarioRoutes from "./usuarioRoutes";
import planoRoutes from "./planoRoutes";
import allRulesRoutes from "./allRuleRoutes";
import servicoRoutes from "./servicoRoutes";
import assinaturaRoutes from "./assinaturaRoutes";
import bankRoutes from "./bankRoutes";
import relatorioRoutes from "./relatorioRoutes";
import minhaContaRoutes from "./minhaContaRoutes";
import cobrancaRoutes from "./cobrancaRoutes";
import erroRoutes from "./erroRoutes";
import store from "@/store";
import notaFiscalRoutes from "./notaFiscalRoutes";
import boletoRoutes from "./boletoRoutes";
import remessaRoutes from "./remessaRoutes";
import categoriaRoutes from "./categoriaRoutes";
import produtoRoutes from "./produtoRoutes";
import contratoRoutes from "./contratoRoutes";
import mesaAntecipacaoRoutes from "./mesaAntecipacaoRoutes";
import vendasPdvRoutes from "./vendasPdvRoutes";
import setorRoutes from "@/router/setorRoutes";
import eventoRoutes from "@/router/eventoRoutes";
import estandeRoutes from "@/router/estandeRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [];
routes.push(
  authRoutes,
  dashboardRoutes,
  transacaoRoutes,
  cobrancaRoutes,
  notaFiscalRoutes,
  empresaRoutes,
  clienteRoutes,
  usuarioRoutes,
  planoRoutes,
  servicoRoutes,
  assinaturaRoutes,
  bankRoutes,
  relatorioRoutes,
  minhaContaRoutes,
  erroRoutes,
  boletoRoutes,
  remessaRoutes,
  categoriaRoutes,
  produtoRoutes,
  mesaAntecipacaoRoutes,
  contratoRoutes,
  vendasPdvRoutes,
  setorRoutes,
  eventoRoutes,
  estandeRoutes,
  allRulesRoutes // Essa rota deve sempre estar em última posição no array de rotas
);

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to: Route, from: Route, next: Function) => {
  let userAuthenticaded = store.state.authenticated;
  if (!userAuthenticaded) {
    const isAuthenticated = localStorage.getItem(
      process.env.VUE_APP_LOCAL_AUTHENTICATION_KEY!!
    );
    if (isAuthenticated) {
      userAuthenticaded = true;
      store.commit("login", JSON.parse(isAuthenticated));
    }
  }

  const { meta } = to;

  if (meta && meta.public) {
    next();
    return;
  }

  if (!userAuthenticaded) {
    next({ name: "Login" });
    return;
  }

  next();
});

export default router;
