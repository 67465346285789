<template>
  <v-dialog v-model="params.showDialog" max-width="500" persistent>
    <v-card min-height="200">
      
      <v-card-title>{{ params.title }}</v-card-title>

      <v-card-text style="padding:1rem">
        {{ params.text }}
      </v-card-text>

      <div class="centralizado-linha">
        <Button :label="params.cancelaText ? params.cancelaText : 'Não'" 
          class="p-button-text p-button-danger" icon="pi pi-times"
          @click="params.cancela" v-if="params.cancela" />
      
        <Button :label="params.confirmaText ? params.confirmaText : 'Sim'" 
                class="p-button-text p-button-success" icon="pi pi-check"
                @click="params.confirma"/>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>

import Button from 'primevue/button';

export default {
  props: {
    params: Object
  },
  components:{
    Button
  }
};
</script>

<style scoped>

@media (max-width: 900px) {
  .col,
  .col-6 {
    flex: none;
    max-width: 25rem;
  }
  .p-button-raised{
    width: 100%;
    margin: 0.8rem;
  }

}

.p-button-raised{
  width: 100%;
  max-width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem;
}


.p-button-success{
  color: #33b35b !important;
}

</style>
