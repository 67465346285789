<template>
  <v-container fluid>
    <form @submit.prevent="getNotas(true)">
      <v-row class="mobile">
        <div class="espacado">
          <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getNotas(true)"
            :showIcon="true"
            dateFormat="dd/mm/yy"
          />
        </div>

        <div class="espacado">
          <MultiSelect
            v-model="filtros.status"
            :options="status"
            @change="getNotas(true)"
            optionLabel="name"
            placeholder="Filtro por status"
            display="chip"
          />
        </div>

        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.tomador"
            separator=","
            @add="getNotas(true)"
            @remove="getNotas(true)"
            placeholder="Filtro por nome, telefone, email"
          />
        </div>

        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col  class="d-flex justify-end">
          <div @click="handleExportAllXml" class="div-btn-export-all">
            <v-icon dark color="white">
              mdi-download
            </v-icon>
            <span class="text--white text-caption ml-2"><b>Exportar XML</b></span>
          </div>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              hide-default-footer
              :fixed-header="true"
              :options="{ itemsPerPage: pagination.limit }"
              :headers="header"
              :items="notas"
          >
            <template #[`item.status`]="{ item }">
              <v-chip
                  class="white--text"
                  :color="STATUS_NOTA[item.status].cor"
                  :key="item.id"
              >
                {{ STATUS_NOTA[item.status].texto }}
              </v-chip>
            </template>

            <template #[`item.data_emissao`]="{ item }">
              {{
                item.data_emissao
                    | formatDate({ dateStyle: "short", timeStyle: "short" })
              }}
            </template>

            <template #[`item.itens`]="{ item }">
              {{ totalNota(item) | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>

            <template #[`item.msg_erro`]="{ item }">
              <v-tooltip bottom v-if="item.msg_erro">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <span>{{ item.msg_erro }}</span>
              </v-tooltip>
            </template>

            <template #[`item.xml`]="{ item }">
              <v-btn
                  type="button"
                  icon
                  v-if="item.xml"
                  @click="openUrl('https://api.focusnfe.com.br' + item.xml)"
              >
                <v-icon> mdi-download </v-icon>
              </v-btn>
            </template>

            <template #[`item.enviar`]="{ item }">
              <v-btn type="button" icon @click="enviarEmail(item)">
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>

            <template #[`item.acoes`]="{ item }">
              <v-btn type="button" icon @click="ver(item)"
                     v-if="item.tipo == 'NFSE' || item.status == 'AUTORIZADO'">
                <v-icon> mdi-magnify </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-skeleton-loader>
        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
        <v-pagination
          v-model="pagination.page"
          :length="totalPaginas"
          @input="next"
          :total-visible="10"
        >
        </v-pagination>
      </v-col>
    </v-row>

    <DialogNotaFiscal
      :nota="nota"
      v-if="showNota"
      :showDialog="showNota"
      :close="fechaDialogNotaFiscal"
    />


  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import DialogNotaFiscal from "@/components/Dialogs/DialogNotaFiscal.vue";
import Calendar from "primevue/calendar";
import moment from "moment";
import MultiSelect from "primevue/multiselect";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "NotasFiscais",
  components: {
    DialogNotaFiscal,
    Calendar,
    MultiSelect,
    Chips,
    BotaoBuscarListas,
  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: this.$store.state.filtrosNotas.dataIntervalo,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      menuDataInicio: false,
      menuDataFim: false,
      showNota: false,
      header: [
        {
          text: "Referência",
          value: "id",
          align: "center"
        },
        {
          text: "Tipo",
          value: "tipo",
          align: "center"
        },
        {
          text: "Emissão",
          value: "data_emissao",
          align: "center"
        },
        {
          text: "Status",
          value: "status",
          align: "center"
        },
        {
          text: "RPS",
          value: "numero_rps",
          align: "center"
        },
        {
          text: "Série RPS",
          value: "serie_rps",
          align: "center"
        },
        {
          text: "Número NF",
          value: "numero",
          align: "center"
        },
        {
          text: "Tomador",
          value: "tomador.nome",
          align: "center"
        },
        {
          text: "Total",
          value: "itens",
          align: "center"
        },
        {
          text: "Info",
          value: "msg_erro",
          align: "center"
        },
        {
          text: "Enviar",
          align: "center",
          value: "enviar"
        },
        {
          text: "Xml",
          align: "center",
          value: "xml"
        },
        {
          text: "Ver",
          align: "center",
          value: "acoes"
        }
      ],
      notas: [],
      nota: {},
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first: 0
      },
      filtros: {
        status: this.$store.state.filtrosNotas.status,
        tomador: this.$store.state.filtrosNotas.tomador
      },
      status: [
        { name: "Enviar", code: "ENVIAR" },
        { name: "Enviada", code: "ENVIADA" },
        { name: "Autorizado", code: "AUTORIZADO" },
        { name: "Erro autorização", code: "ERRO_AUTORIZACAO" },
        { name: "Cancelado", code: "CANCELADO" },
        { name: "Processando autorização", code: "PROCESSANDO_AUTORIZACAO" }
      ]
    };
  },
  mounted() {
    this.getNotas();
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    async getNotas(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosNotas", {
        status: this.filtros.status,
        tomador: this.filtros.tomador,
        dataIntervalo: this.dataIntervalo
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.dataIntervalo[0] || !this.dataIntervalo[1]) {
        return;
      }

      this.$axios
        .get(`/notafiscal/empresa/${this.empresaAtual.id}`, {
          headers: {
            filtros: JSON.stringify({
              tomador: this.filtros.tomador,
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.pagination.total = response.data.count;
          this.notas = response.data.data;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar notas",
            text: error.response.data
          });
        });
    },
    fechaDialogNotaFiscal() {
      this.showNota = false;
      this.getNotas();
    },
    ver(nota) {
      this.nota = nota;
      this.showNota = true;
    },
    exportar() {
      if (!this.notas) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Nenhuma nota para exportação"
        });
      }

      const copy = this.notas.map(item => {
        const obj = {
          tipo: item.tipo,
          id: item.id,
          transacao_id: item.transacao_id,
          numero_rps: item.numero_rps,
          serie_rps: item.serie_rps,
          data_emissao: item.data_emissao,
          nome_empresa: item.empresa.nome_fantasia,
          documento_cliente: item.tomador ? (item.tomador.cpf || item.tomador.cnpj) :'',
          nome_cliente: item.tomador ? item.tomador.nome: '',
          telefone_cliente: item.tomador ? item.tomador.telefone_celular:'',
          email_cliente: item.tomador ? item.tomador.email:'',
          status_nfe: item.status,
          numero_nfe: item.numero,
          url_nfe: item.url,
          xml_nfe: item.xml,
          codigo_verificacao: item.codigo_verificacao,
          xml: item.xml ? item.xml : "",
          msg_erro: item.msg_erro ? item.msg_erro : ""
        };

        return obj;
      });

      this.toCSVeDownload("NotasFiscais.csv", copy);
    },
    totalNota(nota) {
      if (!nota.itens) return 0;

      const total = nota.itens.reduce(
        (prev, curr) => (prev += curr.quantidade * curr.valor - curr.desconto),
        0
      );
      return total.toFixed(2);
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getNotas();
    },
    enviarEmail(obj) {
      this.$axios
        .post(`/notafiscal/${obj.id}/enviar-email`)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Sucesso",
            text: "E-mail enviado!"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Enviar e-mail",
            text: error.response.data
          });
        });
    },
    openUrl(url) {
      window.open(url, "_blank");
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.tomador.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

    handleExportAllXml() {
      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      this.$axios
        .get(`/notafiscal/empresa/${this.empresaAtual.id}/download-xml`, {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
          },
          responseType: "blob"
        })
        .then(response => {
          console.log("Adan-------->", response)
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));

          // Cria um link de download temporário
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'lote-xml.zip'); // Nome do arquivo a ser baixado
          document.body.appendChild(fileLink);

          // Executa o download
          fileLink.click();

          // Remove o link temporário após o download
          document.body.removeChild(fileLink);

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar notas",
            text: error.response.data
          });
        });
    }

  }
};
</script>

<style lang="scss" scoped>
.div-btn-export-all {
  padding: 8px 8px 8px 8px;
  background-color: #2196f3;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
@media (max-width: 900px) {
  .mobile {
    display: block;
  }
  .col,
  .col-3 {
    max-width: 90%;
  }
}
</style>
