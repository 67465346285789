<template>
  <v-card>
    <v-card-text>
      <v-subheader class="d-flex justify-center">
        <span class="text-h6 green--text">Transação efetuada com sucesso </span>
      </v-subheader>
      <v-container>
        <v-row>
          <v-col class="text-center">
            <svg
              id="ee370ff1-147c-4ceb-b530-4ba4072ed128"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="700.12205"
              height="241.4453"
              viewBox="0 0 800.12205 541.4453"
            >
              <path
                d="M931.831,368.72265c-.83984.69-1.68017,1.35-2.54,2H717.15087q-2.37012-.975-4.71973-2a249.80684,249.80684,0,0,1-61.87012-38.52c-12.37011-10.48-23.75-21.3-32-31.44-11.50976-14.15-22.10986-29.57-27.04-47.13995-4.91992-17.57-3.45019-37.71,7.54-52.28a46.79519,46.79519,0,0,1,12.3501-11.31c.79-.5,1.58985-.98,2.40967-1.43,13.18018-7.4,29.81006-9.2,44.65039-5.37,21.25,5.49,38.90967,20.82,51.50977,38.8,12.60009,17.97,20.78027,38.61,28.83984,59.02,8.18018-36.43,32.95019-69.06,66.41016-85.65,33.45019-16.59,75.01025-16.39,107.75,1.58a108.29189,108.29189,0,0,1,27.55029,21.7c.78955.85,1.56006,1.72,2.31982,2.6,15.96973,18.49,25.96,42.17,26.77979,66.59C970.68114,317.33264,956.10106,348.93267,931.831,368.72265Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#f2f2f2"
              />
              <path
                d="M725.63085,370.72265h-3c-.03955-.67-.08985-1.33-.1499-2a224.18,224.18,0,0,0-6.56006-38.74,238.8167,238.8167,0,0,0-38.31006-80.46,228.5998,228.5998,0,0,0-65.5-60.46,1.42,1.42,0,0,1-.69971-1.03,1.49571,1.49571,0,0,1,2.21-1.56,2.25584,2.25584,0,0,1,.19971.13,226.457,226.457,0,0,1,40.12012,31.12,240.89806,240.89806,0,0,1,51.71,74.13,233.59238,233.59238,0,0,1,19.82031,76.87C725.53124,369.39263,725.581,370.06268,725.63085,370.72265Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#fff"
              />
              <path
                d="M942.85106,219.28265a.18442.18442,0,0,1-.06982.02c-2.18994.4-4.39014.82-6.57031,1.27a296.83934,296.83934,0,0,0-52.33008,15.89,308.80792,308.80792,0,0,0-48.5,25,313.78428,313.78428,0,0,0-43.44971,33.08,307.5251,307.5251,0,0,0-36.99023,40.12,297.483,297.483,0,0,0-22.60987,34.06c-.37988.66-.75,1.33-1.12011,2h-3.42969c.35986-.67.73-1.34,1.10986-2a299.923,299.923,0,0,1,27.98-41.33,310.83961,310.83961,0,0,1,38.31006-39.75,317.8396,317.8396,0,0,1,44.37988-32.28,311.85339,311.85339,0,0,1,49.52-24.16,297.10379,297.10379,0,0,1,51.4502-14.52c.48974-.09.96973-.18,1.46-.27C943.86083,216.07263,944.66112,218.89263,942.85106,219.28265Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#fff"
              />
              <path
                d="M397.61864,376.66758c5.33472-10.07067,6.21531-23.18724-.41809-32.45416-3.94861-5.51625-10.114-9.13731-16.5831-11.17974s-13.29352-2.64274-20.05919-3.13909c-6.69738-.49134-13.51391-.88463-20.05622.63013s-12.87936,5.21528-16.06641,11.12621c-5.384,9.98545-.47945,22.78941-4.43391,33.42231-3.629,9.75779-13.95725,15.46248-24.11029,17.76466s-20.7502,2.05718-30.90827,4.337-20.50358,7.93161-24.19689,17.66525c-2.67075,7.03869-1.3438,15.14634,2.17543,21.8015s9.03083,12.04315,14.88123,16.78118c8.72778,7.06833,18.9387,13.122,30.153,13.73469,11.81633.64564,23.37009-4.97924,31.87484-13.20795s14.29474-18.86236,18.92069-29.75469c2.93464-6.91,5.70733-14.34655,11.58833-19.01277,4.32141-3.42878,9.81777-4.93092,15.14811-6.35168l6.03579-1.60878a41.44067,41.44067,0,0,0,25.89412-20.25286Q397.53861,376.81864,397.61864,376.66758Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#2f2e41"
              />
              <polygon
                points="150.079 528.665 137.82 528.665 131.987 481.377 150.082 481.377 150.079 528.665"
                fill="#ffb8b8"
              />
              <path
                d="M129.06244,525.16164h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H114.17558a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,129.06244,525.16164Z"
                fill="#2f2e41"
              />
              <polygon
                points="252.079 528.665 239.82 528.665 233.987 481.377 252.082 481.377 252.079 528.665"
                fill="#ffb8b8"
              />
              <path
                d="M231.06244,525.16164h23.64387a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H216.17558a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,231.06244,525.16164Z"
                fill="#2f2e41"
              />
              <path
                d="M373.58482,670.35742a4.83433,4.83433,0,0,1-.512-.02749l-49.87738-5.34411a4.69083,4.69083,0,0,1-4.06331-5.75129l44.16249-167.97008A4.71833,4.71833,0,0,1,366.84,487.772l40.968-11.42012a4.722,4.722,0,0,1,4.54861,1.4821c26.34037,29.71022,46.19019,95.57108,61.227,178.585a4.69166,4.69166,0,0,1-4.02183,5.48968l-40.86771,5.21789a4.67848,4.67848,0,0,1-5.17081-3.62687l-19.88367-88.64816a3.64829,3.64829,0,0,0-7.13744.08245l-18.33031,91.651A4.66624,4.66624,0,0,1,373.58482,670.35742Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#2f2e41"
              />
              <circle
                cx="166.05906"
                cy="195.89412"
                r="24.56103"
                fill="#ffb8b8"
              />
              <path
                d="M365.31214,491.73677a4.68072,4.68072,0,0,1-4.52138-3.48538L346.015,432.74183a16.77836,16.77836,0,0,1,10.11005-19.96459c11.894-4.64277,23.22577-6.3651,33.68038-5.12119h0c9.29189,1.1075,20.75451,56.11828,19.99615,57.29195l3.66683,10.99972a4.69093,4.69093,0,0,1-3.44212,6.0648l-43.695,9.61228A4.732,4.732,0,0,1,365.31214,491.73677Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#ccc"
              />
              <path
                d="M378.55437,548.5036a7.61039,7.61039,0,0,0-7.36811-9.04937l-8.51288-25.67-8.70071,11.03671,9.41789,22.68464a7.65163,7.65163,0,0,0,15.16381.998Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#ffb8b8"
              />
              <path
                d="M488.00168,486.81137a7.61039,7.61039,0,0,0-11.385-2.56171L454.061,469.32786l-.02058,14.05386,21.42241,12.01539a7.65163,7.65163,0,0,0,12.53883-8.58574Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#ffb8b8"
              />
              <path
                d="M360.7065,536.64362a4.6899,4.6899,0,0,1-4.45394-3.21767l-19.95679-42.21646a24.39872,24.39872,0,0,1-1.23475-7.66906l6-54.87133a12.059,12.059,0,0,1,24.02994-1.45563l-9.17975,62.631,14.64881,38.44a4.68142,4.68142,0,0,1-2.47839,5.225l-5.278,2.63948A4.68456,4.68456,0,0,1,360.7065,536.64362Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#ccc"
              />
              <path
                d="M467.29051,492.35386a4.681,4.681,0,0,1-2.115-.50387l-39.68768-20.04705a24.36835,24.36835,0,0,1-6.289-4.56031l-38.73694-38.86239a12.05881,12.05881,0,0,1,15.99239-17.99389l41.96249,46.60271,33.263,21.11994a4.67994,4.67994,0,0,1,1.933,5.45l-1.875,5.59554a4.692,4.692,0,0,1-4.44732,3.19934Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#ccc"
              />
              <path
                d="M338.061,362.473a28.60013,28.60013,0,0,0,16.89515,10.63127,29.8702,29.8702,0,0,0,20.06006-2.87413c4.94657-2.67271,8.905-6.68407,12.782-10.63335,1.618-1.64821,3.3414-3.549,3.26-5.79747-.12611-3.48365-4.31416-5.38825-7.90863-6.148a56.43491,56.43491,0,0,0-35.03266,3.93979c-4.88,2.26681-9.96448,6.3679-9.23175,11.46579"
                transform="translate(-199.93898 -179.27735)"
                fill="#2f2e41"
              />
              <path
                d="M983.061,368.72265h-482a17.02412,17.02412,0,0,0-17,17v186a17.02411,17.02411,0,0,0,17,17h482a17.0241,17.0241,0,0,0,17-17v-186A17.02411,17.02411,0,0,0,983.061,368.72265Zm15,203a15.01828,15.01828,0,0,1-15,15h-482a15.01828,15.01828,0,0,1-15-15v-186a15.01828,15.01828,0,0,1,15-15h482a15.01828,15.01828,0,0,1,15,15Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#3f3d56"
              />
              <path
                d="M588.5747,536.72265a58,58,0,1,1,58-58A58.06566,58.06566,0,0,1,588.5747,536.72265Zm0-114a56,56,0,1,0,56,56A56.06353,56.06353,0,0,0,588.5747,422.72265Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#3f3d56"
              />
              <path
                d="M940.04724,441.72265h-231a12.4967,12.4967,0,0,0-12.48,12.01,4.01078,4.01078,0,0,0-.02.49,12.51762,12.51762,0,0,0,12.5,12.5h231a12.5,12.5,0,0,0,0-25Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#4caf50"
              />
              <path
                d="M940.04724,490.72265h-231a12.4967,12.4967,0,0,0-12.48,12.01,4.01078,4.01078,0,0,0-.02.49,12.51762,12.51762,0,0,0,12.5,12.5h231a12.5,12.5,0,0,0,0-25Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#4caf50"
              />
              <path
                d="M582.10406,504.06438a7.45991,7.45991,0,0,1-4.48864-1.491l-.08027-.06027-16.904-12.94229a7.51006,7.51006,0,1,1,9.13524-11.92273l10.94913,8.39563,25.87453-33.74415a7.50978,7.50978,0,0,1,10.52941-1.38993l-.16087.21843.165-.21525a7.51849,7.51849,0,0,1,1.38994,10.52948L588.07894,501.1335A7.5131,7.5131,0,0,1,582.10406,504.06438Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#4caf50"
              />
              <path
                d="M581.939,720.72265h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#3f3d56"
              />
              <path
                d="M883.19672,323.67208a198.22844,198.22844,0,0,0-62.84517,1.41695,202.3421,202.3421,0,0,0-59.17277,20.68847,195.19962,195.19962,0,0,0-29.529,19.50926c-1.50813,1.2048.62683,3.31522,2.12132,2.12132a193.80848,193.80848,0,0,1,53.78714-30.23554,199.64709,199.64709,0,0,1,60.06423-12.18517,191.075,191.075,0,0,1,34.77677,1.57754,1.55392,1.55392,0,0,0,1.84518-1.04765,1.50922,1.50922,0,0,0-1.04766-1.84518Z"
                transform="translate(-199.93898 -179.27735)"
                fill="#fff"
              />
            </svg>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-space-between">
          <v-col cols="3">
            <v-btn
              color="secondary"
              style="width:100%"
              :to="{ name: comprovante.tipo }"
            >
              Nova Transferência
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="secondary"
              style="width:100%"
              :to="{ name: 'Extrato' }"
            >
              Extrato
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              color="secondary"
              style="width:100%"
              @click="openComprovante"
            >
              Comprovante
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <DialogComprovanteTransf
      v-if="comprovante"
      :comprovante="comprovante"
      :showDialog="showComprovante"
      :cancela="closeComprovante"
    />
  </v-card>
</template>

<script>
import DialogComprovanteTransf from "@/components/Dialogs/DialogComprovanteTransf.vue";
export default {
  name: "SucessoTransacao",
  components: {
    DialogComprovanteTransf
  },
  props: {
    comprovante: Object
  },

  mounted() {
    if (this.comprovante) {
      this.showComprovante = true;
    }
  },
  data() {
    return {
      showComprovante: false
    };
  },
  methods: {
    openComprovante() {
      this.showComprovante = true;
    },
    closeComprovante() {
      this.showComprovante = false;
    }
  }
};
</script>

<style></style>
