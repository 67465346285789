<template>
  <div class="pa-2 " :style="{ borderLeft: `2px solid ${color}` }">
    <v-row no-gutters>
      <v-col>
<!--        <i class="dash-icon" :class="icon" :style="{ color: color }"></i>-->
        <v-icon :color="color"> {{ icon }} </v-icon>
        <span
          class="font-italic  text-subtitle-1 text-start"
          :style="{ color: color }"
        >
          {{ titulo }}
        </span></v-col
      >
    </v-row>
    <v-row no-gutters>
      <v-col class="text-center">
        <span class="text-caption">R$ </span>
        <span class="dash-number ">
          {{ hoje | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
        <p class="text-subtitle-2 pa-0">Hoje</p>
        <span class="text-caption">Mês </span>
        <span class="text-caption ">
          R$ {{ mes | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col class="text-center">
        <span class="text-caption">R$ </span>
        <span class="dash-number ">
          {{ semana | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
        <p class="text-subtitle-2">Semana</p>
        <span class="text-caption">Receber </span>
        <span class="text-caption ">
          R$ {{ total | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  props: {},
  data() {
    return {
      titulo: "Recebíveis",
      icon: "mdi-cash-usd",
      color: "#33b35b",
      hoje: 0,
      semana: 0,
      mes: 0,
      total: 0
    };
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  mounted() {
    this.getTotalRecebiveis();
  },
  methods: {
    getTotalRecebiveis() {
      if (!this.empresaAtual || !this.empresaAtual.id) return;

      const hoje = moment();

      const fimSemana = moment().endOf("week");
      const fimMes = moment().endOf("month");
      const sempre = moment().add(5, "year");

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-recebiveis`,
          {
            params: {
              "data-inicio": hoje.format("YYYY-MM-DD"),
              "data-fim": hoje.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          this.hoje = response.data.recebiveis.totalLiquido;
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-recebiveis`,
          {
            params: {
              "data-inicio": hoje.format("YYYY-MM-DD"),
              "data-fim": fimSemana.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          this.semana = response.data.recebiveis.totalLiquido;
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-recebiveis`,
          {
            params: {
              "data-inicio": hoje.format("YYYY-MM-DD"),
              "data-fim": fimMes.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          this.mes = response.data.recebiveis.totalLiquido;
        });

      this.$axios
        .get(
          `/relatorio/transacao/empresa/${this.empresaAtual.id}/total-recebiveis`,
          {
            params: {
              "data-inicio": hoje.format("YYYY-MM-DD"),
              "data-fim": sempre.format("YYYY-MM-DD")
            }
          }
        )
        .then(response => {
          this.total = response.data.recebiveis.totalLiquido;
        });
    }
  }
};
</script>
<style>
.dash-number {
  font-size: 20px;
}
</style>
