import Vue from "vue";
Vue.filter("formatDate", function(date: string, format: object) {
  if (date == undefined) return "";
  if (date.split("T").length < 2) {
    date = date + "T00:00:00";
  }
  const dateAux: any = Date.parse(date);
  if (isNaN(dateAux)) return null;

  return new Intl.DateTimeFormat("pt-BR", format)
    .format(dateAux)
    .replaceAll(",", "");
});

Vue.filter("formatMoney", function(value: number, format: object) {
  if (!value) return "0,00";
  return new Intl.NumberFormat("pt-BR", format).format(value);
});

Vue.filter("formatBoolean", function(value: boolean) {
  return value ? "Sim" : "Não";
});

Vue.filter("formatOrigem", function(value: string) {
  return value == "ON" ? "online" : "maquininha";
});

Vue.filter("suppressEmail", function(value: string) {
  const emailParts = value.split("@");
  const localPart = emailParts[0];
  const domainPart = emailParts[1];

  const count = Math.floor(localPart.length / 2);

  const maskedLocalPart = localPart.slice(0, count) + "*****";
  const maskedEmail = maskedLocalPart + "@" + domainPart;

  return maskedEmail;
});

Vue.filter("suppressCpf", function(value: string) {
  if(value) {
    return value.slice(0, 2) + "******" + value.slice(-3);
  } else {
    return;
  }
});
