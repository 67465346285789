<template>
  <v-dialog v-model="showDialog" dense persistent max-width="1200">
    <v-card>
      <div class="d-flex">
        <v-card-title>
          Enviar Certificado Digital
        </v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="black" class="mt-2 mr-2" icon @click="cancelar">
          <v-icon size="30">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-card outlined class="px-3 py-3">
          <form @submit.prevent="handleSave">
            <v-row
                class="mx-auto align-center mt-3"
                dense
            >
              <v-col cols="12" sm="4">
                <v-file-input
                    label="Arquivo"
                    dense
                    required
                    v-model="form.file"
                ></v-file-input>
              </v-col>
              <v-col v-if="form.file" cols="12" sm="3">
                <v-text-field
                    required
                    dense
                    v-model="form.password"
                    label="Senha do certificado"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                    dark
                    small
                    color="primary"
                    type="submit"
                >
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapMutations} from "vuex";

export default {
  props: {
    showDialog: Boolean,
    empresa: Object
  },
  data() {
    return {
      form: {
        file: null,
        password: null
      }
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    handleSave() {

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64File = reader.result;

        const data = {
          certificado_base64: base64File.replace("data:application/x-pkcs12;base64,", ""),
          emite_nfse: this.empresa.emite_nfse,
          emite_nfce: this.empresa.emite_nfce,
          senha: this.form.password
        };

        if(this.empresa.emite_nfce) {
          data.id_token_nfce = this.empresa.id_token_nfce;
          data.csc_nfce = this.empresa.csc_nfce;
        }


        this.$axios
          .post(
                `/empresa/${this.empresa.id}/configuracao-nfe`,
              data,
                {
            headers: {
              "Content-Type": "application/json",
            }
          })
          .then(() => {
            this.openDialogMessage({
              type: "success",
              title: "Envio Certificado Digital",
              text: "Certificado enviado com sucesso."
            });

            this.$emit("close-dialog");

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Envio Certificado",
              text: error.response.data
            });
          });

      };

      reader.readAsDataURL(this.form.file);

    },

    cancelar() {
      this.$emit("cancela-dialog");
    }
  }

};
</script>
