<template>
  <div
      class="pa-2"
      :style="{ borderLeft: `2px solid ${color}` }"
  >
    <v-row no-gutters>
      <v-col>
<!--        <i class="dash-icon" :class="icon" :style="{ color: color }"></i>-->
        <v-icon :color="color"> {{ icon }} </v-icon>
        <span
            class="font-italic text-subtitle-1 text-start"
            :style="{ color: color }"
        >
          {{ titulo }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-8">
      <v-col class="text-center">
        <v-row>
          <v-col class="nopad">
            <span class="caption" style="color: #1b907d;">R$</span>
            <span class="dash-number" style="color: #1b907d;">{{ valores.CREDITO | formatMoney({ minimumFractionDigits: 2 }) }}</span>
            <p class="metodo nopad">Crédito</p>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col class="nopad">
            <span class="caption" style="color: #a9059a;">R$</span>
            <span class="dash-number" style="color: #a9059a;">{{ valores.DEBITO | formatMoney({ minimumFractionDigits: 2 }) }}</span>
            <p class="metodo nopad">Débito</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="nopad">
            <span class="caption" style="color: #076fbb;">R$</span>
            <span class="dash-number" style="color: #076fbb;">{{ valores.PIX | formatMoney({ minimumFractionDigits: 2 }) }}</span>
            <p class="metodo nopad">PIX</p>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col class="nopad">
            <span class="caption" style="color: orange;">R$</span>
            <span class="dash-number" style="color: orange;">{{ valores.DINHEIRO | formatMoney({ minimumFractionDigits: 2 }) }}</span>
            <p class="metodo nopad">Dinheiro</p>
          </v-col>
        </v-row>

      </v-col>

    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    valores: Object
  },
  data() {
    return {
      titulo: "Vendas por método",
      icon: "mdi-chart-line",
      color: "#3f3f3f",
    };
  },
  computed: {
  },
  methods: {
  }
};
</script>
<style scoped>
.dash-number {
  font-size: 1rem;
  color: '#424242';
}
.nopad{
  margin: 0;
  padding: 0;
}
.metodo{
  font-size: 0.8rem;
  color: '#424242';
}
.saldo {
  line-height: 1rem !important;
  font-size: 20px;
}
@media (min-width: 900px) {
  .saldo {
    min-height: 8.5rem;
  }
}
</style>
