<template>
  <v-card elevation="0" outlined v-if="user && user.permissoes.extrato_conta">
    <v-list>
      <v-subheader>
        <v-row dense class="d-flex align-center">
          <v-col>
            <form @submit.prevent="getExtrato">

              <div class="espacado">
                <Calendar v-model="dataIntervalo" selectionMode="range"
                          @date-select="getExtrato" :showIcon="true"
                          dateFormat="dd/mm/yy" />
              </div>

            </form>
          </v-col>
          <v-col class="text-end">
            Extrato
          </v-col>
        </v-row>
      </v-subheader>
      <v-divider></v-divider>
      <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
                <v-icon> mdi-download </v-icon>
            </v-btn>
        </div>
      <v-list-item v-if="carregando">
        <v-skeleton-loader
            class="mt-3"
            :loading="true"
            type="image@3"
            width="100%"
        ></v-skeleton-loader>
      </v-list-item>
      <v-list-item class="item-list" v-for="movto of extrato" :key="movto.id" :class="movto.cancelado ? 'text-decoration-line-through' : ''">
        <v-list-item-avatar>
<!--          <i-->
<!--            :class="-->
<!--              movto.valor >= 0-->
<!--                ? 'fas fa-arrow-right green&#45;&#45;text'-->
<!--                : 'fas fa-arrow-left red&#45;&#45;text'-->
<!--            "-->
<!--          ></i>-->
          <v-icon v-if="movto.valor >= 0" color="green"> mdi-arrow-right </v-icon>
          <v-icon v-else color="red"> mdi-arrow-left </v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="text-subtitle-2">
          <v-list-item-title>
            <v-row dense class="align-center">
              <v-col class="text-subtitle-2 d-flex flex-column">
                <span>
                  {{
                    movto.data_operacao
                      | formatDate({
                        dateStyle: "short"
                      })
                  }}
                </span>
                <span v-if="movto.tipo">
                  {{ TIPO_EXTRATO[movto.tipo].texto }}
                </span>
                <span v-if="movto.cancelado">
                  <Tag severity="danger" value="Cancelado" rounded></Tag>
                </span>
              </v-col>
              <v-col>
                <span class="text-subtitle"> {{ movto.descricao }}</span>
              </v-col>
              <v-col class="text-end">
                <span
                  class="ml-5 caption font-weight-bold"
                  :class="movto.valor >= 0 ? 'green--text' : 'red--text'"
                  >R$</span
                >
                <span
                  class="text-h5"
                  :class="movto.valor >= 0 ? 'green--text' : 'red--text'"
                >
                  {{
                    movto.valor
                      | formatMoney({
                        minimumFractionDigits: 2
                      })
                  }}
                </span>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';



export default {
  name: "Extrato",
  components:{
    Calendar,
    Tag
  },
  props: {
    contaCorrente: String
  },
  watch: {
    async contaCorrente(newValue, oldValue) {
      if (newValue) {
        await this.getExtrato();
      }
    }
  },
  async mounted() {
    if(!this.user || !this.user.permissoes.extrato_conta){
      this.$store.commit("logout", false);
      return;
    }
    if (this.contaCorrente) {
      await this.getExtrato();
    }
  },
  data() {
    return {
      dataIntervalo: [new Date(), new Date()],
      dataInicio: new Date().toISOString().split('T')[0],
      dataFim: new Date().toISOString().split('T')[1],
      extrato: [],
      carregando: false,
    };
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getExtrato() {
      if (!this.contaCorrente) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Informe uma conta corrente"
        });
        return;
      }


      if(this.dataIntervalo[0]){
        this.dataInicio = this.dataIntervalo[0].toISOString().substr(0, 10);
      }

      if(this.dataIntervalo[1]){
        this.dataFim = this.dataIntervalo[1].toISOString().substr(0, 10);
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      this.carregando = true;
      this.$axios.get( `/banco/conta/${this.contaCorrente}/extrato?data-inicio=${this.dataInicio}&data-fim=${this.dataFim}`)
        .then(response => {
          this.extrato = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Extrato",
            text: error.response.data
          });
        }).finally(()=>{
          this.carregando = false;
        });

    },
    async exportar(){

      if(!this.extrato){
         this.openDialogMessage({
            type: "error",
            title: "Atenção",
            text: "Nenhum registro para exportação",
          });
      }

      await this.toCSVeDownload("extrato.csv", this.extrato);

    },
  }
};
</script>

<style scoped>

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
