import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Eventos from "@/views/Evento/Eventos.vue";
import FormEvento from "@/views/Evento/FormEvento.vue";
import FormPlano from "@/views/Plano/FormPlano.vue";

const eventoRoutes: RouteConfig = {
  path: "/eventos",
  component: GenericComponent,
  children: [
    {
      path: "/",
      component: Eventos,
      name: "Eventos",
      meta: {
        breadCrumb: [
          {
            text: "Eventos"
          }
        ]
      }
    },
    {
      path: "/eventos/add",
      name: "addEvento",
      component: FormEvento,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Eventos",
              to: {
                name: "Eventos"
              }
            },
            {
              text: "Novo Evento"
            }
          ];
        }
      }
    },
    {
      path: "/eventos/:idEvento",
      component: FormEvento,
      name: "viewEvento",
      props: route => ({
        mode: "view",
        idEvento: route.params.idEvento
      }),
      meta: {
        breadCrumb(route: Route) {
          const idEvento = route.params.idEvento;

          return [
            {
              text: "Eventos",
              to: { name: "Eventos" }
            },
            {
              text: idEvento
            }
          ];
        }
      }
    },
    {
      path: "/eventos/:idEvento/edit",
      name: "editEvento",
      component: FormEvento,
      props: route => ({
        mode: "edit",
        idEvento: route.params.idEvento
      }),
      meta: {
        breadCrumb(route: Route) {
          const idEvento = route.params.idEvento;

          return [
            {
              text: "Eventos",
              to: { name: "Eventos" }
            },
            {
              text: idEvento,

              to: {
                name: "viewEvento",
                params: {
                  idUsuario: idEvento
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};

export default eventoRoutes;
