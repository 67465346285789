import { Route, RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import NotasFiscais from "@/views/NotaFiscal/NotasFiscais.vue";

const notaFiscalRoutes: RouteConfig = {
    path: "/notasfiscais",
    component: GenericComponent,
    children: [
        {
            path: "/",
            component: NotasFiscais,
            name: "NotasFiscais",
            meta: {
                breadCrumb: [
                    {
                        text: "Notas"
                    }
                ]
            }

        },
        {
            path: ":id",
            component: NotasFiscais,
            name: "VerNota",
            props: route => ({
                id: route.params.id
            }),
            meta: {
                breadCrumb(route: Route) { 
                    const id  = route.params.id
                return [
                    {
                        text: "Notas",
                        to: {
                            name: "NotasFiscais"
                        }
                    },
                    {
                        text: id
                    }
                ];
            }
            }
        },
        {
            path: "add",
            component: NotasFiscais,
            name: "CriaNota",
            props: route => ({
                id: route.params.id
            }),
            meta: {
                breadCrumb(route: Route) { 
                    const id  = route.params.id
                return [
                    {
                        text: "Notas",
                        to: {
                            name: "NotasFiscais"
                        }
                    },
                    {
                        text: id
                    }
                ];
            }
            }
        }
    ]
}

export default notaFiscalRoutes;