<template>
  <div
    class="pa-2 card"
    :style="{ borderLeft: `2px solid ${color}` }">
    <v-row no-gutters>
      <span class="font-italic text-subtitle-1 text-start" :style="{ color: color }">
        {{ titulo }}
      </span>
    </v-row>

    <v-col class="d-flex centralizado green--text">
      <div>
        <span class="caption">R$</span>
        <span class="text-h3">
          {{
            totais.valor_liquido | formatMoney({ minimumFractionDigits: 2 })
          }}
        </span>
      </div>
    </v-col>
    <div class="centralizado">
      <span class="detalhes grey--text font-weight-regular">
        Total de taxa -R$ {{ totais.total_taxas | formatMoney({ minimumFractionDigits: 2 }) }} 
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totais: {
      valor_bruto: Number,
      valor_liquido: Number,
      total_taxas: Number
    }
  },
  data() {
    return {
      titulo: "Você receberá",
      color: "#33b35b"
    };
  },
  methods: {}
};
</script>
<style scoped>

.detalhes{
  font-size: 0.8rem;
}

@media (min-width: 900px) {
  .card {
    min-height: 8.5rem;
  }
}
</style>
