<template>
  <div>
    <v-container fluid>
      <form @submit.prevent="getTransacoes(true)">
        <v-row>
          <div class="espacado">
            <Calendar v-model="dataIntervalo" selectionMode="range"
                      @date-select="getTransacoes(true)" :showIcon="true"
                      dateFormat="dd/mm/yy" />
          </div>
          <div class="espacado">
            <MultiSelect v-model="filtros.status" :options="status" @change="getTransacoes(true)"
                        optionLabel="name" placeholder="Filtro por status" display="chip"/>

          </div>
          <div class="espacado">
            <Chips ref="chipcomponente" v-model="filtros.cliente" separator=","  @add="getTransacoes(true)" @remove="getTransacoes(true)"
                    placeholder="Filtro por nome, telefone, email"/>
          </div>
          <div class="espacado">
            <BotaoBuscarListas @buscar="buscar" />
          </div>
        </v-row>
      </form>
    </v-container>
    <div class="text-end">
      <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
        </v-btn>
    </div>
    <v-skeleton-loader
        :loading="loadingSkeleton"
        type="table"
    >
      <v-data-table
          :headers="headers"
          :items="transacoes"
          :loading="carregando"
          loading-text="Carregando dados"
          :search="search"
          :fixed-header="true"
          hide-default-footer
          class="fill-height"
          :options="{
        itemsPerPage: pagination.limit,
      }"
      >
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
              class="ma-2 text-center"
              :color="statusStyle(item.status)"
              style="justify-content: center; min-width: 100px"
              label
              text-color="white">
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.data_criacao`]="{ item }">
          {{
            item.data_criacao
                | formatDate({ dateStyle: "short", timeStyle: "short" })
          }}
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          {{
            TIPO_TRANSACAO[item.tipo]
          }}
        </template>
        <template v-slot:[`item.dataCompensado`]="{ item }">
          {{ item.dataCompensado | formatDate({ dateStyle: "short" }) }}
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          {{
            item.valor
                | formatMoney({
              minimumFractionDigits: 2,
            })
          }}
        </template>
        <template v-slot:[`item.valor_liquido`]="{ item }">
          {{
            item.valor_liquido
                | formatMoney({
              minimumFractionDigits: 2,
            })
          }}
        </template>
        <template v-slot:[`item.valor_taxa_administracao`]="{ item }">
          {{
            item.valor_taxa_administracao
                | formatMoney({
              minimumFractionDigits: 2,
            })
          }}
        </template>
        <template v-slot:[`item.valor_taxa_antecipacao`]="{ item }">
          {{
            item.valor_taxa_antecipacao
                | formatMoney({
              minimumFractionDigits: 2,
            })
          }}
        </template>
        <template v-slot:[`item.acoes`]="{ item }">
          <button type="button" @click="detalheTransacao(item.id)" icon>
            <v-icon> mdi-magnify </v-icon>
          </button>
        </template>
      </v-data-table>
    </v-skeleton-loader>
     <v-pagination  v-model="pagination.page" :length="totalPaginas" @input="next"
                    :total-visible="10">
     </v-pagination>

    <router-view></router-view>
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
import Calendar from 'primevue/calendar';
import Chips from 'primevue/chips';
import MultiSelect from 'primevue/multiselect';
import moment from 'moment';
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Transacoes",
  components:{
    Calendar, MultiSelect, Chips, BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      showTransacaoDialog: false,
      idTransacaoDialog: null,
      transacoes: [],
      carregando: false,
      search: null,
      dataIntervalo: this.$store.state.filtrosTransacoes.dataIntervalo,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      menuDataInicio: false,
      menuDataFim: false,
      filtros:{
        status: this.$store.state.filtrosTransacoes.status,
        cliente: this.$store.state.filtrosTransacoes.cliente
      },
      headers: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "Venda",
          align: "center",
          sortable: false,
          value: "data_criacao",
        },

        {
          text: "Tipo",
          align: "center",
          sortable: false,
          value: "tipo",
        },
        {
          text: "Parcelas",
          align: "center",
          sortable: false,
          value: "parcelas",
        },

        {
          text: "Origem",
          align: "center",
          sortable: false,
          value: "origem",
        },
        {
          text: "Valor",
          align: "center",
          sortable: false,
          value: "valor",
        },
        {
          text: "Líquido",
          align: "center",
          sortable: false,
          value: "valor_liquido",
        },
        {
          text: "MDR",
          align: "center",
          sortable: false,
          value: "valor_taxa_administracao",
        },
        {
          text: "Antecipação",
          align: "center",
          sortable: false,
          value: "valor_taxa_antecipacao",
        },
        {
          text: "Cliente",
          align: "center",
          sortable: false,
          value: "cliente.nome",
        },
        {
          text: "E-mail",
          align: "center",
          sortable: false,
          value: "cliente.email",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "acoes",
        },
      ],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      status:[
        {name:'Pago',code:'PAGO'},
        {name:'Estornado',code:'ESTORNADO'},
        {name:'Vencido',code:'VENCIDO'},
        {name:'Pendente',code:'PENDENTE'},
        {name:'Negada',code:'NEGADA'},
        {name:'Cancelado',code:'CANCELADO'},
        {name:'Chargeback',code:'CHARGEBACK'},
        {name:'Falha',code:'FALHA'},
        {name:'Protestado',code:'PROTESTADO'},
        {name:'Iniciada',code:'INICIADA'},

      ]
    };
  },
  computed: {
    ...mapState(["empresaAtual","user"]),
    totalPaginas(){
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },

  mounted() {
    this.getTransacoes();
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    detalheTransacao(idTransacao) {
      this.$router.push({
        name: "DetalheTransacao",
        params: { idTransacao: idTransacao.toString() },
      });
    },

   async getTransacaoById(id) {

      if(id) {
        await this.$axios
          .get(`/transacao/${id}`)
          .then(response => {
            this.transacoes.push(response.data);
          })
          .catch(error => {
            console.log(error)
          });
      }

      this.loadingSkeleton = false;
    },

    getTransacoes(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosTransacoes", {
        status: this.filtros.status,
        cliente: this.filtros.cliente,
        dataIntervalo: this.dataIntervalo
      });

      if(this.dataIntervalo[0]){
        this.dataInicio = moment(this.dataIntervalo[0]).format('YYYY-MM-DD');
      }

      if(this.dataIntervalo[1]){
        this.dataFim = moment(this.dataIntervalo[1]).format('YYYY-MM-DD');
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }


      if(!this.empresaAtual || !this.empresaAtual.id) return;

      this.transacoes = [];

      this.$axios.get(`/transacao/empresa/${this.empresaAtual.id}/`, {
          headers:{
            "filtros": JSON.stringify({
                cliente: this.filtros.cliente,
                status: this.filtros.status.map(m=>m.code)
              })
          },
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            "first": this.pagination.first,
            "limit": this.pagination.limit,
            "ordem": 'DESC'
          },
        })
        .then((response) => {

          if(response.data.data.length === 0) {
            const id = this.filtros.cliente[0];

            //Verificando se é um número para pesquisar pelo ID
            if (/^-?\d+(\.\d+)?$/.test(id)) {
              this.getTransacaoById(id);
            } else {
              this.loadingSkeleton = false;
            }

          } else {
            this.pagination.total = response.data.count;
            this.transacoes = response.data.data;
            this.loadingSkeleton = false;
          }

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data
          });
        });
    },
    next(page){
      this.pagination.page = page
      this.pagination.first = ((page-1) * this.pagination.limit);
      this.getTransacoes();
    },
   async exportar(){

      if(!this.transacoes){
        this.openDialogMessage({
            type: "error",
            title: "Atenção",
            text: "Nenhuma cobrança para exportação",
          });
      }

     this.$axios.get(`/transacao/empresa/${this.empresaAtual.id}/`,
      {
          headers:{
            "filtros": JSON.stringify({
                cliente: this.filtros.cliente,
                status: this.filtros.status.map(m=>m.code)
              })
          },
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            "first": 0,
            "limit": this.pagination.total,
            "ordem": 'ASC'
          },
        })
        .then((response) => {
          const lista = response.data.data.map((item) =>{
              const obj = {
                id: item.id,
                id_externo: item.id_extreno,
                nsu: item.nsu,
                pdv_adquirente: item.pdv_adquirente,
                numero_terminal: item.numero_terminal,
                origem: item.origem,
                data_criacao: item.data_criacao.split('T')[0],
                hora_criacao: item.data_criacao.split('T')[1],
                data_atualizacao: item.data_atualizacao.split('T')[0],
                hora_atualizacao: item.data_atualizacao.split('T')[1],
                status: item.status,
                tipo: item.tipo,
                parcelas: item.parcelas,
                card_brand: item.card_brand,
                valor: this.parseFloatToMoney(item.valor),
                valor_liquido: this.parseFloatToMoney(item.valor_liquido),
                valor_taxa_administracao: this.parseFloatToMoney(item.valor_taxa_administracao),
                valor_taxa_antecipacao: this.parseFloatToMoney(item.valor_taxa_antecipacao),
                nome_cliente: item.cliente?.nome,
                telefone_cliente: item.cliente?.telefone_celular,
                email_cliente: item.cliente?.email,
                nome_empresa: item.empresa.nome_fantasia,
                cobranca_id: item.cobranca_id,
                url_boleto: item.url_boleto,
                vencimento_boleto: item.vencimento_boleto?.split('T')[0],
                data_pagamento_boleto: item.data_pagamento_boleto?.split('T')[0],
                valor_pago_juros_multa: this.parseFloatToMoney(item.valor_pago_juros_multa),
                //itens: item.itens?.map((s) =>{ return `${s.servico.nome};`})
              }
            return this.replaceUndefinedWithEmptyString(obj);
          })

           this.toCSVeDownload("transacoes.csv", lista);
        })
        .catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar cobranças",
            text: error.response?error.response.data:error.message,
          });
        });


    },

    replaceUndefinedWithEmptyString(obj) {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key] === undefined ? "" : obj[key];
      });
      return newObj;
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.cliente.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  },
};
</script>

<style scoped>
.p-chips {
    min-width: 20rem;
}
</style>
