<template>
  <div
    class="pa-2 saldo"
    :style="{ borderLeft: `2px solid ${color}` }"
  >
    <v-row no-gutters>
      <v-col>
<!--        <i class="dash-icon" :class="icon" :style="{ color: color }"></i>-->
        <v-icon :color="color"> {{ icon }} </v-icon>
        <span
          class="font-italic text-subtitle-1 text-start"
          :style="{ color: color }"
        >
          {{ titulo }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="conta">
      <v-col class="text-center">
        <span class="caption green--text">R$</span>
        <span class="text-h3 green--text saldo">{{
          saldo | formatMoney({ minimumFractionDigits: 2 })
        }}</span>
        <p></p>
        <span class="text-caption banco">{{ conta.banco.nome }} </span>
        <span class="text-caption"
          >AG. {{ conta.agencia }} {{ conta.digito_agencia }}</span
        >
        <span class="text-caption"
          >C/C {{ conta.numero_conta }} {{ conta.digito_numero_conta }}</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      titulo: "Saldo",
      icon: "mdi-wallet",
      color: "#076fbb",
      saldo: 0,
      conta: null
    };
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  mounted() {
    this.getContasCorrentes();
  },
  methods: {
    getContasCorrentes() {
      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/empresa/${this.empresaAtual.id}/conta-corrente`)
        .then(async response => {
          this.contas = response.data;
          this.conta = this.contas.filter(c => c.principal)[0];
          if (this.conta) {
            this.getSaldo();
          }
        });
    },
    getSaldo() {
      this.$axios
        .get(`/banco/conta/${this.conta.id}/saldo`)
        .then(response => {
          this.saldo = response.data.saldo;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Saldo",
            text: error.response.data
          });
        });
    }
  }
};
</script>
<style scoped>
.dash-number {
  font-size: 20px;
}
.saldo {
  line-height: 1rem !important;
}
.banco {
  display: block;
}
@media (min-width: 900px) {
  .saldo {
    min-height: 8.5rem;
  }
}
</style>
