import Vue from "vue";
import Vuex from "vuex";
import router from "@/router/index";
import baseApi from "../plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    empresaAtual: localStorage.getItem(process.env.VUE_APP_LOCAL_KEY!!),
    contaCorrente: null,
    authenticated: false,
    config: {
      id_marketplace: "",
      nome: "",
      colors: {
        primary: "",
        secondary: "",
        error: "",
        info: " ",
        success: "",
        warning: "",
        font: "",
        btn_confirma: "",
        btn_cancela: "",
        cards: {
          recebiveis: "",
          vendas: "",
          estornos: "",
          falhas: ""
        }
      },
      images: {
        favicon: "",
        logo: "",
        background: ""
      },
      url_app_anroid: "",
      url_app_ios: ""
    },
    user: {
      id: null,
      tipo: null,
      senha: null,
      email: null,
      redirect: false,
      token: null
    },
    menuOpen: false,
    appLoading: false,
    showEmpresasDialog: false,
    dialogMessageParams: {
      showDialog: false,
      type: "",
      title: "",
      text: ""
    },
    dialogConfirmParams: {
      showDialog: false,
      title: "",
      text: "",
      confirma: Function,
      cancela: Function
    },
    filtrosTransacoes: {
      status: [],
      cliente: [],
      dataIntervalo: [new Date(), new Date()]
    },
    filtrosCobrancas: {
      status: [],
      cliente: [],
      dataIntervalo: [new Date(), new Date()]
    },
    filtrosAssinaturas: {
      status: [],
      titular: []
    },
    filtrosContratos: {
      status: [],
      pagador: [],
      emissor: [],
      tab: 0
    },
    filtrosNotas: {
      status: [],
      tomador: [],
      dataIntervalo: [new Date(), new Date()]
    },
    filtrosRemessas: {
      filtros: [],
      tipo: "REMESSA",
      dataIntervalo: [new Date(), new Date()]
    },
    filtrosEmpresas: {
      status: [],
      empresa: []
    },
    filtrosClientes: {
      cliente: [],
      status: []
    },
    filtrosServicos: {
      servico: [],
      status: []
    },
    filtrosProdutos: {
      produto: [],
      status: []
    },
    filtrosPlanos: {
      planos: [],
      status: []
    },
    filtrosUsuarios: {
      usuarios: [],
      status: []
    },
    filtrosVendasPdv: {
      cliente: [],
      dataIntervalo: [],
      tab: 0
    }
  },
  getters: {
    getEmpresaAtual(state) {
      if (state.empresaAtual) {
        return state.empresaAtual;
      }
      const local = localStorage.getItem(process.env.VUE_APP_LOCAL_KEY!!);
      if (local) {
        return JSON.parse(local);
      }
      return {};
    },
    isAuthenticated(state) {
      return state.authenticated;
    },
    isMenuOpen(state) {
      return state.menuOpen;
    },
    isAppLoading(state) {
      return state.appLoading;
    }
  },
  mutations: {
    setAppConfig(state, theme) {
      state.config = theme;
    },
    setEmpresaAtual(state, empresa) {
      if (empresa) {
        state.empresaAtual = empresa;
        localStorage.setItem(
          process.env.VUE_APP_LOCAL_KEY!!,
          JSON.stringify(empresa)
        );
      }
    },
    autentica(state) {
      state.authenticated = true;
    },
    login(state, payload) {
      let redirect;
      state.user = payload;
      if (state.user.redirect) {
        redirect = payload.redirect;
        delete payload.redirect;
      }
      localStorage.setItem(
        process.env.VUE_APP_LOCAL_AUTHENTICATION_KEY!!,
        JSON.stringify(payload)
      );
      state.authenticated = state.user.token ? true : false;
      if (redirect) router.push("/");
    },
    logout(state) {
      state.authenticated = false;
      localStorage.setItem(
        process.env.VUE_APP_LOCAL_AUTHENTICATION_KEY!!,
        ""
      );
      router.push("/login");
    },
    openMenu(state, menuOpen) {
      state.menuOpen = menuOpen;
    },
    showEmpresasDialog(state, valor) {
      state.showEmpresasDialog = valor;
    },
    setAppLoading(state, appLoading) {
      state.appLoading = appLoading;
    },

    setContaCorrente(state, contaCorrente) {
      state.contaCorrente = contaCorrente;
    },
    openDialogMessage(state, dialogMessageParams) {
      state.dialogMessageParams = {
        showDialog: true,
        type: dialogMessageParams.type,
        title: dialogMessageParams.title,
        text: dialogMessageParams.text
      };
    },
    closeDialogMessage(state) {
      state.dialogMessageParams = {
        showDialog: false,
        type: "",
        title: "",
        text: ""
      };
    },
    openDialogConfirm(state, dialogConfirmParams) {
      state.dialogConfirmParams = {
        showDialog: true,
        title: dialogConfirmParams.title,
        text: dialogConfirmParams.text,
        confirma: dialogConfirmParams.confirma,
        cancela: dialogConfirmParams.cancela
      };
    },
    closeDialogConfirm(state) {
      state.dialogConfirmParams = {
        showDialog: false,
        title: "",
        text: "",
        confirma: Function,
        cancela: Function
      };
    },
    setFiltrosTransacoes(state, payload) {
      state.filtrosTransacoes = payload;
    },
    setFiltrosCobrancas(state, payload) {
      state.filtrosCobrancas = payload;
    },
    setFiltrosAssinaturas(state, payload) {
      state.filtrosAssinaturas = payload;
    },
    setFiltrosContratos(state, payload) {
      state.filtrosContratos = payload;
    },
    setFiltrosNotas(state, payload) {
      state.filtrosNotas = payload;
    },
    setFiltrosRemessas(state, payload) {
      state.filtrosRemessas = payload;
    },
    setFiltrosEmpresas(state, payload) {
      state.filtrosEmpresas = payload;
    },
    setFiltrosClientes(state, payload) {
      state.filtrosClientes = payload;
    },
    setFiltrosServicos(state, payload) {
      state.filtrosServicos = payload;
    },
    setFiltrosProdutos(state, payload) {
      state.filtrosProdutos = payload;
    },
    setFiltrosPlanos(state, payload) {
      state.filtrosPlanos = payload;
    },
    setFiltrosUsuarios(state, payload) {
      state.filtrosUsuarios = payload;
    },
    setFiltrosVendasPdv(state, payload) {
      state.filtrosVendasPdv = payload;
    }
  },
  actions: {
    async getConfig(state, hostParam) {
      return baseApi
        .get(`/public/marketplace/${hostParam}`)
        .then(async response => {
          this.commit("setAppConfig", response.data);
        });
    },
    async getNewToken(state, payload) {
      const url = `${process.env.VUE_APP_ROOT_API}/usuario/autenticar`;
      const data = {
        id: payload.user.id,
        token: payload.user.token,
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Credential-Type": "revalidate"
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          console.error("Erro na resposta HTTP:", response.status, response.statusText);
          const text = await response.text();
          console.error("Conteúdo da resposta:", text);
          return;
        }

        return await response.json();

      } catch (error) {
        console.error('Erro ao obter novo token:', error);
      }
    }
  },
  modules: {}
});
