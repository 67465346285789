<template>
  <v-container v-if="usuario">
    <v-row>
      <v-col>
        <form @submit.prevent="atualizarUsuario">
          <v-container>
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                  disabled
                  dense
                  prepend-icon="mdi-passport"
                  required
                  label="CPF"
                  @keyup="usuario.cpf = $masks.cpf(usuario.cpf)"
                  v-model="usuario.cpf"
                >
                </v-text-field>
              </v-col>
              
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  prepend-icon="mdi-account-check"
                  v-model="usuario.nome"
                  label="Nome"
                  required
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  required
                  dense
                  prepend-icon="mdi-cellphone-basic"
                  @focus="usuario.telefone = $masks.telefone(usuario.telefone)"
                  @keyup="usuario.telefone = $masks.telefone(usuario.telefone)"
                  v-model="usuario.telefone"
                  label="Telefone"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-email"
                  disabled
                  dense
                  v-model="usuario.email"
                  required
                  label="E-mail"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-row>
            <v-col class="text-end">
              <v-btn color="primary" type="submit">
                Atualizar
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    usuario: Object
  },

  methods: {
    ...mapMutations(["openDialogMessage"]),
    atualizarUsuario() {
      this.$axios
        .put("/usuario", this.usuario)
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Atualizar Usuário",
            text: "Usuário atualizado com sucesso"
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Usuário",
            text: error.response.data
          });
        });
    }
  }
};
</script>

<style></style>
