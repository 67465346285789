import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Setores from "@/views/Setor/Setores.vue";

const setorRoutes: RouteConfig = {
  path: "/setores",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Setores",
      component: Setores,

      meta: {
        breadCrumb: [
          {
            text: "Setores"
          }
        ]
      }
    }
  ]
};
export default setorRoutes;
