<template>
  <div class="cabecalho">
    <div class="item-cabecalho">
      <v-app-bar-nav-icon
        color="secondary"
        class="neon"
        small
        @click="openMenu"
      >
      </v-app-bar-nav-icon>

      <div @click="openEmpresaDialog">
        <div v-if="empresaAtual" class="empresa">
          <v-icon dark large>mdi-ballot-recount-outline</v-icon>

          <div v-if="isMobile" class="empresa-dados">
            <span class="white--text">{{ empresaAtual.nome_fantasia }}</span>
            <span class="text-caption white--text box-menu-mobile"
              >{{ empresaAtual.cnpj }} - {{ empresaAtual.razao_social }} </span
            >
          </div>
          <div class="empresa-dados" v-else>
            <span class="white--text">{{ empresaAtual.nome_fantasia }}</span>
          </div>
        </div>

        <div v-else class="box-definir-empresa">
          <v-col cols="1" class="d-flex flex-column">
            <v-icon dark large>mdi-ballot-recount-outline</v-icon>
          </v-col>

          <v-col class="d-flex flex-column">
            <span class="white--text"
              >Clique a aqui para definir a empresa</span
            >
          </v-col>
        </div>
      </div>
    </div>

    <div class="item-cabecalho">
      <div v-if="isMobile" class="sessao">
        <span class="text-caption white--text">{{ user.nome }}</span>
        <span class="text-caption white--text">{{ user.email }}</span>
      </div>

      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <svg
            v-bind="attrs"
            v-on="on"
            id="457bf273-24a3-4fd8-a857-e9b918267d6a"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="40"
            height="40"
            viewBox="0 0 698 698"
          >
            <defs>
              <linearGradient
                id="b247946c-c62f-4d08-994a-4c3d64e1e98f"
                x1="349"
                y1="698"
                x2="349"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="gray" stop-opacity="0.25" />
                <stop offset="0.54" stop-color="gray" stop-opacity="0.12" />
                <stop offset="1" stop-color="gray" stop-opacity="0.1" />
              </linearGradient>
            </defs>
            <title>profile pic</title>
            <g opacity="0.5">
              <circle
                cx="349"
                cy="349"
                r="349"
                fill="url(#b247946c-c62f-4d08-994a-4c3d64e1e98f)"
              />
            </g>
            <circle cx="349.68" cy="346.77" r="341.64" fill="#f5f5f5" />
            <path
              d="M601,790.76a340,340,0,0,0,187.79-56.2c-12.59-68.8-60.5-72.72-60.5-72.72H464.09s-45.21,3.71-59.33,67A340.07,340.07,0,0,0,601,790.76Z"
              transform="translate(-251 -101)"
              fill="#6c63ff"
            />
            <circle cx="346.37" cy="339.57" r="164.9" fill="#333" />
            <path
              d="M293.15,476.92H398.81a0,0,0,0,1,0,0v84.53A52.83,52.83,0,0,1,346,614.28h0a52.83,52.83,0,0,1-52.83-52.83V476.92a0,0,0,0,1,0,0Z"
              opacity="0.1"
            />
            <path
              d="M296.5,473h99a3.35,3.35,0,0,1,3.35,3.35v81.18A52.83,52.83,0,0,1,346,610.37h0a52.83,52.83,0,0,1-52.83-52.83V476.35A3.35,3.35,0,0,1,296.5,473Z"
              fill="#fdb797"
            />
            <path
              d="M544.34,617.82a152.07,152.07,0,0,0,105.66.29v-13H544.34Z"
              transform="translate(-251 -101)"
              opacity="0.1"
            />
            <circle cx="346.37" cy="372.44" r="151.45" fill="#fdb797" />
            <path
              d="M489.49,335.68S553.32,465.24,733.37,390l-41.92-65.73-74.31-26.67Z"
              transform="translate(-251 -101)"
              opacity="0.1"
            />
            <path
              d="M489.49,333.78s63.83,129.56,243.88,54.3l-41.92-65.73-74.31-26.67Z"
              transform="translate(-251 -101)"
              fill="#333"
            />
            <path
              d="M488.93,325a87.49,87.49,0,0,1,21.69-35.27c29.79-29.45,78.63-35.66,103.68-69.24,6,9.32,1.36,23.65-9,27.65,24-.16,51.81-2.26,65.38-22a44.89,44.89,0,0,1-7.57,47.4c21.27,1,44,15.4,45.34,36.65.92,14.16-8,27.56-19.59,35.68s-25.71,11.85-39.56,14.9C608.86,369.7,462.54,407.07,488.93,325Z"
              transform="translate(-251 -101)"
              fill="#333"
            />
            <ellipse
              cx="194.86"
              cy="372.3"
              rx="14.09"
              ry="26.42"
              fill="#fdb797"
            />
            <ellipse
              cx="497.8"
              cy="372.3"
              rx="14.09"
              ry="26.42"
              fill="#fdb797"
            />
          </svg>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'MinhaContaDados' }">
            <v-list-item-icon>
<!--              <v-icon color="primary">fas fa-user-circle</v-icon>-->
              <v-icon color="primary"> mdi-account-circle </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Minha Conta</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon color="primary"> mdi-power-standby </v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Sair
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["empresaAtual", "user"]),
    isMobile() {
      return window.screen.width > 480;
    }
  },
  methods: {
    logout() {
      this.$store.commit("logout", false);
    },
    openMenu() {
      this.$store.commit("openMenu", true);
    },
    openEmpresaDialog() {
      this.$store.commit("showEmpresasDialog", true);
    }
  }
};
</script>
<style scoped>
.neon {
  animation: pisca 0.5s 10 ease-in-out alternate backwards;
}
@keyframes pisca {
  0% {
    box-shadow: 0 0 5px #3c89fc;
  }
  100% {
    box-shadow: 0 0 5px #3c89fc, 0 0 10px #3c89fc, 0 0 20px #3c89fc,
      0 0 40px #3c89fc, 0 0 60px #3c89fc;
  }
}
.cabecalho {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item-cabecalho {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.sessao {
  flex-direction: column;
  display: flex;
  margin-right: 1rem;
  text-align: end;
  @media only screen and (max-width: 900px) {
   display: none;
  }
}

.empresa {
  display: flex;
  margin-left: 1rem;
  text-align: start;
}
.empresa-dados {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
}
.box-definir-empresa {
  display: flex;
  align-items: center;
}
.box-definir-empresa > div > i {
  margin-left: 10px;
}
.box-definir-empresa > div > span {
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    margin-left: 10px;
  }
}

.box-menu-mobile {
  display: block;
  @media only screen and (max-width: 900px) {
   display: none;
  }
}
</style>
