import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "@/store";
import "@/mixins/mixins";
import masks from "@/plugins/mask";
import baseApi from "@/plugins/axios";
import "@/plugins/filters";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import PrimeVue from "primevue/config";
import ToastService from 'primevue/toastservice';

Vue.config.productionTip = false;
Vue.prototype.$axios = baseApi;
Vue.prototype.$masks = masks;
Vue.use(PrimeVue, {
  locale: {
    firstDayOfWeek: 0,
    dayNames: [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sabado"
    ],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    monthNamesShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    today: "Hoje",
    clear: "Limpar",
    dateFormat: "dd/MM/yyyy",
    weekHeader: "Wk"
  }
});
Vue.use(ToastService);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
