<template>
  <v-card>
    <form @submit.prevent="confirmaTransacao">
      <v-subheader class="text-subtitle-1 font-weight-bold">
        TED
      </v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-autocomplete
              required
              dense
              :items="bancos"
              v-model="ted.destino.banco"
              item-value="codigo_febraban"
              :item-text="banco => `${banco.codigo_febraban} - ${banco.nome}`"
              label="Banco"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-text-field
              required
              dense
              v-model="ted.destino.agencia"
              label="Agência"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field
              dense
              v-model="ted.destino.digito_agencia"
              label="Digito"
            >
            </v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              required
              dense
              v-model="ted.destino.numero_conta"
              label="Num. Conta"
            >
            </v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field
              required
              dense
              v-model="ted.destino.digito_numero_conta"
              label="Digito"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="d-flex align-center">
          <v-col>
            <v-radio-group v-model="tipoPessoa" dense row>
              <v-radio label="Pessoa Física" dense value="1" selected>
              </v-radio>
              <v-radio label="Pessoa Jurídica" dense value="2"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="3">
            <v-text-field
              dense
              required
              @blur="checkDocumento"
              :label="tipoPessoa == 1 ? 'CPF' : 'CNPJ'"
              @keyup="
                ted.destino.documento_titular =
                  tipoPessoa == 1
                    ? $masks.cpf(ted.destino.documento_titular)
                    : $masks.cnpj(ted.destino.documento_titular)
              "
              v-model="ted.destino.documento_titular"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              dense
              required
              v-model="ted.destino.nome_titular"
              label="Nome Destinatário"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense class="d-flex justify-end">
          <v-col cols="3">
            <v-text-field
              dense
              label="Valor"
              required
              v-model="ted.valor"
              class="text-h4"
              @keyup="ted.valor = $masks.dinheiro(ted.valor)"
              prepend-icon="R$"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense class="d-flex justify-end">
          <v-col cols="8">
            <v-text-field dense v-model="ted.descricao" label="Descrição">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="d-flex align-end">
          <v-col cols="8" class="text-caption">
            <div
              style=" border-left:3px solid gray;margin-left:12px; padding:10px"
            >
              <span style="display: block">
                Este serviço destina-se a criar uma TED para outra conta.
              </span>
              <span>
                O horário para a realização de TED é de 09:00 - 16:30 horas.
              </span>
            </div>
          </v-col>

          <v-col
            ><v-btn color="btCancela" @click="$emit('cancela-transacao')" text>
              Cancelar
            </v-btn>
            <v-btn type="submit" color="btConfirma" text>
              Transferir
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </form>
    <DialogToken
      :showDialog="showDialogToken"
      :descricao="`Transferir R$ ${ted.valor} para ${ted.destino.nome_titular}`"
      @get-token="save"
      @cancela-token="cancelaToken"
    />
  </v-card>
</template>

<script>
import DialogToken from "@/components/Dialogs/DialogToken.vue";
import { mapMutations } from "vuex";
function initialState() {
  return {
    bancos: [],
    tipoPessoa: "1",
    ted: {
      destino: {
        banco: {},
        documento_titular: null
      },
      valor: null
    },
    showDialogToken: false
  };
}

export default {
  name: "TED",
  components: {
    DialogToken
  },
  props: {
    contaCorrente: String
  },
  data() {
    return initialState();
  },

  mounted() {
    this.getBancos();
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getBancos() {
      this.$axios.get("/public/banco").then(response => {
        this.bancos = response.data;
      });
    },
    save() {
      const data = {
        destino: {
          banco: {
            codigo_febraban: this.ted.destino.banco
          },
          nome_titular: this.ted.destino.nome_titular,
          documento_titular: this.ted.destino.documento_titular,
          agencia: this.ted.destino.agencia,
          digito_agencia: this.ted.destino.digito_agencia,
          numero_conta: this.ted.destino.numero_conta,
          digito_numero_conta: this.ted.destino.digito_numero_conta
        },
        valor: this.parseToFloat(this.ted.valor),
        descricao: this.ted.descricao
      };

      this.$axios
        .post(`/banco/conta/${this.contaCorrente}/ted`, data)
        .then(response => {
          this.ted.valor = "";
          this.ted.descricao = "";
          this.$emit("atualiza-saldo");
          this.closeDialogConfirm();
          this.$router.push({
            name: "SucessoTransacao",
            params: {
              comprovante: response.data
            }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Transação TED",
            text: error.response.data
          });
        });
    },

    confirmaTransacao() {
      //adicionar validação
      this.openDialogToken();
    },
    checkDocumento() {
      if (this.tipoPessoa == 1) {
        this.checkCPF();
      } else {
        this.checkCnpj();
      }
    },
    checkCnpj() {
      if (!this.ted.destino.documento_titular) return;

      if (!this.validarCNPJ(this.ted.destino.documento_titular)) {
        this.ted.destino.documento_titular = "";
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "CNPJ inválido."
        });
      }
    },
    checkCPF() {
      if (!this.ted.destino.documento_titular) return;
      const checkCNPJ = this.validaCPF(this.ted.destino.documento_titular);
      if (!checkCNPJ) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "CPF inválido."
        });
        this.ted.destino.documento_titular = "";
      }
    },
    openDialogToken() {
      this.showDialogToken = true;
    },
    cancelaToken() {
      this.showDialogToken = false;
    }
  }
};
</script>

<style></style>
