<template>
  <v-container fluid>
    <form @submit.prevent="getEventos(true)">
      <v-row>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.eventos"
            separator=","
            @add="getEventos(true)"
            @remove="getEventos(true)"
            placeholder="Filtro por nome"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                :to="{ name: 'addEvento' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar Evento</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
            :headers="headers"
            :items="eventos"
            :loading="carregando"
            loading-text="Carregando dados"
            :fixed-header="true"
            class="fill-height"
            width="500px"
            :options="{ itemsPerPage: pagination.limit }"
            hide-default-footer
          >

            <template v-slot:[`item.data_realizacao`]="{ item }">
              {{
                item.data_realizacao
                    | formatDate({ dateStyle: "short", timeStyle: "short" })
              }}
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-pagination
        v-model="pagination.page"
        :length="totalPaginas"
        :loading="carregando"
        @input="next"
        :total-visible="10"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  components: {
    Chips,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      eventos: [],
      carregando: false,
      headers: [
        {
          text: "Nome",
          align: "left",
          sortable: false,
          value: "nome"
        },
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status"
        },
        {
          text: "Data",
          align: "center",
          sortable: false,
          value: "data_realizacao",
        },
        {
          text: "Descrição",
          align: "left",
          sortable: true,
          value: "descricao"
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "acoes"
        }
      ],
      filtros: {
        eventos: [],
        status: this.$store.state.filtrosPlanos.status
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      status: [
        {
          texto: "Vendas abertas",
          valor: "VENDA_ABERTA"
        },
        {
          texto: "Preparando execução",
          valor: "PREPARACAO_EXECUCAO"
        },
        {
          texto: "Live",
          valor: "LIVE"
        },
        {
          texto: "Encerrado",
          valor: "ENCERRADO"
        },
        {
          texto: "Cancelado",
          valor: "CANCELADO"
        }
      ],
    };
  },
  mounted() {
    this.getEventos();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    async getEventos(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      // this.$store.commit("setFiltrosPlanos", {
      //   planos: this.filtros.planos,
      //   status: this.filtros.status,
      // });

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/evento/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              evento: this.filtros.eventos.map(p => p.trim()),
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.eventos = response.data.data;

          this.eventos = this.eventos.map((element) => {
            const status = this.status.filter((item) => {
              return item.valor === element.status
            });

            if(status.length === 1) {
              element.status = status[0].texto;
            }

            return element;
          });

          this.pagination.total = response.data.count;
          this.loadingSkeleton = false;
        });
    },
    calculaTotal(plano) {
      let total = 0.0;

      plano.itens.forEach(item => {
        total =
            parseFloat(total) +
            (parseFloat(item.quantidade * item.valor) - item.desconto);
      });

      return total.toFixed(2);
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getEventos();
    },

    goToConsultPage(item) {
      this.$router.push({
        name: "viewEvento",
        params: {
          idEvento: item.id
        }
      });
    },

    goToEditPage(item) {
      this.$router.push({
        name: "editEvento",
        params: {
          idEvento: item.id
        }
      });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.eventos.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  }
};
</script>

<style></style>
