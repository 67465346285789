<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row v-if="title != null">
          <v-col class="text-center">
            <span class="text-subtitle-1">{{ title }}</span>
          </v-col>
        </v-row>
        <DataTable
          :value="parcelas"
          sortField="parcela"
          :sortOrder="1"
          :paginator="true"
          :rows="5"
          selectionMode="single"
          @row-select="
            event => verExtratoDePagamentoParcela(event.data.pagamentos)
          "
        >
          <Column field="parcela" header="Parcela" />

          <Column field="status" header="Status">
            <template #body="slot">
              <v-chip
                :color="STATUS_PARCELA_CONTRATO[slot.data.status].cor"
                text-color="white"
                style="justify-content: center; min-width: 100px"
                class="ma-2 text-center"
              >
                {{ STATUS_PARCELA_CONTRATO[slot.data.status].texto }}
              </v-chip>
            </template>
          </Column>

          <Column field="data_vencimento" header="Vencimento">
            <template #body="slot">
              {{
                slot.data.data_vencimento | formatDate({ dateStyle: "short" })
              }}
            </template>
          </Column>

          <Column field="valor" header="Valor">
            <template #body="slot">
              R$
              {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>
          </Column>
          <Column field="total_pago" header="Total pago">
            <template #body="slot">
              R$
              {{
                slot.data.total_pago | formatMoney({ minimumFractionDigits: 2 })
              }}
            </template>
          </Column>
          <Column field="total_pendente" header="Pendente">
            <template #body="slot">
              R$
              {{
                slot.data.total_pendente
                  | formatMoney({ minimumFractionDigits: 2 })
              }}
            </template>
          </Column>
        </DataTable>
      </v-card-text>
    </v-card>
    <Dialog
      header="Extrato de pagamentos"
      :visible.sync="showDialog"
      :closable="true"
      modal
    >
      <ExtratoPagamentoContrato :pagamentos="extratoDePagamento" />
    </Dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";
import ExtratoPagamentoContrato from "@/components/Tables/ExtratoPagamentoContrato.vue";

export default {
  components: {
    DataTable,
    Column,
    Dialog,
    ExtratoPagamentoContrato
  },

  data() {
    return {
      showDialog: false,
      extratoDePagamento: null
    };
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  props: {
    parcelas: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    elevation: {
      type: Number
    }
  },
  methods: {
    verExtratoDePagamentoParcela(pagamentos) {
      if (!pagamentos || !pagamentos.length) {
        return;
      }
      this.extratoDePagamento = pagamentos;
      this.showDialog = true;
    }
  }
};
</script>

<style>
.dash-card {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);

  max-width: 250px;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 1rem !important;
}
.p-dialog {
  min-height: 250px !important;
}
@media (max-width: 900px) {
  .descricao_mobile {
    text-align: end;
    margin-left: 15px;
  }
}
</style>
