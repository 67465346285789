<template>
  <div>

    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>

      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@15"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="px-16 mt-3">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else fluid>
      <form @submit.prevent="handleSave">
        <v-row style="padding: 2rem;">
          <v-col cols="8">
            <v-row v-if="mode !== 'create' && evento.foto_url">
              <v-col>
                <v-card elevation="0" outlined height="210">
                  <v-img
                    class="grey lighten-2"
                    :src="evento.foto_url"
                    height="100%"
                    contain
                  ></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n4">
                <v-file-input
                  label="Carregar imagem"
                  accept="image/jpeg"
                  truncate-length="20"
                  v-model="imagem"
                ></v-file-input>
              </v-col>
              <v-col>
                <v-text-field
                  :readonly="mode == 'view'"
                  required
                  dense
                  label="Nome"
                  v-model="evento.nome"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :readonly="mode == 'view'"
                  required
                  dense
                  :items="status"
                  item-text="texto"
                  item-value="valor"
                  label="Status"
                  v-model="evento.status"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  :readonly="mode == 'view'"
                  dense
                  v-model="evento.data_realizacao"
                  @keyup="evento.data_realizacao = $masks.data(evento.data_realizacao)"
                  label="Data do Evento"
                  maxlength="10"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  rows="2"
                  counter="150"
                  :readonly="mode == 'view'"
                  label="Descrição"
                  hint="Detalhamento do evento"
                  v-model="evento.descricao"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-end mr-15">
                <v-btn
                  dark
                  color="primary"
                  v-if="mode != 'view'"
                  type="submit"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </form>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CryptoJS from "crypto-js";

export default {
  props: {
    mode: String,
    idEvento: String,
    backRoute: String,
    idEmpresa: String
  },
  data() {
    return {
      loadingSkeleton: false,
      imagem: null,
      status: [
        {
          texto: "Vendas abertas",
          valor: "VENDA_ABERTA"
        },
        {
          texto: "Preparando execução",
          valor: "PREPARACAO_EXECUCAO"
        },
        {
          texto: "Live",
          valor: "LIVE"
        },
        {
          texto: "Encerrado",
          valor: "ENCERRADO"
        },
        {
          texto: "Cancelado",
          valor: "CANCELADO"
        }
      ],
      evento: {
        status: null,
        descricao: null,
        data_realizacao: null,
        nome: null

      }
    };
  },
  mounted() {
    if (this.mode != "create") this.getEvento();
  },
  computed: {
    ...mapState(["empresaAtual", "user"])
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getEvento() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/evento/${this.idEvento}`)
        .then(response => {
          this.evento = response.data;

          const data = this.$options.filters.formatDate(
              this.evento.data_realizacao
          );
          if (data) this.evento.data_realizacao = data;

          this.loadingSkeleton = false;

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Evento",
            text: error.response.data
          });
        });
    },
    async handleSave() {
      this.mode === "create" ? await this.save() : await this.edit();
    },
    async upload(evento) {
      if (!this.imagem) {
        return;
      }

      const boundaryTest = CryptoJS.SHA1(this.imagem.name).toString();
      const formData = new FormData();
      formData.append("file", this.imagem);

      await this.$axios
        .post(
          `/evento/${evento.id}/empresa/${this.empresaAtual.id}/upload-imagem`,
          formData,
          {
          headers: {
            "Content-Type": "multipart/form-data",
            "file-token": boundaryTest
          }
        }
        )
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: this.mode === "create" ? `Evento cadastrado com sucesso.` : `Evento editado com sucesso.`,
            life: 2000
          });

          this.$router.push({
            name: "Eventos",
          });

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Envio da imagen",
            text: error.response.data
          });
        });
    },
    async edit() {
      const eventoObj = { ...this.evento };

      if(eventoObj.data_realizacao) {
        eventoObj.data_realizacao = eventoObj.data_realizacao
            .split("/")
            .reverse()
            .join("-");
      }
      eventoObj.empresa = { id: this.empresaAtual.id };
      eventoObj.id = this.idEvento;

      await this.$axios
        .post("/evento", eventoObj)
        .then((response) => {

          if(this.imagem) {
            this.upload(response.data);
          } else {
            this.$toast.add({
              severity: "success",
              summary: `Evento editado com sucesso.`,
              life: 2000
            });

            this.$router.push({
              name: "Eventos",
            });

          }

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Editar Evento",
            text: error.response.data
          });
        });
    },
    async save() {
      const eventoObj = { ...this.evento };

      if(eventoObj.data_realizacao) {
        eventoObj.data_realizacao = eventoObj.data_realizacao
            .split("/")
            .reverse()
            .join("-");
      }
      eventoObj.empresa = { id: this.empresaAtual.id };

      await this.$axios
        .post("/evento", eventoObj)
        .then((response) => {

          if(this.imagem) {
            this.upload(response.data);
          } else {
            this.$toast.add({
              severity: "success",
              summary: `Evento cadastrado com sucesso.`,
              life: 2000
            });

            this.$router.push({
              name: "Eventos",
            });

          }

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Salvar Evento",
            text: error.response.data
          });
        });
    },
  }
};
</script>

<style scoped>
@media (max-width: 900px) {
  .col,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    flex: none;
    max-width: 25rem;
  }
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
