<template>
  <v-container fluid>
    <v-row>

      <v-col cols="12">
        <v-row class="vendas-tab" dense>
          <v-col>
            <VendasPorEstandeCardTotalCarga :valor="totalCarga"/>
          </v-col>
          <v-col>
            <VendasPorEstandeCardTotalConsumos :valor="totalConsumos" />
          </v-col>
          <v-col>
            <VendasPorEstandeCardSaldoCarteiras :valor="saldoDisponivel" />
          </v-col>
          <v-col>
            <VendasPorEstandeCardTotalFormaPagamento :valores="vendasPorFormaDePagamento" />
          </v-col>

        </v-row>
      </v-col>

      <v-col cols="12">
        <form @submit.prevent="buscar()">

          <div class="d-flex justify-start">
            <div class="espacado d-flex justify-start">
              <Calendar v-model="dataIntervalo" selectionMode="range"
                        @date-select="buscar()" :showIcon="true"
                        dateFormat="dd/mm/yy"/>
            </div>
            <div class="espacado">
              <Dropdown v-model="eventoSelecionado"
                        :options="eventos" optionLabel="nome"
                        placeholder="Selecione o evento"
                        filterPlaceholder="Buscar evento"
                        @change="buscar()"
                        :filter="true" />
            </div>
            <div class="espacado">
              <MultiSelect
                v-model="filtros.estandes"
                :options="estandes"
                @change="getVendas()"
                optionLabel="name"
                placeholder="Filtro por estande"
                display="chip"
              />
            </div>
            <div class="espacado">
              <BotaoBuscarListas @buscar="buscar()" />
            </div>
          </div>

        </form>
      </v-col>

    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton" class="px-16 mt-10">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="vendas.length > 0" >
      <v-col class="custom-table rounded">

        <v-data-table group-by="estande.nome" :items="vendas" hide-default-header hide-default-footer disable-pagination ref="table">
          <!-- group custom header -->
          <template #[`group.header`]="{ items, toggle, isOpen }">

            <td @click="toggle" class="titulo white">
<!--              <i class="fas fa-arrow-right" style="font-size: 12px" v-if="!isOpen && $vuetify.breakpoint.smAndUp"/>-->
<!--              <i class="fas fa-arrow-down" style="font-size: 12px" v-else />-->
              <v-icon style="font-size: 12px" v-if="!isOpen && $vuetify.breakpoint.smAndUp"> mdi-arrow-right </v-icon>
              <v-icon style="font-size: 12px" v-else> mdi-arrow-down </v-icon>
            </td>

            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Estande</span>
              <span class="green--text" style="display: block;">
                {{ items[0].estande.nome }}
              </span>
            </td>

            <td @click="toggle" class="white titulo">
              <span class="text-caption">Qtd. Vendida</span>
              <span class="green--text" style="display: block;">
                {{ items[0].total_itens_vendido }}
              </span>
            </td>

            <td @click="toggle"  class="white titulo">
              <span class="text-caption">Total</span>
              <span class="green--text" style="display: block;">
                R$ {{ items[0].total_vendas | formatMoney({ minimumFractionDigits: 2, }) }}
              </span>
            </td>

          </template>
          <!------->
          <template v-slot:[`item`]="{ index, item }" v-if="$vuetify.breakpoint.smAndUp">

            <tr v-if="index === 0" style="cursor: default">
              <th>SKU</th>
              <th>Produto</th>
              <th>Valor unid.</th>
              <th class="text-center">Itens vendidos</th>
              <th class="text-center">Total</th>
            </tr>
            <tr v-for="(produto) in item.itens" :key="item.estande.id+'_'+produto.id" style="cursor: default">
              <td >{{ produto.sku }}</td>
              <td >{{ produto.nome }}</td>
              <td >R$ {{ (produto.total_vendas / produto.qtd_vendido) | formatMoney({ minimumFractionDigits: 2, }) }}</td>
              <td class="text-center">{{ produto.qtd_vendido }}</td>
              <td class="text-center">R$ {{ produto.total_vendas | formatMoney({ minimumFractionDigits: 2, }) }}</td>
            </tr>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Calendar from 'primevue/calendar';
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import moment from 'moment';
import VendasPorEstandeCardSaldoCarteiras from "@/components/Cards/VendasPorEstandeCardSaldoCarteiras.vue";
import VendasPorEstandeCardTotalCarga from "@/components/Cards/VendasPorEstandeCardTotalCarga.vue";
import VendasPorEstandeCardTotalConsumos from "@/components/Cards/VendasPorEstandeCardTotalConsumos.vue";
import VendasPorEstandeCardTotalFormaPagamento from "@/components/Cards/VendasPorEstandeCardTotalFormaPagamento.vue";
import MultiSelect from "primevue/multiselect";
import Dropdown from 'primevue/dropdown';

export default {
  name: "VendasPorEstande",
  components:{
    MultiSelect,
    Dropdown,
    Calendar,
    BotaoBuscarListas,
    VendasPorEstandeCardTotalFormaPagamento,
    VendasPorEstandeCardTotalConsumos,
    VendasPorEstandeCardTotalCarga,
    VendasPorEstandeCardSaldoCarteiras
  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      dataInicio: null,
      dataFim: null,
      totalCarga: 0.00,
      totalConsumos: 0.00,
      totalTarifas: 0.00,
      saldoDisponivel: 0.00,
      eventoSelecionado: null,
      vendasPorFormaDePagamento: {
        "DEBITO": 0.00,
        "CREDITO": 0.00,
        "PIX": 0.00,
        "DINHEIRO": 0.00
      },
      vendas: [],
      eventos: [],
      estandes: [],
      filtros: {
        estandes: []
      }
    };
  },
  mounted() {
    this.getEventos();
    this.getEstandes();
  },

  methods: {
    buscar(){
      this.getVendas();
      this.getToTalOperacoesPorTipo();
      this.getSaldoDisponivelEvento();
      this.getToTalPorFormaPagamento();
    },
    getEventos() {
      return this.$axios
        .get(`/evento/empresa/${this.empresaAtual.id}/listar`,{
          params: {
            first: 0,
            limit: 900000,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.eventos = response.data.data;
        });
    },
    getEstandes() {
      return this.$axios
        .get(`/estande/empresa/${this.empresaAtual.id}/listar`,{
          params: {
            first: 0,
            limit: 900000,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.estandes = response.data.data;

          this.estandes = this.estandes.map((element) => {
            return { name: element.nome, code: element.id }
          })
        });
    },
    async getVendas() {

      //this.loadingSkeleton = true;

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      if(!this.eventoSelecionado) return;

      if(this.dataIntervalo[0]){
        this.dataInicio = moment(this.dataIntervalo[0]).format('YYYY-MM-DD');
      }

      if(this.dataIntervalo[1]){
        this.dataFim = moment(this.dataIntervalo[1]).format('YYYY-MM-DD');
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      return this.$axios.get(`/relatorio/venda/empresa/${this.empresaAtual.id}/evento/${this.eventoSelecionado.id}/vendas-estande`,
          {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
          },
        }
      )
        .then((response) => {
          this.vendas = response.data;
          if(this.filtros.estandes.length){
            console.log(this.filtros.estandes)
            this.vendas = this.vendas.filter(v =>
              this.filtros.estandes.some(e => e.code === v.estande.id)
            );
          }
          //this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data
          });
        });
    },
    getToTalOperacoesPorTipo() {
      if(!this.eventoSelecionado) return

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      if(this.dataIntervalo[0]){
        this.dataInicio = moment(this.dataIntervalo[0]).format('YYYY-MM-DD');
      }

      if(this.dataIntervalo[1]){
        this.dataFim = moment(this.dataIntervalo[1]).format('YYYY-MM-DD');
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      this.$axios.get(`/relatorio/cashless/empresa/${this.empresaAtual.id}/evento/${this.eventoSelecionado.id}/total-operacoes-tipo`,
        {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
          },
        })
          .then(response => {
            this.totalCarga = response.data.CARGA ? response.data.CARGA: 0.00;
            this.totalConsumos = Math.abs(response.data.CONSUMO? response.data.CONSUMO:0.00);
            this.totalTarifas = Math.abs(response.data.TARIFA? response.data.TARIFA:0.00);

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar Operações por tipo",
              text: error.response.data
            });
          });
    },
    getSaldoDisponivelEvento() {
      if(!this.eventoSelecionado) return

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      if(this.dataIntervalo[0]){
        this.dataInicio = moment(this.dataIntervalo[0]).format('YYYY-MM-DD');
      }

      if(this.dataIntervalo[1]){
        this.dataFim = moment(this.dataIntervalo[1]).format('YYYY-MM-DD');
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      this.$axios.get(`/relatorio/cashless/empresa/${this.empresaAtual.id}/evento/${this.eventoSelecionado.id}/saldo-disponivel`)
          .then(response => {
            this.saldoDisponivel = response.data.saldo ? response.data.saldo: 0.00;

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar Operações por tipo",
              text: error.response.data
            });
          });
    },
    getToTalPorFormaPagamento() {
      if(!this.eventoSelecionado) return

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      if(this.dataIntervalo[0]){
        this.dataInicio = moment(this.dataIntervalo[0]).format('YYYY-MM-DD');
      }

      if(this.dataIntervalo[1]){
        this.dataFim = moment(this.dataIntervalo[1]).format('YYYY-MM-DD');
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      this.$axios.get(`/relatorio/cashless/empresa/${this.empresaAtual.id}/evento/${this.eventoSelecionado.id}/total-operacoes-forma-pagamento`,
        {
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
          },
        })
          .then(response => {
            this.vendasPorFormaDePagamento = {
              "DEBITO" : response.data.DEBITO ? response.data.DEBITO: 0.00,
              "CREDITO": response.data.CREDITO ? response.data.CREDITO: 0.00,
              "PIX": response.data.PIX ? response.data.PIX: 0.00,
              "DINHEIRO": response.data.DINHEIRO ? response.data.DINHEIRO: 0.00,
            }

          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Buscar Operações por tipo",
              text: error.response.data
            });
          });
    },
  },
  computed: {
    ...mapState(["empresaAtual","user"])
  },
};
</script>

<style scoped>
.titulo{
  font-size: 1.2rem !important;
}
.dados-cliente{
  font-size: 0.8rem !important;
}
.v-row-group__header td {
  background-color: #f5f2f2 !important;
  /* color: #495057; */
}

.export-icon {
  font-size: 25px;
  transition: all 0.2s linear;
}

.export-icon:hover {
  transform: scale(1.3);
}

.vendas-tab {
  animation: moveDown 0.6s ease-in-out forwards;
}


@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }


  tr {
    max-width: 100%;
  }
  td {
    display: flex;
    justify-content: space-between;
  }

}
</style>
