<template>
  <v-card>
    <v-subheader class="text-subtitle-1 font-weight-bold">
      Pagamento de Contas
    </v-subheader>
    <form @submit.prevent="confirmaPagamento">
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col cols="8">
            <v-text-field dense v-model="searchBoleto" label="Código de Barras">
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn text small @click="getBoleto">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense v-if="boleto.conta_corrente_id">
          <v-col cols="8">
            <v-row dense>
              <v-col class="pa-0 text-subtitle-1 font-weight-bold " cols="4"
                >Código de Barras:</v-col
              >
              <v-col class="pa-0 text-caption font-weight-bold">{{
                boleto.codigo_barras
              }}</v-col>
            </v-row>

            <v-row dense v-if="boleto.nome_beneficiario">
              <v-col class="pa-0 text-subtitle-1 font-weight-bold " cols="4 "
                >Beneficiário:</v-col
              >
              <v-col class="pa-0 text-subtitle-1 font-weight-bold ">{{
                boleto.nome_beneficiario
              }}</v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0 text-subtitle-1 font-weight-bold " cols="4"
                >Documento Beneficiário:</v-col
              >
              <v-col class="pa-0 text-subtitle-1 font-weight-bold ">{{
                $masks.cnpj(boleto.documento_beneficiario)
              }}</v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field dense label="Descrição"> </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-2">
                <div style=" border-left:3px solid gray;padding:10px">
                  <span class="text-caption font-weight-bold">
                    {{ boleto.recomendacao_recebimento }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row
              dense
              class="text-body-2 font-weight-bold d-flex justify-end"
            >
              <v-col cols="3" class="text-end">
                Valor
              </v-col>
              <v-col cols="5" class="text-end">
                R$
                {{ boleto.valor | formatMoney({ minimumFractionDigits: 2 }) }}
              </v-col>
            </v-row>

            <v-row
              dense
              class="text-body-2 font-weight-bold d-flex justify-end"
            >
              <v-col cols="5" class="text-end">
                Juros
              </v-col>
              <v-col cols="5" class="text-end">
                R$
                {{
                  boleto.valor_juros_calculado
                    | formatMoney({ minimumFractionDigits: 2 })
                }}
              </v-col>
            </v-row>
            <v-row
              dense
              class="text-body-2 font-weight-bold d-flex justify-end"
            >
              <v-col cols="3" class="text-end">
                Multas
              </v-col>
              <v-col cols="5" class="text-end">
                R$
                {{
                  boleto.valor_multa_calculado
                    | formatMoney({ minimumFractionDigits: 2 })
                }}
              </v-col>
            </v-row>

            <v-row
              dense
              class="text-body-2 font-weight-bold d-flex justify-end align-center mt-3"
            >
              <v-col class="text-end" cols="10">
                <v-text-field
                  dense
                  label="Valor"
                  v-model="boleto.valor_total"
                  required
                  @keyup="
                    boleto.valor_total = $masks.dinheiro(boleto.valor_total)
                  "
                  prepend-icon="R$"
                  class=" align-input text-h4"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="btCancela" @click="$emit('cancela-transacao')" text>
          Cancelar
        </v-btn>
        <v-btn type="submit" color="btConfirma" text>
          Pagar
        </v-btn>
      </v-card-actions>
    </form>
    <DialogToken
      :showDialog="showDialogToken"
      :descricao="
        `Pagamento R$ ${boleto.valor_total} para ${boleto.nome_beneficiario}`
      "
      @get-token="pagar"
      @cancela-token="cancelaToken"
    />
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import DialogToken from "@/components/Dialogs/DialogToken.vue";
function initialState() {
  return {
    boleto: {},
    searchBoleto: null,
    showDialogToken: false
  };
}
export default {
  name: "PagtoConta",
  components: {
    DialogToken
  },
  props: {
    contaCorrente: String
  },
  data() {
    return initialState();
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getBoleto() {
      if (!this.searchBoleto) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Informe um código de barras para pesquisar."
        });
        return;
      }
      this.$axios(
        `/banco/conta/${
          this.contaCorrente
        }/boleto/consulta/${this.sanitizeString(this.searchBoleto)}`
      )
        .then(response => {
          this.boleto = response.data;
          this.boleto.valor_total = this.$masks.dinheiro(
            this.boleto.valor_total.toFixed(2)
          );
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Boleto",
            text: error.response.data
          });
        });
    },
    confirmaPagamento() {
      if (!this.boleto.conta_corrente_id) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Informe um código de barras antes de iniciar o pagamento"
        });
        return;
      }
      this.openDialogToken();
    },
    pagar() {
      const data = {
        codigo_barras: this.boleto.codigo_barras,
        valor_pagamento: this.parseToFloat(this.boleto.valor_total),
        descricao: this.descricao
      };
      this.$axios
        .post(`/banco/conta/${this.contaCorrente}/boleto/pagar`, data)
        .then(response => {
          this.$emit("atualiza-saldo");
          this.closeDialogConfirm();
          this.$router.push({
            name: "SucessoPagto",
            params: {
              comprovante: response.data
            }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Pagamento",
            text: error.response.data
          });
        });
    },
    openDialogToken() {
      this.showDialogToken = true;
    },
    cancelaToken() {
      this.showDialogToken = false;
    }
  }
};
</script>

<style scoped>
.align-input >>> input {
  text-align: right;
}
</style>
