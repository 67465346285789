import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Bank from "@/views/Bank/Bank.vue";
import TEF from "@/views/Bank/TEF.vue";
import TED from "@/views/Bank/TED.vue";
import Extrato from "@/views/Bank/Extrato.vue";
import SucessoTransacao from "@/views/Bank/SucessoTransacao.vue";
import PagtoContas from "@/views/Bank/PagtoContas.vue";
import SucessoPagto from "@/views/Bank/SucessoPagto.vue";

const empresaRoutes: RouteConfig = {
  path: "/relatorios/bank",
  component: GenericComponent,

  children: [
    {
      path: "/",
      component: Bank,

      meta: {
        breadCrumb: [
          {
            text: "Bank"
          }
        ]
      },
      children:[
        {
          path:"/",
          redirect:"extrato"
        },
        {
          path:"extrato",
          component: Extrato,
          name:"Extrato",

          meta:{
            breadCrumb: [
              {
                text: "Relatórios"
              },
              {
                text:"Extrato conta"
              }
            ]
          }
        },
        {
          path:"ted",
          component: TED,
          name:"TED",
          meta:{
            breadCrumb:[
              {
                text:"Bank"
              },
              {
                text:"TEF"
              }
            ]
          }
        },
        {
          path:"tef",
          component: TEF,
          name:"TEF",
          meta:{
            breadCrumb:[
              {
                text:"Bank"
              },
              {
                text:"TEF"
              }
            ]
          }
        },
        {
          path:"pagamentos",
          component: PagtoContas,
          name:"PagtoContas",
          meta:{
            breadCrumb:[
              {
                text:"Bank"
              },
              {
                text:"Pagamentos"
              }
            ]
          }
        },
        {
          path:"sucesso-pagto",
          component: SucessoPagto,
          name:"SucessoPagto",
          props: route => ({ comprovante: route.params.comprovante }),
          meta:{
            breadCrumb:[
              {
                text:"Bank"
              },
              {
                text:"Sucessso Transação"
              }
            ]
          }
        },
        {
          path:"sucesso-transacao",
          component: SucessoTransacao,
          name:"SucessoTransacao",
          props: route => ({ comprovante: route.params.comprovante }),
          meta:{
            breadCrumb:[
              {
                text:"Bank"
              },
              {
                text:"Sucessso Transação"
              }
            ]
          }
        }
      ]
    },

  ]
};

export default empresaRoutes;
