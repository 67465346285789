<template>
  <v-card flat>
    <v-card-text>
      <v-row v-if="title != null">
        <v-col class="text-center">
          <span class="text-subtitle-1">{{ title }}</span>
        </v-col>
      </v-row>
      <DataTable
        :value="transacoesPaginadas"
        responsiveLayout="stack"
        sortField="data_criacao"
        :sortOrder="-1"
        breakpoint="960px"
        scrollHeight="flex-row"
      >
        <Column field="id" header="ID"></Column>

        <Column field="data_criacao" header="Data">
          <template #body="slot">
            {{
              slot.data.data_criacao
                | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          </template>
        </Column>

        <Column field="tipo" header="Tipo">
          <template #body="slot">
            {{ TIPO_TRANSACAO[slot.data.tipo] }}
          </template>
        </Column>

        <Column field="valor" header="Valor">
          <template #body="slot">
            {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 }) }}
          </template>
        </Column>

        <Column field="status" header="Status">
          <template #body="slot">
            <v-chip
              :color="statusStyle(slot.data.status)"
              text-color="white"
              style="justify-content: center; min-width: 100px"
              class="ma-2 text-center"
            >
              {{ slot.data.status.toLowerCase() }}
            </v-chip>
          </template>
        </Column>

        <Column header="Ver">
          <template #body="slot">
            <v-btn
              icon
              :to="{
                name: 'DetalheTransacao',
                params: {
                  idTransacao: slot.data.id.toString(),
                  callback: 'dashboard'
                }
              }"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </Column>
      </DataTable>
      <v-divider></v-divider>
      <v-pagination
        class="mt-5"
        v-model="pagination.page"
        :length="totalPaginas"
        @input="setPagina"
        :total-visible="10"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      pagination: {
        page: 1,
        total: this.transacoes.length,
        limit: 5,
        first: 0
      },
    }
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    transacoesPaginadas() {
      const startIndex = (this.pagination.page - 1) * this.pagination.limit;
      const endIndex = startIndex + this.pagination.limit;
      return this.transacoes.slice(startIndex, endIndex);
    },
  },
  props: {
    transacoes: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    elevation: {
      type: Number
    }
  },
  methods: {
    setPagina(page) {
      this.pagination.page = page;
    }
  }
};
</script>

<style>
.dash-card {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);

  max-width: 250px;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem 0rem !important;
}
</style>
