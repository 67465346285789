<template>
  <v-dialog v-model="showDialog" dense persistent max-width="1200">
    <v-card>
      <form @submit.prevent="handleSave">
        <div class="d-flex">
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" class="mt-2 mr-2" icon @click="cancelar">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-card outlined class="px-3 py-3 mt-n5">
            <v-row dense class="mx-auto">
              <v-col cols="2">
                <v-select
                  dense
                  :items="TIPO_PESSOA"
                  item-text="texto"
                  item-value="valor"
                  v-model="socio.tipo"
                  :readonly="mode == 'view'"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  v-if="socio.tipo === 'PESSOA_FISICA'"
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.cpf"
                  @keyup="socio.cpf = $masks.cpf(socio.cpf)"
                  @blur="checkCPF"
                  maxlength="14"
                  label="CPF"
                >
                </v-text-field>
                <v-text-field
                  v-else
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.cnpj"
                  @keyup="socio.cnpj = $masks.cnpj(socio.cnpj)"
                  maxlength="18"
                  label="CNPJ"
                  @blur="checkCNPJ"
                >
                </v-text-field>
              </v-col>
              <v-col v-if="socio.tipo === 'PESSOA_JURIDICA'">
                <v-text-field
                  required
                  :readonly="mode == 'view'"
                  dense
                  v-model="socio.razao_social"
                  label="Razão social"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.nome"
                  :label="socio.tipo === 'PESSOA_JURIDICA' ? 'Nome Fantasia' : 'Nome'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="2" v-if="socio.tipo === 'PESSOA_JURIDICA'">
                <v-text-field
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.rg"
                  label="IE"
                  maxlength="15"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.data_nascimento"
                  @keyup="socio.data_nascimento = $masks.data(socio.data_nascimento)"
                  label="Nascimento/Fundação"
                  required
                  maxlength="10"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  required
                  dense
                  ref="telefone_celular"
                  @keyup="socio.telefone_celular = $masks.telefone(socio.telefone_celular)"
                  maxlength="13"
                  :readonly="mode == 'view'"
                  v-model="socio.telefone_celular"
                  label="Telefone Celular"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.email"
                  label="Email"
                  type="email"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                    dense
                    :readonly="mode == 'view'"
                    v-model="socio.renda_mensal"
                    @keyup="
                      socio.renda_mensal = $masks.dinheiro(socio.renda_mensal)
                    "
                    label="Renda Mensal"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="2" class="d-flex">
                <v-text-field
                  required
                  dense
                  maxlength="9"
                  ref="cep"
                  @keyup="socio.cep = $masks.cep(socio.cep)"
                  :readonly="mode == 'view'"
                  v-model="socio.cep"
                  label="Cep"
                >
                </v-text-field>
                <v-btn @click="consultaCEP" text small>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.endereco"
                  label="Endereço"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  required
                  dense
                  ref="numero"
                  :readonly="mode == 'view'"
                  v-model="socio.numero"
                  label="Número"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.complemento"
                  label="Complemento"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="4">
                <v-text-field
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.bairro"
                  label="Bairro"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.cidade"
                  label="Cidade"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  required
                  dense
                  v-model="socio.uf"
                  label="UF"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-switch
                  :readonly="mode == 'view'"
                  class="ma-0"
                  v-model="socio.pep"
                  label="Publicamente Exposto?"
                ></v-switch>
              </v-col>

            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.nome_mae"
                  label="Nome da Mãe">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-switch
                  :readonly="mode == 'view'"
                  class="ma-0 mr-3"
                  v-model="socio.administrador"
                  label="Administrador?"
                ></v-switch>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  required
                  dense
                  :readonly="mode == 'view'"
                  v-model="socio.percentual"
                  @keyup="
                      socio.percentual = $masks.dinheiro(socio.percentual)
                    "
                  label="Percentual"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row dense class="mt-n5">
              <v-col>
                <v-card-subtitle class="subtitle-text text-subtitle-2"
                >Escolha o documento</v-card-subtitle
                >
                <v-card-text class="mt-n5 text-subtitle-2">
                  <v-radio-group
                      @change="handleChangeTipoDocumento"
                      v-model="tipoDocumento"
                      row
                  >
                    <v-radio label="RG" value="RG"></v-radio>
                    <v-radio label="CNH" value="CNH"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-list class="mt-n10">
                  <v-list-item>
                    <v-list-item-content>
                      <v-container>
                        <v-row class="align-center" dense v-for="(documento, index) of doctosTipoSocio"  :key="documento">
                          <v-col cols="3" v-if="mode !== 'view'">
                            <v-file-input @change="(e) => getDoc(e, documento)" label="Arquivo" dense :accept="TIPO_DOCUMENTO[documento].formato"
                                          truncate-length="20" v-model="currentDocument[index]">
                            </v-file-input>
                          </v-col>
                          <v-col cols="3" class="text-subtitle font-weight-bold d-flex justify-space-between">
                            {{ TIPO_DOCUMENTO[documento].texto }} em {{ TIPO_DOCUMENTO[documento].tipo }}
<!--                            <i v-if="getSocioDocto(documento)" class="fa-style fas fa-check green&#45;&#45;text mr-3"></i>-->
                            <v-icon v-if="getSocioDocto(documento)" class="green--text mr-3"> mdi-check </v-icon>
                          </v-col>
                          <v-col cols="4">
                            {{ getSocioDocto(documento) ? "": "Documento Pendente"}}
                            <v-btn icon fab small v-if="getSocioDocto(documento)" :href="getSocioDocto(documento).url" target="_blank">
<!--                              <i class="fa-style fas fa-search"></i>-->
                              <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="btConfirma" text type="submit" v-if="mode !== 'view' && user.permissoes.adm_empresa" > Salvar </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import CryptoJS from "crypto-js";

function initialState() {
  return {
    socio: {
      id: null,
      cpf: null,
      cnpj: null,
      rg:null,
      rg_data_expedicao: null,
      rg_ssp: null,
      tipo: "PESSOA_FISICA",
      data_nascimento: null,
      telefone_celular: null,
      renda_mensal: 0,
      percentual: 0,
      numero: null,
      documentos: [],
      administrador: false,
      pep: false,
      cep: null,
      nome: null,
      razao_social: null
    },
    step: 1,
    tipoDocumento: "RG",
    file: [],
    tab: null,
    escondeBtnCancelar: false,
    dataAniversario: null,
    calendarioAniversario: false,
    currentDocument: [],
    documentType: {},
    doctosTipoSocio: [
      "RG_FRENTE",
      "RG_VERSO",
      "PAGINA_PROCURACAO"
    ],
  };
}
export default {
  props: {
    showDialog: Boolean,
    empresaId: {
      type: String,
      required: true,
    },
    id: String,
    mode: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["user"]),
    title() {
      let title;
      switch (this.mode) {
        case "create":
          title = "Adicionar Sócio";
          break;

        case "edit":
          title = "Editar Sócio";
          break;
        case "view":
          title = "Consultar Sócio";
          break;
      }
      return title;
    },
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showDialog(newValue, oldValue) {
      if (newValue && this.mode !== "create") this.getSocio();
    },
  },
  data() {
    return initialState();
  },

  methods: {
    ...mapMutations(["openDialogMessage"]),
    getSocio() {
      this.$axios
        .get(`/empresa/socio/${this.id}`)
        .then((response) => {
          this.socio = Object.assign(this.socio, response.data);
          this.socio.telefone_celular = this.$masks.telefone(
            this.socio.telefone_celular
          );
          this.socio.renda_mensal = this.$masks.dinheiro(
            this.socio.renda_mensal
          );

          if(this.socio.data_nascimento){
            this.socio.data_nascimento = this.$options.filters.formatDate(
              this.socio.data_nascimento
            );
          }

          if(this.socio.rg_data_expedicao){
            this.socio.rg_data_expedicao = this.$options.filters.formatDate(
              this.socio.rg_data_expedicao
            );
          }


        })
        .catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Sócio",
            text: error.response.data,
          });
        });
    },
    handleSave() {
      const socioObj = { ...this.socio };
      socioObj.data_nascimento = socioObj.data_nascimento.split("/").reverse().join("-");
      socioObj.empresa_id = this.empresaId;
      socioObj.renda_mensal = this.parseToFloat(socioObj.renda_mensal);
      socioObj.percentual = this.parseToFloat(socioObj.percentual);
      socioObj.rg_data_expedicao = socioObj.rg_data_expedicao?socioObj.rg_data_expedicao.split("/").reverse().join("-"):null;

      delete socioObj.partner_id;
      delete socioObj.documentos;
      delete socioObj.data_atualizacao;

      if (this.id) this.socio.id = this.id;
      this.mode === "create" && !this.socio.id
        ? this.save(socioObj)
        : this.edit(socioObj);
    },
    save(socioObj) {
      this.$axios
        .post("/empresa/socio", socioObj)
        .then((response) => {
         this.escondeBtnCancelar = true;
          this.$toast.add({
            severity: "success",
            summary: `Sócio adicionado com sucesso!`,
            life: 2000
          });

          this.socio.id = response.data.id;

          //Verificando se tem documento enviado para salvar.
          if(this.file.length > 0) {
            this.addDocumentos(response.data);
          } else {
            this.$emit("salva-dados", response.data);
          }

          this.resetDados();
        })
        .catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar Sócio",
            text: error.response.data,
          });
        });
    },

    edit(socioObj) {
      this.$axios
        .put("/empresa/socio/", socioObj)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: `Sócio atualizado com sucesso!`,
            life: 2000
          });

          //Verificando se tem documento enviado para salvar.
          if(this.file.length > 0) {
            this.addDocumentos(response.data);
          } else {
            this.$emit("salva-dados", response.data);
          }

          this.resetDados();
        })
        .catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Atualizar Sócio",
            text: error.response.data,
          });
        });
    },
    consultaCEP() {
      if (!this.socio.cep) return;

      this.socio.cep = this.socio.cep.replaceAll('.','');

      this.getCEP(this.socio.cep)
        .then((response) => {
          this.socio = Object.assign({}, this.socio, response);
          this.$refs["numero"].focus();
        })
        .catch(() => {
          this.openDialogMessage({
            type: "error",
            title: "Erro Validação",
            text: "CEP não encontrado",
          });
          this.$refs["cep"].focus();
        });
    },
    checkCNPJ() {
      if (!this.socio.cnpj) return;
      const checkCNPJ = this.validarCNPJ(this.socio.cnpj);
      if (!checkCNPJ) {
        this.openDialogMessage({
          type: "error",
          title: "Erro Validação",
          text: "CNPJ inválido.",
        });
        this.socio.cnpj = "";
      }
    },
    checkCPF() {
      if (!this.socio.cpf) return;
      const checkCNPJ = this.validaCPF(this.socio.cpf);
      if (!checkCNPJ) {
        this.openDialogMessage({
          type: "error",
          title: "Erro Validação",
          text: "CPF inválido",
        });
        this.socio.cpf = "";
      }
    },

    cancelar() {
      this.resetDados();
      this.$emit("cancela-dialog");
    },
    resetDados() {
      Object.assign(this.$data, initialState());
    },
    async validaForm(currentTab) {
      this.$nextTick(() => {
        if (!document.querySelector("#form").checkValidity()) {
          document.querySelector("form").reportValidity();

          this.tab = this.previousTab;
        } else {
          this.previousTab = currentTab;
        }
      });
    },
    addDocumentos(socio) {
      for(let i = 0; i < this.file.length; i++) {
        const tpDocumento = this.file[i].documento;
        const document = this.file[i].file;
        if(this.TIPO_DOCUMENTO[tpDocumento].formato !== document.type) {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Adicionar Documento",
            text: "Formato de arquivo inválido para esse tipo de documento",
          });

          return;
        }

        const boundaryTest = CryptoJS.SHA1(document.name).toString();

        const formData = new FormData();
        formData.append("file", document);

        this.$axios
          .post(
            `/empresa/${this.empresaId}/socio/${socio.id}/documento?tipo=${tpDocumento}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "file-token": boundaryTest,
              },
            }
          )
          .then((response) => {
            this.socio.documentos = response.data;
            socio.documentos = response.data;
          })
          .catch((error) => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Adicionar Documento",
              text: error.response.data,
            });
          });

        this.$toast.add({
          severity: "success",
          summary: `Documentos adicionados com sucesso!`,
          life: 2000
        });

      }

      this.$emit("salva-dados", socio);
    },
    // addDocumento(tpDocumento, document) {
    //   if (this.TIPO_DOCUMENTO[tpDocumento].formato !== document.type) {
    //     this.openDialogMessage({
    //       type: "error",
    //       title: "Erro - Adicionar Documento",
    //       text: "Formato de arquivo inválido para esse tipo de documento",
    //     });
    //
    //     return;
    //   }
    //
    //   const boundaryTest = CryptoJS.SHA1(document.name).toString();
    //
    //   const formData = new FormData();
    //   formData.append("file", document);
    //
    //   this.$axios
    //     .post(
    //       `/empresa/${this.empresaId}/socio/${this.socio.id}/documento?tipo=${tpDocumento}`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           "file-token": boundaryTest,
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       this.openDialogMessage({
    //         type: "success",
    //         title: "Adicionar Documento",
    //         text: "Documento adicionado com sucesso.",
    //       });
    //       this.socio.documentos = response.data;
    //     })
    //     .catch((error) => {
    //       this.openDialogMessage({
    //         type: "error",
    //         title: "Erro - Adicionar Documento",
    //         text: error.response.data,
    //       });
    //     });
    // },
    getSocioDocto(tpDocto) {
      const docto = this.socio.documentos.find((el) => el.tipo === tpDocto);
      if (docto) return docto;
    },
    handleChangeTipoDocumento(item) {
      if (item === "RG") {
        this.doctosTipoSocio = [
          "RG_FRENTE",
          "RG_VERSO",
          "PAGINA_PROCURACAO"
        ];
      }

      if (item === "CNH") {
        this.doctosTipoSocio = [
          "CNH_FRENTE",
          "CNH_VERSO",
          "PAGINA_PROCURACAO"
        ];
      }
    },
    getDoc(e, documento) {
      if(e) {
        this.file.push({
          documento: documento,
          file: e
        });
      }
    },
  },
};
</script>
