import { Route, RouteConfig } from "vue-router";
import Relatorios from "@/views/Relatorios/Relatorios.vue";
import Monitoramento from "@/views/Relatorios/Monitoramento.vue";
import ExtratoRecebiveis from "@/views/Relatorios/ExtratoRecebiveis.vue";
import VendasPorEstande from "@/views/Relatorios/VendasPorEstande.vue";
import GenericComponent from "@/components/GenericComponent.vue";
import PagamentoAnalitico from "@/views/Relatorios/PagamentoAnalitico.vue";
import VendasPdv from "@/views/Relatorios/VendasPdv.vue";
import VendaAnalitico from "@/views/Relatorios/VendaAnalitico.vue";

const relatorioRoutes: RouteConfig = {
  path: "/relatorios",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Relatorios",
      component: Relatorios,

      meta: {
        breadCrumb: [
          {
            text: "Relatórios"
          },
        ]
      }
    },
    {

      path: "/relatorios/monitoramento",
      name: "Monitoramento",
      component: Monitoramento,

      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Monitoramento"
            }
          ];
        }
      }

    },
    {

      path: "/relatorios/extrato-recebiveis",
      name: "ExtratoRecebiveis",
      component: ExtratoRecebiveis,

      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Extrato Recebíveis"
            }
          ];
        }
      }

    },
    {

      path: "/relatorios/vendas-estande",
      name: "VendasPorEstande",
      component: VendasPorEstande,

      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Vendas por Estande"
            }
          ];
        }
      }

    },
    {

      path: "/relatorios/pagamentoAnalitico",
      name: "pagamentoAnalitico",
      component: PagamentoAnalitico,

      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Pagamento Analitico"
            }
          ];
        }
      }
    },
    {

      path: "/relatorios/vendasPDV",
      name: "vendasPDV",
      component: VendasPdv,

      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Vendas PDV"
            }
          ];
        }
      }
    },
    {
      path: "/relatorios/vendas-analitico",
      name: "VendaAnalitico",
      component: VendaAnalitico,
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Relatórios",
              // to: {
              //   name: "Relatorios"
              // }
            },
            {
              text: "Vendas Analitico"
            }
          ];
        }
      }

    },
  ]

}



export default relatorioRoutes;
