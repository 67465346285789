<template>
  <transition name="bounce">
    <div class="app-overlay" v-show="showDialog">
      <img :src="require('@/assets/img/loading.gif')" alt="" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    showDialog: Boolean
  }
};
</script>

<style scoped>
.app-overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
</style>
