import { Route, RouteConfig } from "vue-router";
import Clientes from "@/views/Cliente/Clientes.vue";
import FormCliente from "@/views/Cliente/FormCliente.vue";
import GenericComponent from "@/components/GenericComponent.vue";

const clienteRoutes: RouteConfig = {
  path: "/clientes",
  component: GenericComponent,
  children: [
    {
      path: "/",
      name: "Clientes",
      component: Clientes,
      meta: {
        breadCrumb: [
          {
            text: "Clientes"
          }
        ]
      }
    },
    {
      path: "/clientes/add",
      name: "addCliente",
      component: FormCliente,
      props: route => ({
        mode: "create",
        idEmpresa: route.params.idEmpresa
      }),
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Clientes",
              to: {
                name: "Clientes"
              }
            },
            {
              text: "Novo Cliente"
            }
          ];
        }
      }
    },
    {
      path: "/clientes/:idCliente",
      component: FormCliente,
      name: "viewCliente",
      props: route => ({
        mode: "view",
        idCliente: route.params.idCliente
      }),
      meta: {
        breadCrumb(route: Route) {
          const idCliente = route.params.idCliente;

          return [
            {
              text: "Clientes",
              to: { name: "Clientes" }
            },
            {
              text: idCliente
            }
          ];
        }
      }
    },
    {
      path: "/clientes/:idCliente/edit",
      name: "editCliente",
      component: FormCliente,
      props: route => ({
        mode: "edit",
        idCliente: route.params.idCliente
      }),
      meta: {
        breadCrumb(route: Route) {
          const idCliente = route.params.idCliente;

          return [
            {
              text: "Clientes",
              to: { name: "Clientes" }
            },
            {
              text: idCliente,

              to: {
                name: "viewCliente",
                params: {
                  idUsuario: idCliente
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};

export default clienteRoutes;
