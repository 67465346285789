<template>
  <v-card>
    <form @submit.prevent="confirmaTransacao">
      <v-subheader class="text-subtitle-1 font-weight-bold">
        Transação Interna
      </v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense>
          <v-col class="font-weight-bold"> Destino</v-col>
        </v-row>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              dense
              class="pa-0 ma-0"
              v-model="buscaCnpjDestino"
              @keyup="buscaCnpjDestino = $masks.cnpj(buscaCnpjDestino)"
              maxlength="18"
              label="Documento Destinatário"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn text small @click="getByDocumento">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense v-if="tef.destinatario.id">
          <v-col cols="5" class="text-subtitle-1 font-weight-bold ">
            <v-row dense>
              <v-col class="pa-0" cols="2">Conta:</v-col>
              <v-col class="pa-0">{{ tef.destinatario.numero_conta }}</v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" cols="2">Titular:</v-col>
              <v-col class="pa-0">{{ tef.destinatario.nome_titular }}</v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" cols="2">CNPJ:</v-col>
              <v-col class="pa-0">{{
                $masks.cnpj(tef.destinatario.documento_titular)
              }}</v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row dense class="d-flex justify-end ">
              <v-col cols="6">
                <v-text-field
                  dense
                  label="Valor"
                  v-model="tef.valor"
                  required
                  prepend-icon="R$"
                  @keyup="tef.valor = $masks.dinheiro(tef.valor)"
                  class="text-h4"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field v-model="tef.descricao" dense label="Descrição">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="btCancela" @click="$emit('cancela-transacao')" text>
          Cancelar
        </v-btn>
        <v-btn type="submit" color="btConfirma" text>
          Transferir
        </v-btn>
      </v-card-actions>
    </form>
    <DialogToken
      :showDialog="showDialogToken"
      :descricao="
        `Transferir R$ ${tef.valor} para ${tef.destinatario.nome_titular}`
      "
      @get-token="save"
      @cancela-token="cancelaToken"
    />
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
import DialogToken from "@/components/Dialogs/DialogToken.vue";
function initialState() {
  return {
    buscaCnpjDestino: null,
    tef: {
      destinatario: {
        id: null,
        cnpj: null,
        numero_conta: null,
        nome_titular: null,
        documento_titular: null
      },
      valor: null,
      descricao: null
    },
    showDialogToken: false
  };
}
export default {
  name: "TEF",
  components: {
    DialogToken
  },
  props: {
    contaCorrente: String
  },

  data() {
    return initialState();
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getByDocumento() {
      this.tef.destinatario = {
        id: null,
        cnpj: null,
        numero_conta: null,
        nome_titular: null,
        documento_titular: null
      };

      if (!this.buscaCnpjDestino) return;
      const searchCNPJ = this.buscaCnpjDestino.replace(/[^a-zA-Z0-9 ]/g, "");
      this.$axios
        .get(`/banco/conta/documento/${searchCNPJ}`)
        .then(response => {
          if (this.contaCorrente === response.data.id) {
            this.openDialogMessage({
              type: "error",
              title: "Atenção",
              text: "Não é possível transferir para a mesma conta corrente."
            });
            return;
          }
          this.tef.destinatario = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Atenção",
            text: error.response.data
          });
        });
    },
    save(token) {
      const data = {
        destino: this.tef.destinatario.id,
        valor: this.parseToFloat(this.tef.valor),
        descricao: this.tef.descricao
      };

      this.$axios
        .post(`/banco/conta/${this.contaCorrente}/tef`, data)
        .then(response => {
          this.tef.valor = "";
          this.tef.descricao = "";
          this.$emit("atualiza-saldo");
          this.closeDialogConfirm();
          this.$router.push({
            name: "SucessoTransacao",
            params: {
              comprovante: response.data
            }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Transação TEF",
            text: error.response.data
          });
        });
    },

    confirmaTransacao() {
      if (!this.tef.destinatario.id) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção",
          text: "Escolha um destinatário para realizar a transação."
        });
        return;
      }
      this.openDialogToken();
    },
    openDialogToken() {
      this.showDialogToken = true;
    },
    cancelaToken() {
      this.showDialogToken = false;
    }
  }
};
</script>

<style></style>
