<template>
  <v-dialog v-model="showDialog" persistent max-width="800">
    <v-card>
      <form @submit.prevent="save">
        <v-card-title> Adicionar Item </v-card-title>
        <v-tabs v-model="tab" align-with-title @change="handleChangeTab">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab>
            Serviços
          </v-tab>
          <v-tab>
            Produtos
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-autocomplete
                v-if="tab === 0"
                dense
                @change="item.valor = $masks.dinheiro(item.servico.valor.toFixed(2))"
                :items="servicos"
                item-value="id"
                item-text="nome"
                v-model="item.servico"
                return-object
              >
                <template v-slot:selection="{ item }">
                  {{ item.sku }} - {{ item.nome }} R$
                  {{ item.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content style="max-width: 45rem;">
                    <v-list-item-title>
                      {{ item.sku }} - {{ item.nome }} R$
                      {{
                        item.valor | formatMoney({ minimumFractionDigits: 2 })
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.descricao }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-if="tab === 1"
                dense
                @change="item.valor = $masks.dinheiro(item.produto.valor.toFixed(2))"
                :items="produtos"
                item-value="id"
                item-text="nome"
                v-model="item.produto"
                return-object
              >
                <template v-slot:selection="{ item }">
                  {{ item.nome }} R$
                  {{ item.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content style="max-width: 45rem;">
                    <v-list-item-title>
                      {{ item.nome }} R$
                      {{
                        item.valor | formatMoney({ minimumFractionDigits: 2 })
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.descricao }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>

            </v-col>

            <v-col cols="4">
              <p class="valor">
                R$
                {{ totalComputed | formatMoney({ minimumFractionDigits: 2 }) }}
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <v-text-field
                required
                dense
                label="Quantidade"
                type="number"
                step="any"
                v-model="item.quantidade"
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                required
                dense
                step="any"
                label="Valor unitário"
                v-on:input="item.valor = $masks.dinheiro(item.valor)"
                v-model="item.valor"
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                required
                dense
                v-on:input="item.desconto = $masks.dinheiro(item.desconto)"
                label="Desconto"
                v-model="item.desconto"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btCancela" text @click="cancelar">
            Cancelar
          </v-btn>
          <v-btn color="btConfirma" text type="submit"> Salvar </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showDialog(newValue, oldValue) {
      if (newValue) {
        if(this.servicos.length === 0) this.getServicos();
      }
    }
  },
  computed: {
    ...mapState(["empresaAtual"]),
    totalComputed() {
      if (!this.item && !this.item.servico && !this.item.produto) return 0;

      const qtd = this.parseToFloat(
        this.item.quantidade ? this.item.quantidade : 1
      );
      const valorServico = this.parseToFloat(this.item.valor);
      const desconto = this.parseToFloat(
        this.item.desconto ? this.item.desconto : 0
      );
      const totalBruto = qtd * valorServico;

      return (totalBruto - desconto).toFixed(2);
    }
  },
  data() {
    return {
      tab: 0,
      item: {
        servico: {
          valor: "0,00",
          id: null
        },
        produto: {
          valor: "0,00",
          id: null
        },
        desconto: "0,00",
        valor: "0,00",
        quantidade: 1
      },
      servicos: [],
      produtos: [],
    };
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    handleChangeTab() {

      if(this.tab === 0) {
        if(this.servicos.length === 0) this.getServicos();
      }

      if(this.tab === 1) {
        if(this.produtos.length === 0)  this.getProdutos();
      }

    },
    getServicos() {
      this.$axios
        .get(`/servico/empresa/${this.empresaAtual.id}?status=ATIVO`)
        .then(response => {
          this.servicos = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Serviços",
            text: error.response.data
          });
        });
    },
    getProdutos() {
      this.$axios
        .get(`/produto/empresa/${this.empresaAtual.id}?status=ATIVO`)
        .then(response => {
          this.produtos = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Produtos",
            text: error.response.data
          });
        });
    },
    save() {


      if (!this.item.servico?.id && !this.item.produto?.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Informe o serviço ou produto!"
        });
        return;
      }

      if(this.tab === 0) {
        delete this.item.produto;
      }

      if(this.tab === 1) {
        delete this.item.servico;
      }

      this.item.quantidade = this.parseToFloat(this.item.quantidade);
      this.item.valor = this.parseToFloat(this.item.valor);
      this.item.desconto = this.parseToFloat(this.item.desconto);

      this.$emit("salva-dados", this.item);
      this.resetDados();
    },
    cancelar() {
      this.$emit("cancela-dialog");
    },
    resetDados() {
      this.item = {
        desconto: "0,00",
        quantidade: 1
      };
    }
  }
};
</script>

<style>
.valor {
  font-size: 2rem;
  margin: showDialog0.5rem;
}
</style>
