<template>
  <div
      class="pa-2"
      :style="{ borderLeft: `2px solid ${color}` }"
  >
    <v-row no-gutters>
      <v-col>
<!--        <i class="dash-icon" :class="icon" :style="{ color: color }"></i>-->
        <v-icon :color="color"> {{ icon }} </v-icon>
        <span
            class="font-italic text-subtitle-1 text-start"
            :style="{ color: color }"
        >
          {{ titulo }}
        </span>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-8">
      <v-col class="text-center">
        <span class="caption orange--text">R$</span>
        <span class="text-h3 orange--text">{{
            valor | formatMoney({ minimumFractionDigits: 2 })
          }}</span>
        <p></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    valor: Number
  },
  data() {
    return {
      titulo: "Total de consumos",
      icon: "mdi-cart",
      color: "orange",
    };
  },
  computed: {
  },
  methods: {

  }
};
</script>
<style scoped>
.dash-number {
  font-size: 20px;
}
@media (min-width: 900px) {
  .saldo {
    min-height: 8.5rem;
  }
}
</style>
