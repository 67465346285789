<template>
  <div>
    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16">
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row class="grey lighten-5  table_mobile">

        <v-col cols="4">
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              class="mt-3"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-col>

        <v-col class="text-center pa-0">
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="table"
          ></v-skeleton-loader>
        </v-col>
      </v-row>

    </v-container>

    <v-container v-else fluid>
      <v-row class="grey lighten-5  align-center table_mobile">
        <v-col cols="4" class="d-flex flex-column py-3">
        <span class="headline font-weight-bold ">
          Contrato #{{ contrato.numero }}
        </span>

          <span class="caption" v-if="contrato.usuario_criacao">
          Criado
          {{
              contrato.data_criacao
                  | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          por {{ contrato.usuario_criacao.nome }}
        </span>
          <span class="caption" v-if="contrato.usuario_cancelamento">
          Cancelado
          {{
              contrato.data_cancelamento
                  | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          por {{ contrato.usuario_cancelamento.nome }}
        </span>
          <span class="caption">
          Atualizado
          {{
              contrato.data_atualizacao
                  | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
        </span>
          <span v-if="contrato.data_aceite && contrato.usuario_aceite" class="caption">
          Aceito em
          {{
              contrato.data_aceite
                  | formatDate({ dateStyle: "short", timeStyle: "short" })
            }}
          por {{ contrato.usuario_aceite?.nome }}
        </span>
          <span class="caption">
          Inicio
          {{
              contrato.data_inicio_contrato | formatDate({ dateStyle: "short" })
            }}
          Termino
          {{
              contrato.data_termino_contrato | formatDate({ dateStyle: "short" })
            }}
        </span>

          <span class="caption">
          Total pago R$
          {{ contrato.total_pago | formatMoney({ minimumFractionDigits: 2 }) }}
        </span>

          <span class="caption">
          Parcelas
          {{
              contrato.qtd_parcelas == 0
                  ? "recorrente"
                  : `${contrato.qtd_parcelas}x`
            }}
          R$
          {{
              contrato.valor_parcela | formatMoney({ minimumFractionDigits: 2 })
            }}
        </span>
        </v-col>

        <v-divider vertical></v-divider>
        <v-col cols="4" class="text-center pa-0">
          <p class="headline font-weight-bold">Valor do contrato</p>
          <span class="caption font-weight-bold green--text">R$</span>
          <span class="text-h4 font-weight-bold">
          {{
              contrato.valor_total_contrato
                  | formatMoney({ minimumFractionDigits: 2 })
            }}</span
          >

          <div class="caption" style="margin-top: 0.2rem;">
          <span
          >Saldo devedor R$
            {{
              contrato.saldo_devedor | formatMoney({ minimumFractionDigits: 2 })
            }}</span
          >
          </div>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col class="text-center pa-0">
          <v-chip
              class="ma-2 text-center"
              v-if="contrato.status"
              :color="STATUS_CONTRATO[contrato.status].cor"
              style="justify-content: center; min-width: 100px"
              label
              text-color="white"
          >
            {{ STATUS_CONTRATO[contrato.status].texto }}
          </v-chip>

          <div v-if="contrato.modalidade === 'CARTAO'">
            <p>
              <i class="far fa-credit-card"></i>
              Cartão
            </p>
            <p>
              {{ contrato.cartao.first_digits }} *** **** ****
              {{ contrato.cartao.last_digits }}
            </p>
          </div>

          <div v-if="contrato.modalidade === 'BOLETO'">
            <p class="text-h6 font-weight-bold">
              <i class="far fa-money-alt"></i>
              Boleto
            </p>
          </div>

          <div v-if="contrato.modalidade === 'DEBITO_AUTOMATICO'">
            <p class="text-h6 font-weight-bold">
              <i class="fa fa-money"></i>
              Débito automatico
            </p>
          </div>
        </v-col>
      </v-row>

      <v-row class="grey lighten-5  table_mobile">
        <v-col cols="4" class="d-flex flex-column py-3">
          <p class="text-h6 font-weight-bold">Pagador</p>
          <div class="d-flex flex-column custom-card" v-if="contrato.pagador">
          <span>
            {{ contrato.pagador.nome_fantasia }}
          </span>
            <span class="text-caption">
            {{ contrato.pagador.razao_social }}
          </span>
            <span class="text-caption">
            {{ $masks.cnpj(contrato.pagador.cnpj) }}
          </span>
            <span class="text-caption">
            {{ $masks.telefone(contrato.pagador.telefone) }}
          </span>
            <span class="text-caption">
            {{ contrato.pagador.email }}
          </span>
            <span class="text-caption">
            {{ contrato.pagador.endereco }}, {{ contrato.pagador.numero }} -
            {{ contrato.pagador.cidade }} ,
            {{ contrato.pagador.uf }}
          </span>
          </div>

          <p class="text-h6 font-weight-bold" style="margin-top: 0.5rem">
            Emissor
          </p>
          <div
              v-if="contrato.recebedor"
              class="d-flex flex-column custom-card mb-4"
          >
          <span>
            {{ contrato.recebedor.nome_fantasia }}
          </span>
            <span>
            {{ contrato.recebedor.razao_social }}
          </span>
            <span class="text-caption">
            {{ $masks.cnpj(contrato.recebedor.cnpj) }}
          </span>
            <span class="text-caption">
            {{ contrato.recebedor.endereco }}, {{ contrato.recebedor.numero }} -
            {{ contrato.recebedor.cidade }} ,
            {{ contrato.recebedor.uf }}
          </span>
          </div>
          <div>
            <p class="text-h6 font-weight-bold" style="margin-top: 0.5rem">
              Funções
            </p>
          </div>
          <div class="d-flex flex-column custom-card mb-4">
            <v-row dense>
              <v-col>
                <v-btn
                    style="width:7.5rem;height:42px;margin: 0.5rem;"
                    outlined
                    :to="{ name: 'AceiteDeContrato' }"
                >
                  <v-icon> mdi-magnify </v-icon>
                  ver
                </v-btn>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    @click="openModalCancelarContrato"
                    v-if="
                  contrato.status !== 'CANCELADO' &&
                    contrato.status !== 'QUITADO' &&
                    podeCancelarContrato
                "
                    style="margin: 0.5rem;"
                    class="p-button-outlined p-button-danger"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="text-center pa-0">
          <TabelaParcelasContrato
              v-if="contrato.parcelas"
              :parcelas="contrato.parcelas"
              title="Parcelas do Contrato"
              :elevation="6"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import TabelaParcelasContrato from "@/components/Tables/TableParcelasContrato.vue";
import Button from "primevue/button";

export default {
  components: {
    TabelaParcelasContrato,
    Button
  },
  props: {
    idContrato: String
  },
  computed: {
    ...mapState(["user"]),
    podeCancelarContrato() {
      return this.empresaDoUsuario.find(
        obj => obj.id === this.contrato.recebedor.id
      );
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      contrato: {
        id: null
      },
      empresaDoUsuario: []
    };
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getContrato() {
      await this.$axios
        .get(`/contrato/${this.idContrato}`)
        .then(response => {
          this.contrato = response.data;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar contrato",
            text: error.response.data
          });
          this.showDialogEditPagto = false;
        });
    },
    openModalCancelarContrato() {
      if (!this.podeCancelarContrato) {
        this.openDialogMessage({
          type: "error",
          title: "Erro - Ao cancelar Contrato",
          text: "Sem permissão para cancelar contrato!"
        });
      }
      this.openDialogConfirm({
        title: "Atenção",
        text: `Deseja realmente cancelar esta contrato? Esta ação não poderá ser desfeita.`,
        confirma: this.cancelar,
        cancela: this.closeDialogConfirm
      });
    },
    cancelar() {
      this.closeDialogConfirm();
      this.$axios
        .put(`/contrato/${this.contrato.id}/cancelar`)
        .then(() => {
          this.$toast.add({
            severity: "info",
            summary: `Contrato #${this.contrato.numero} cancelado!`,
            life: 2000
          });
          this.getContrato();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cancelar contrato",
            text: error.response.data
          });
        });
    },
    async buscaEmpresaDoUsuario() {
      return await this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresaDoUsuario = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar contrato",
            text: error.response.data
          });
        });
    }
  },
  async created() {
    await this.getContrato();
    if (this.contrato.id) {
      await this.buscaEmpresaDoUsuario();
    }
  }
};
</script>

<style>
.table_mobile {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

@media (max-width: 900px) {
  .table_mobile {
    display: block !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
