<template>
  <v-container fluid>
    <v-row>
      <v-col fluid>
        <v-row dense>
          <v-col>
            <p class="text-subtitle-2">Quem está cobrando?</p>
            <div class="d-flex">
              <AutoComplete
                  class="autocomplete-input"
                  v-model="contrato.recebedor"
                  :suggestions="empresasFiltradasUsuario"
                  @complete="searchEmpresaUsuario"
                  forceSelection
                  :field="itemTextEmpresa"
                  placeholder="Pesquise aqui..."
              >
                <template #item="slotProps">
                  <div>
                    <div>{{ slotProps.item.nome }}</div>
                    <div>{{ slotProps.item.razao_social }}</div>
                    <div>
                      CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                      {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                      {{
                        $masks.telefone(
                            slotProps.item.telefone_celular
                        )
                      }}
                      E-mail: {{ slotProps.item.email }}
                    </div>
                  </div>
                </template>
              </AutoComplete>
              <div v-if="contrato.recebedor" class="div-clearable" @click="contrato.recebedor = null">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
<!--        <v-row dense>-->
<!--          <v-col>-->
<!--            <p class="text-subtitle-2">Quem está cobrando?</p>-->
<!--            <v-autocomplete-->
<!--              :items="empresas"-->
<!--              item-value="id"-->
<!--              item-text="razao_social"-->
<!--              v-model="contrato.recebedor"-->
<!--              dense-->
<!--              return-object-->
<!--            >-->
<!--              <template v-slot:selection="{ item }">-->
<!--                <p style="margin-bottom:0px;display: block;width: 100%;">-->
<!--                  {{ item.razao_social }}-->
<!--                </p>-->
<!--                <span class="text-caption">{{ $masks.cnpj(item.cnpj) }}</span>-->
<!--              </template>-->
<!--              <template v-slot:item="{ item }">-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>{{-->
<!--                    item.nome_fantasia-->
<!--                  }}</v-list-item-title>-->
<!--                  <v-list-item-subtitle>-->
<!--                    {{ $masks.cnpj(item.cnpj) }} - -->
<!--                    {{ item.razao_social }}-->
<!--                  </v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--              </template>-->
<!--            </v-autocomplete>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-row dense>
          <v-col>
            <p class="text-subtitle-2">Quem vai pagar?</p>
            <div class="d-flex">
              <AutoComplete
                  class="autocomplete-input"
                  v-model="contrato.pagador"
                  :suggestions="empresasFiltradas"
                  @complete="searchEmpresa"
                  forceSelection
                  :field="itemTextEmpresa"
                  placeholder="Pesquise aqui..."
              >
                <template #item="slotProps">
                  <div>
                    <div>{{ slotProps.item.nome }}</div>
                    <div>{{ slotProps.item.razao_social }}</div>
                    <div>
                      CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                      {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                      {{
                        $masks.telefone(
                            slotProps.item.telefone_celular
                        )
                      }}
                      E-mail: {{ slotProps.item.email }}
                    </div>
                  </div>
                </template>
              </AutoComplete>
              <div v-if="contrato.pagador" class="div-clearable" @click="contrato.pagador = null">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
          </v-col>
        </v-row>

<!--        <v-row dense>-->
<!--          <v-col>-->
<!--            <p class="text-subtitle-2">Quem vai pagar?</p>-->
<!--            <v-autocomplete-->
<!--              :items="empresas"-->
<!--              item-value="id"-->
<!--              item-text="razao_social"-->
<!--              v-model="contrato.pagador"-->
<!--              dense-->
<!--              return-object-->
<!--            >-->
<!--              <template v-slot:selection="{ item }">-->
<!--                <p style="margin-bottom:0px;display: block;width: 100%;">-->
<!--                  {{ item.razao_social }}-->
<!--                </p>-->
<!--                <span class="text-caption">{{ $masks.cnpj(item.cnpj) }}</span>-->
<!--              </template>-->
<!--              <template v-slot:item="{ item }">-->
<!--                <v-list-item-content>-->
<!--                  <v-list-item-title>{{-->
<!--                    item.nome_fantasia-->
<!--                  }}</v-list-item-title>-->
<!--                  <v-list-item-subtitle>-->
<!--                    {{ $masks.cnpj(item.cnpj) }} - -->
<!--                    {{ item.razao_social }}-->
<!--                  </v-list-item-subtitle>-->
<!--                </v-list-item-content>-->
<!--              </template>-->
<!--            </v-autocomplete>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-row>
          <v-col cols="4">
            <span class="text-caption span-block">Data de Inicio</span>
            <Calendar
              v-model="contrato.data_inicio_contrato"
              dateFormat="dd/mm/yy"
              :showIcon="true"
              :manualInput="false"
              :touchUI="true"
              style="width: 100%"
            />
          </v-col>
          <v-col cols="4">
            <span class="text-caption span-block">Modalidade</span>
            <Dropdown
                style="width: 100%"
              v-model="contrato.modalidade"
              :options="[
                { value: 'DEBITO_AUTOMATICO', texto: 'Débito automático' }
              ]"
              optionLabel="texto"
              optionValue="value"
            />
          </v-col>
        </v-row>

        <v-row class="inputs">
          <v-col cols="4">
            <span class="text-caption span-block">Valor Total</span>
            <InputNumber
              style="width: 100%"
              v-model="contrato.valor_total_contrato"
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              :minFractionDigits="2"
              :maxFracionDigits="2"
            />
          </v-col>
          <v-col cols="4">
            <span class="text-caption span-block">Parcelas</span>
            <InputNumber
              style="width: 100%"
              mode="decimal"
              v-model="contrato.qtd_parcelas"
              :allowEmpty="true"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <span class="text-caption span-block">Juros a.m. %</span>
            <InputNumber
              style="width: 100%"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              v-model="contrato.juros_mora_atraso"
              suffix=" %"
              :max="100"
              :min="0"
              :allowEmpty="true"
            />
          </v-col>
          <v-col cols="4">
            <span class="text-caption span-block">Multa %</span>
            <InputNumber
              style="width: 100%"
              mode="decimal"
              :minFractionDigits="2"
              :maxFractionDigits="2"
              v-model="contrato.multa_atraso"
              suffix=" %"
              :max="100"
              :min="0"
              :allowEmpty="true"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col fluid class="box_espelho_contrato">
        <v-row v-if="loadingSkeleton" class="grid_mobile">
          <v-skeleton-loader
              :loading="true"
              type="heading"
              width="50%"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="text@4"
              width="50%"
          ></v-skeleton-loader>
        </v-row>
        <v-row v-if="contrato.recebedor && contrato.recebedor.razao_social && !loadingSkeleton" class="grid_mobile">
          <v-col class="grid_mobile" cols="2">
            <span class="span-block"> Emissor </span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block"> {{ contrato.recebedor.nome_fantasia }} </span>
            <span class="text-caption span-block">
              {{ contrato.recebedor.razao_social }}
            </span>
            <span class="span-block">CNPJ {{ contrato.recebedor.cnpj }}</span>
            <span class="span-block">TEL {{  contrato.recebedor.telefone  }}</span>
            <span class="span-block">
              {{ contrato.recebedor.endereco }}, {{ contrato.recebedor.numero }}
              {{ contrato.recebedor.cidade }} - {{ contrato.recebedor.uf }}
            </span>
          </v-col>
        </v-row>

        <v-row v-if="contrato.pagador && contrato.pagador.razao_social">
          <v-col class="grid_mobile" cols="2">
            <span class="span-block"> Pagador </span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block"> {{ contrato.pagador.nome_fantasia }} </span>
            <span class="text-caption span-block">
              {{ contrato.pagador.razao_social }}
            </span>
            <span class="span-block">CNPJ {{ contrato.pagador.cnpj }}</span>
            <span class="span-block">TEL {{  contrato.pagador.telefone  }}</span>
            <span class="span-block">
              {{ contrato.pagador.endereco }}, {{ contrato.pagador.numero }}
              {{ contrato.pagador.cidade }} - {{ contrato.pagador.uf }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-divider horizontal class="color_divider"></v-divider>
        </v-row>
        <v-row class="grid_mobile">
          <v-col>
            <span class="span-block">Data de inicio</span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block">
              {{ dataInicioContratoFormatada }}
            </span>
          </v-col>
        </v-row>

        <v-row class="bg grid_mobile">
          <v-col>
            <span class="span-block">Modalidade</span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block">
              {{ OPCOES_PAGTO[contrato.modalidade].texto }}
            </span>
          </v-col>
        </v-row>

        <v-row class="grid_mobile">
          <v-col>
            <span class="span-block">Valor total</span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block">
              R$
              {{
                contrato.valor_total_contrato
                  | formatMoney({ minimumFractionDigits: 2 })
              }}
            </span>
          </v-col>
        </v-row>

        <v-row class="bg grid_mobile">
          <v-col>
            <span class="span-block">Parcelas</span>
          </v-col>
          <v-col class="confirm_contrato">
            <span class="span-block">
              {{
                contrato.qtd_parcelas +
                  " X R$ " +
                  $masks.dinheiro(valorPorParcela)
              }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="text-end button-gerar">
      <v-col>
        <v-btn
          class="primary"
          @click="openModalConfirmacao"
          v-if="estaValidoParaGerar"
        >
          Gerar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import InputNumber from "primevue/inputnumber";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "CriaContato",
  props: {
    cliente: Object,
    id: String
  },
  components: {
    AutoComplete,
    InputNumber,
    Calendar,
    Dropdown
  },
  created() {
    this.getEmpresasUsuario();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    dataInicioContratoFormatada() {
      if (this.contrato.data_inicio_contrato === null) return null;
      const data = new Date(this.contrato.data_inicio_contrato);
      if (!data) return;
      return new Date(data).toLocaleDateString("pt-BR", { timeZone: "UTC" });
    },
    estaValidoParaGerar() {
      if (!this.contrato.data_inicio_contrato) return false;

      return true;
    },
    valorPorParcela() {
      if (this.contrato.valor_total_contrato <= 0) return 0.0;

      return (
        this.contrato.valor_total_contrato / this.contrato.qtd_parcelas
      ).toFixed(2);
    }
  },
  data() {
    return {
      loadingSkeleton: true,
      empresasUsuario: [],
      empresasFiltradasUsuario: [],
      empresas: [],
      empresasFiltradas: [],
      contrato: {
        recebedor: null,
        pagador: null,
        modalidade: "DEBITO_AUTOMATICO",
        tipo: "PARCELADO",
        data_inicio_contrato: new Date(),
        valor_total_contrato: 0,
        qtd_parcelas: 1,
        teimosinha: true,
        juros_mora_atraso: 0,
        multa_atraso: 0
      },
      erros: []
    };
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getEmpresasUsuario() {
      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresasUsuario = response.data
            .filter(obj => obj.status == "ATIVA")
            .map(empresa => {
              empresa.telefone = this.$masks.telefone(empresa.telefone);
              empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
              return empresa;
            });

          this.contrato.recebedor = this.empresasUsuario.find(obj => obj.id === this.empresaAtual.id);
          this.empresasFiltradasUsuario = this.empresasUsuario;
          this.getEmpresas();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },

    async getEmpresas() {
      return await this.$axios
        .get(`/empresa/${this.contrato.recebedor.id}/marketplace?status=ATIVA`)
        .then(async response => {
          this.empresas = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });

          this.empresasFiltradas = this.empresas;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel buscar as empresas",
            text: error.response.data
          });
        });
    },

    async save() {
      this.closeDialogConfirm();
      if (!this.contrato.pagador) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção!",
          text: "Pagador não pode ser vazio!"
        });
        return false;
      }
      if (!this.empresaAtual) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção!",
          text: "Emissor não pode ser vazio!"
        });
        return false;
      }
      if (
        this.contrato.pagador.nome_fantasia === this.empresaAtual.nome_fantasia
      ) {
        this.openDialogMessage({
          type: "error",
          title: "Atenção!",
          text: "Pagador e emissor não podem ser iguais!"
        });
        return false;
      }

      if (this.contrato.qtd_parcelas <= 0) {
        this.openDialogMessage({
          type: "error",
          title: "Erro - ao criar Contrato",
          text: "Quantidade de parcela não pode ser igual a zero"
        });
        return false;
      }
      this.$axios
        .post(`/contrato`, this.contrato)
        .then(response => {
          this.contrato = response.data;

          this.$router.push({
            name: "viewContrato",
            params: { idContrato: this.contrato.id }
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - ao criar Contrato",
            text: error.response.data
          });
        });
    },

    openModalConfirmacao() {
      this.openDialogConfirm({
        title: "Atenção",
        text: `Deseja realmente emitir este contrato? Esta ação não pode ser desfeita.`,
        confirma: this.save,
        cancela: this.closeDialogConfirm
      });
    },

    searchEmpresaUsuario(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradasUsuario = this.empresasUsuario.filter(item => {
          const razaoSocial = item.razao_social
              ? item.razao_social.toLowerCase()
              : "";
          const cnpj = item.cnpj
              ? this.$masks.cnpj(item.cnpj).toLowerCase()
              : "";
          const nomeFantasia = item.nome_fantasia
              ? item.nome_fantasia.toLowerCase()
              : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
              razaoSocial.includes(queryText) ||
              cnpj.includes(queryText) ||
              nomeFantasia.includes(queryText) ||
              nome.includes(queryText) ||
              cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradasUsuario = [...this.empresasUsuario];
      }

    },

    itemTextEmpresa(item) {
      if (item.cpf) {
        return `${item.razao_social} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.razao_social} - ${this.$masks.cnpj(item.cnpj)}`;
      }

    },

    searchEmpresa(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradas = this.empresas.filter(item => {
          const razaoSocial = item.razao_social
              ? item.razao_social.toLowerCase()
              : "";
          const cnpj = item.cnpj
              ? this.$masks.cnpj(item.cnpj).toLowerCase()
              : "";
          const nomeFantasia = item.nome_fantasia
              ? item.nome_fantasia.toLowerCase()
              : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
              razaoSocial.includes(queryText) ||
              cnpj.includes(queryText) ||
              nomeFantasia.includes(queryText) ||
              nome.includes(queryText) ||
              cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradas = [...this.empresas];
      }
    },

  }
};
</script>

<style scoped>
.fa-menu-icon {
  font-size: 1.2em;

  margin-right: 10px;
}

.custom-card {
  margin-left: 20px;
  margin-top: -39px;
}

.text-caption {
  display: block;
}

.sku {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 6rem;
}
.titulo {
  font-size: 1.3rem;
}

i {
  font-size: 1.3rem;
}
.nome-fantasia {
  font-weight: 500;
}
.endereco {
  font-size: 0.8rem;
}

.inline {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.inputs {
  flex-wrap: inherit !important;
}
.box_contrato_cobranca {
  min-height: 150px;
}
.box_gerar {
  justify-content: end;
}
.button-gerar {
  justify-content: right;
}
.box_opcao_pagamento {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.box_espelho_contrato {
  margin-left: 50px;
}
.bg {
  background-color: #d3d3d3;
}
.confirm_contrato {
  text-align: end;
}
.color_divider {
  border-color: rgba(0, 0, 0, 0.42) !important;
}
.span-block {
  display: block;
}

.autocomplete-input {
  width: 100%;
  height: 43px;
}

.div-clearable {
  margin-left: -50px;
  margin-top: 8px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
  .custom-card {
    margin-top: 10px;
    margin-left: 0px;
  }
  .button-gerar {
    justify-content: end;
    margin-top: 20px !important;
  }
  .box_espelho_contrato {
    display: block;
    margin-left: 0px;
  }
  .inputs {
    display: block;
  }
  .box_opcao_pagamento {
    display: block;
  }
  .grid_mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
