<template>
  <DataTable
    :value="pagamentos"
    sortField="parcela"
    :sortOrder="1"
    :paginator="true"
    :rows="5"
    responsiveLayout="stack"
    breakpoint="960px"
  >
    <Column field="data_da_operacao" header="Data">
      <template #body="slot">
        {{ slot.data.data_operacao | formatDate({ dateStyle: "short" }) }}
      </template>
    </Column>
    <Column field="valor_pago" header="Valor">
      <template #body="slot">
        R$
        {{ slot.data.valor | formatMoney({ minimumFractionDigits: 2 }) }}
      </template>
    </Column>
    <Column field="descricao" header="Descrição">
      <template #body="slot">
        <span class="descricao_mobile">
          {{ slot.data.descricao }}
          <br />
          NSU {{ slot.data.nsu }}
        </span>
      </template>
    </Column>
  </DataTable>
</template>
<script>
import DataTable from "primevue/datatable";
import { mapState } from "vuex";
import Column from "primevue/column";

export default {
  name: "ContratoTabelaExtrato",
  components: {
    DataTable,
    Column
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  props: {
    pagamentos: {
      type: Array,
      required: true
    }
  }
};
</script>
<style>
@media (max-width: 900px) {
  .descricao_mobile {
    text-align: end;
    margin-left: 15px;
  }
}
</style>
