import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import FormProduto from "@/views/Produto/FormProduto.vue";
import Produtos from "@/views/Produto/Produtos.vue";

const produtoRoutes: RouteConfig = {
  path: "/produtos",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "Produtos",
      component: Produtos,

      meta: {
        breadCrumb: [
          {
            text: "Produtos"
          }
        ]
      }
    },
    {
      path: "/produtos/add",
      name: "addProduto",
      component: FormProduto,
      props: {
        mode: "create"
      },
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Produtos",
              to: {
                name: "Produtos"
              }
            },
            {
              text: "Novo Produto"
            }
          ];
        }
      }
    },
    {
      path: "/produtos/:id/clone",
      name: "cloneProduto",
      component: FormProduto,

      props: route => ({
        mode: "clone",
        id: route.params.id
      }),
      meta: {
        breadCrumb(route: Route) {
          return [
            {
              text: "Produtos",
              to: { name: "Produtos" }
            },
            {
              text: "Novo Produto"
            }
          ];
        }
      }
    },
    {
      path: "/produtos/:id",
      name: "viewProduto",
      component: FormProduto,

      props: route => ({
        mode: "view",
        id: route.params.id
      }),
      meta: {
        breadCrumb(route: Route) {
          const id = route.params.id;

          return [
            {
              text: "Produtos",
              to: { name: "Produtos" }
            },
            {
              text: id
            }
          ];
        }
      }
    },
    {
      path: "/produtos/:id/edit",
      name: "editProduto",
      component: FormProduto,
      props: route => ({
        mode: "edit",
        id: route.params.id
      }),
      meta: {
        breadCrumb(route: Route) {
          const id = route.params.id;

          return [
            {
              text: "Produtos",
              to: { name: "Produtos" }
            },
            {
              text: id,

              to: {
                name: "viewProduto",
                params: {
                  id: id
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};
export default produtoRoutes;
