import { RouteConfig, Route } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Empresas from "@/views/Empresa/Empresas.vue";
import DetalhesEmpresa from "@/views/Empresa/DetalhesEmpresa.vue";
import FormEmpresa from "@/views/Empresa/FormEmpresa.vue";
const empresaRoutes: RouteConfig = {
  path: "/empresas",
  component: GenericComponent,

  children: [
    {
      path: "/",
      component: Empresas,
      name: "Empresas",

      meta: {
        breadCrumb: [
          {
            text: "Empresas"
          }
        ]
      }
    },
    {
      path: "/empresas/add",
      name: "addEmpresa",
      props: {
        mode: "create"
      },
      component: FormEmpresa,
      meta: {
        breadCrumb: [
          {
            text: "Empresas",
            to: {
              name: "Empresas"
            }
          },
          {
            text: "Nova Empresa"
          }
        ]
      }
    },
    {
      path: "/empresas/:idEmpresa",
      name: "viewEmpresa",
      component: DetalhesEmpresa,
      props: route => ({
        mode: "view",
        idEmpresa: route.params.idEmpresa
      }),
      meta: {
        breadCrumb(route: Route) {
          const idEmpresa = route.params.idEmpresa;
          return [
            {
              text: "Empresas",
              to: {
                name: "Empresas"
              }
            },
            {
              text: idEmpresa
            }
          ];
        }
      }
    },
    {
      path: "/empresas/:idEmpresa/edit",
      name: "editEmpresa",
      component: FormEmpresa,
      props: route => ({
        mode: "edit",
        idEmpresa: route.params.idEmpresa
      }),
      meta: {
        breadCrumb(route: Route) {
          const idEmpresa = route.params.idEmpresa;
          return [
            {
              text: "Empresas",
              to: {
                name: "Empresas"
              }
            },
            {
              text: idEmpresa,
              to: {
                name: "viewEmpresa",
                params: {
                  idEmpresa: idEmpresa
                }
              }
            },
            {
              text: "Editar"
            }
          ];
        }
      }
    }
  ]
};

export default empresaRoutes;
