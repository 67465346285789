<template>
  <div @click="handleClick" class="div-main">
    <v-icon dark color="white">
      mdi-magnify
    </v-icon>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit("buscar")
    }
  }
};
</script>
<style>
.div-main {
  padding: 8px 8px 8px 8px;
  background-color: #2196f3;
  border-radius: 5px;
  cursor: pointer;
}
</style>
