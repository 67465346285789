<template>
  <v-container fluid>
    <form @submit.prevent="getClientes(true)">
      <v-row>
        <div class="espacado">
          <MultiSelect
            v-model="filtros.status"
            :options="status"
            @change="getClientes(true)"
            optionLabel="name"
            placeholder="Filtro por status"
            display="chip"
          />
        </div>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.cliente"
            separator=","
            @add="getClientes(true)"
            @remove="getClientes(true)"
            placeholder="Filtro por nome, documento, email"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col class="text-end">
          <v-tooltip bottom v-if="user.permissoes.adm_cliente">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                :to="{
                  name: 'addCliente',
                  params: { idEmpresa: empresaAtual.id }
                }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar Cliente</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :headers="headers"
              :items="clientes"
              :loading="carregando"
              loading-text="Carregando dados"
              :fixed-header="true"
              class="fill-height"
              width="500px"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                  class="ma-2 text-center"
                  :color="statusStyle(item.status)"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.documento`]="{ item }">
              {{ $masks.cpf(item.cpf) || $masks.cnpj(item.cnpj) }}
            </template>
            <template v-slot:[`item.endereco`]="{ item }">
            <span v-if="item.cep"
            >{{ item.cep }} - {{ item.endereco }} , {{ item.numero }},
              {{ item.cidade }} - {{ item.uf }}</span
            >
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-pagination
      v-model="pagination.page"
      :length="totalPaginas"
      :loading="carregando"
      @input="next"
      :total-visible="10"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  components: {
    Chips,
    MultiSelect,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      clientes: [],
      carregando: false,
      filtros: {
        cliente: this.$store.state.filtrosClientes.cliente,
        status: this.$store.state.filtrosClientes.status
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      status: [
        { name: "Ativo", code: "ATIVO" },
        { name: "Inativo", code: "INATIVO" }
      ],
      headers: [
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status"
        },
        {
          text: "Nome",
          align: "left",
          sortable: false,
          value: "nome"
        },
        {
          text: "Documento",
          align: "left",
          sortable: false,
          value: "documento"
        },
        {
          text: "Email",
          align: "left",
          sortable: false,
          value: "email"
        },
        {
          text: "Endereço",
          align: "left",
          sortable: false,
          value: "endereco"
        },
        {
          text: "Ações",
          value: "acoes"
        }
      ]
    };
  },
  mounted() {
    this.getClientes();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    async getClientes(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosClientes", {
        cliente: this.filtros.cliente,
        status: this.filtros.status
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;
      this.$axios
        .get(`/cliente/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              cliente: this.filtros.cliente.map(s => s.trim()),
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.clientes = response.data.data;
          this.pagination.total = response.data.count;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getClientes();
    },

    goToConsultPage(item) {
      this.$router.push({
        name: "viewCliente",
        params: {
          idCliente: item.id
        }
      });
    },

    goToEditPage(item) {
      this.$router.push({
        name: "editCliente",
        params: {
          idCliente: item.id
        }
      });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.cliente.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
  }
};
</script>

<style></style>
