import Vue from "vue";
import axios from "@/plugins/axios";
type Iheader = {
  [index: string]: string[];
};

const mixins = {
  data() {
    return {
      TIPO_USUARIO: {
        USUARIO: "Usuário",
        APLICACAO: "Aplicação"
      },
      TIPO_PESSOA: [
        {
          texto: "Pessoa Física",
          valor: "PESSOA_FISICA"
        },
        {
          texto: "Pessoa Jurídica",
          valor: "PESSOA_JURIDICA"
        },
        {
          texto: "Não Informado",
          valor: "NAO_INFORMADO"
        }
      ],
      TIPO_TRANSACAO: {
        CREDITO: "Crédito",
        BOLETO: "Boleto",
        DEBITO: "Débito",
        TICKET: "Ticket",
        PIX: "Pix",
        VOUCHER: "Voucher"
      },
      LINHAS_POR_PAGINA: [10, 20, 30, 40],
      TIPO_DOCUMENTO: {
        SELFIE: {
          texto: "Selfie",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        RG_FRENTE: {
          texto: "Frente do RG",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        RG_VERSO: {
          texto: "Verso do RG",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        CNH_FRENTE: {
          texto: "Frente da CNH",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        CNH_VERSO: {
          texto: "Verso da CNH",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        CCMEI: {
          texto: "CCMEI",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        PAGINA_CONTRATO_SOCIAL: {
          texto: "Contrato Social",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        PAGINA_ESTATUTO_SOCIAL: {
          texto: "Estatuto social",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        PAGINA_ATA_ELEICAO_DIRETORES: {
          texto: "Ata da eleição dos diretores",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        PAGINA_PROCURACAO: {
          texto: "Procuração",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        COMPROVANTE_ENDERECO: {
          texto: "Comprovante de Endereço",
          formato: "image/jpeg",
          tipo: "foto JPEG"
        },
        BALANCO_PATRIMONIAL: {
          texto: "Balanço Patrimonial",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        DRE: {
          texto: "Demonstrativo de Resultado do Exercício",
          formato: "application/pdf",
          tipo: "documento PDF"
        },
        DECLARACAO_FATURAMENTO: {
          texto: "Declaração de Faturamento",
          formato: "application/pdf",
          tipo: "documento PDF"
        }
      },
      TIPO_EMPRESA: [
        {
          texto: "Microempreendedor individual",
          valor: "MEI"
        },
        {
          texto: "Sociedade Limitada",
          valor: "LTDA"
        },
        {
          texto: "Empresário Individual de Responsabilidade Limitada",
          valor: "EIRELI"
        },
        {
          texto: "Micro empresa",
          valor: "ME"
        },
        {
          texto: "Sociedade Simples",
          valor: "SS"
        },
        {
          texto: "Sociedade Cooperativa",
          valor: "SC"
        },
        {
          texto: "Sociedade de Propósito Especifico",
          valor: "SPE"
        },
        {
          texto: "Sociedade por Ações",
          valor: "SA"
        },
        {
          texto: "Empresário individual",
          valor: "EI"
        },
        {
          texto: "Sociedade em Nome Coletivo",
          valor: "SNC"
        },
        {
          texto: "Sociedade em Comandita Simples",
          valor: "SCS"
        },
        {
          texto: "Sociedade em Comandita por Ações",
          valor: "SCA"
        },
        {
          texto: "Sociedade Limitada Unipessoal",
          valor: "SLU"
        }
      ],
      STATUS_EMPRESA: {
        ATIVA: {
          texto: "Ativa",
          cor: "green",
          type: "success"
        },
        INATIVA: {
          texto: "Inativa",
          cor: "#8b8b8b",
          type: "info"
        },
        SUSPENSA: {
          texto: "Suspensa",
          cor: "#d30060",
          type: "error"
        },
        AGUARDANDO_APROVACAO: {
          texto: "Em análise",
          cor: "#87CEFA",
          type: "info"
        },
        AGUARDANDO_APROVACAO_COMPLIANCE: {
          texto: "Em análise de compliance",
          cor: "#87CEFA",
          type: "info"
        },
        AGUARDANDO_CORRECAO_SOCIOS: {
          texto: "Corrigir quadro societário",
          cor: "#87CEFA",
          type: "warning"
        },
        AGUARDANDO_INFORMACOES: {
          texto: "Falta Informações",
          cor: "orange",
          type: "warning"
        },
        NEGADO: {
          texto: "Negado",
          cor: "black",
          type: "error"
        },
        NEGADO_COMPLIANCE: {
          texto: "Negado por compliance",
          cor: "black",
          type: "error"
        },
        AGUARDANDO_CORRECAO: {
          texto: "Corrigir dados",
          cor: "brown",
          type: "warning"
        },
        BLOQUEADA: {
          texto: "Bloqueada",
          cor: "red",
          type: "error"
        },
        AGUARDANDO_ASSINATURA_CONTRATO: {
          texto: "Aguardando assinatura de contrato",
          cor: "#2196f3",
          type: "warning"
        },
        AGUARDANDO_ANALISE_DOCUMENTO: {
          texto: "Análisando documentos",
          cor: "#2196f3",
          type: "warning"
        },
        AGUARDANDO_ANALISE_COMPLIANCE_MANUAL: {
          texto: "Aguardando analise compliance manual",
          cor: "#87CEFA",
          type: "info"
        },
        CREDENCIAMENTO_SPB: {
          texto: "Credenciando no SPB",
          cor: "#87CEFA",
          type: "info"
        },
        CRIANDO_CONTA_PAGAMENTO: {
          texto: "Criando conta pagamento",
          cor: "#87CEFA",
          type: "info"
        }
      },
      TIPO_EXTRATO: {
        CARGA: {
          texto: "Carga"
        },
        TRANSFERENCIA: {
          texto: "Transferência"
        },
        TRANSFERENCIA_PIX: {
          texto: "Transferência PIX"
        },
        TRANSFERENCIA_TEF: {
          texto: "Transferência TEF"
        },
        TAXA_TRANSFERENCIA: {
          texto: "Taxa de transferência"
        },
        DEPOSITO: {
          texto: "Depósito"
        },
        ESTORNO: {
          texto: "Estorno"
        },
        PAGAMENTO_COBRANCA: {
          texto: "Pagamento"
        },
        PAGAMENTO: {
          texto: "Pagamento"
        },
        COMPRA_DEBITO: {
          texto: "Compra no débito"
        },
        COMPRA_CREDITO: {
          texto: "Compra no crédito"
        },
        DEBITO_AUTOMATICO: {
          texto: "Debito automático"
        },
        BLOQUEIO: {
          texto: "Valor bloqueado"
        },
        DESBLOQUEIO: {
          texto: "Valor bloqueado"
        },
        TAXA_COBRANCA: {
          texto: "Taxa de cobrança"
        },
        RECEBIMENTO_COBRANCA: {
          texto: "Recebimento de cobrança"
        },
        RECEBIMENTO_PIX: {
          texto: "Recebimento de PIX"
        },
        RECEBIMENTO_TEF: {
          texto: "Recebimento de TEF"
        },
        AJUSTE: {
          texto: "Ajuste"
        },
        TARIFA: {
          texto: "Tarifa"
        },
        CONSUMO: {
          texto: "Consumo"
        }
      },
      STATUS_RECEBIVEIS: {
        LIQUIDADO: {
          texto: "Liquidado",
          cor: "indigo"
        },
        AGUARDANDO_FUNDOS: {
          texto: "Aguardando Fundos",
          cor: "orange"
        },
        SUSPENSO: {
          texto: "Suspenso",
          cor: "black"
        },
        ESTORNADO: {
          texto: "Estornado",
          cor: "purple"
        },
        PAGO: {
          texto: "Pago",
          cor: "green"
        },
        CHARGEBACK: {
          texto: "Chargeback",
          cor: "gray"
        },
        CHARGEBACK_REFUND: {
          texto: "Chargeback Refund",
          cor: "#7e96a1"
        },
        PAGAMENTO_PROGRAMADO:{
          texto: "Pagamento programado",
          cor: "#5800ff"
        }
      },
      STATUS_COBRANCA: {
        ATRASADO: {
          texto: "Atrasado",
          cor: "orange"
        },
        CANCELADO: {
          texto: "Cancelado",
          cor: "red"
        },
        REGULAR: {
          texto: "Regular",
          cor: "#7e96a1"
        },
        QUITADO: {
          texto: "Quitado",
          cor: "green"
        },
        ATIVO: {
          texto: "Ativo",
          cor: "#0889e7"
        },
        EXPIRADO: {
          texto: "Expirado",
          cor: "#424242"
        }
      },
      STATUS_CONTRATO: {
        ATRASADO: {
          texto: "Atrasado",
          cor: "red"
        },
        CANCELADO: {
          texto: "Cancelado",
          cor: "gray"
        },
        QUITADO: {
          texto: "Quitado",
          cor: "green"
        },
        ATIVO: {
          texto: "Ativo",
          cor: "#0889e7"
        },
        AGUARDANDO_ACEITE: {
          texto: "Aguardando aceite",
          cor: "#424242"
        }
      },
      OPCOES_PAGTO: {
        BOLETO: {
          texto: "Boleto"
        },
        PIX: {
          texto: "PIX"
        },
        CARTAO: {
          texto: "Cartão"
        },
        CASHIN: {
          texto: "Cash-in"
        },
        DEBITO_AUTOMATICO: {
          texto: "Débito automático"
        }
      },
      STATUS_ASSINATURA: {
        ATIVA: {
          texto: "Ativa",
          cor: "green"
        },
        PENDENTE: {
          texto: "Pendente de Pagamento",
          cor: "orange"
        },
        CANCELADA: {
          texto: "Cancelada",
          cor: "grey"
        },
        ENCERRADA: {
          texto: "Encerrada",
          cor: "grey"
        },
        FALHA_PAGAMENTO: {
          texto: "Falha no Pagamento",
          cor: "red"
        },
        DEGUSTACAO: {
          texto: "Degustação",
          cor: "#fbd20a"
        }
      },
      STATUS_NOTA: {
        ENVIAR: {
          texto: "Enviar",
          cor: "#7e96a1"
        },
        ENVIADA: {
          texto: "Enviada",
          cor: "#7e96a1"
        },
        AUTORIZADO: {
          texto: "Autorizado",
          cor: "green"
        },
        CANCELADO: {
          texto: "Cancelado",
          cor: "red"
        },
        ERRO_AUTORIZACAO: {
          texto: "Falha",
          cor: "orange"
        },
        PROCESSANDO_AUTORIZACAO: {
          texto: "Processando",
          cor: "#424242"
        }
      },
      TIPO_REMESSA: {
        REMESSA: {
          texto: "Arquivo Remessa",
          cor: "green"
        },
        RETORNO: {
          texto: "Arquivo Retorno",
          cor: "#87CEFA"
        }
      },
      STATUS_PARCELA_CONTRATO: {
        PENDENTE: {
          texto: "Pendente",
          cor: "orange"
        },
        ATRASADO: {
          texto: "Atrasado",
          cor: "red"
        },
        QUITADO: {
          texto: "Pago",
          cor: "green"
        }
      }
    };
  },
  methods: {
    statusStyle(status: string) {
      let color;
      switch (status) {
        case "INICIADA":
          color = "#0889e7";
          break;
        case "CANCELADO":
          color = "grey";
          break;
        case "FALHA":
          color = "red";
          break;
        case "PROTESTADO":
          color = "red";
          break;
        case "FALHA_PAGAMENTO":
          color = "red";
          break;
        case "NEGADA":
          color = "black";
          break;
        case "PENDENTE":
          color = "orange";
          break;
        case "PAGO":
          color = "green";
          break;
        case "ESTORNADO":
          color = "purple";
          break;
        case "CHARGEBACK":
          color = "brown";
          break;
        case "ATIVO":
          color = "green";
          break;
        case "INATIVO":
          color = "grey";
          break;
        case "ATIVA":
          color = "green";
          break;
        case "INATIVA":
          color = "grey";
          break;
        case "DEGUSTACAO":
          color = "#fbd20a";
          break;
        case "VENCIDO":
          color = "red";
          break;
        default:
          color = "transparent";
      }
      return color;
    },

    validarEmail(email: string) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },

    validarCNPJ(cnpj: string) {
      if (!cnpj) return false;

      cnpj = cnpj.replace(/[^\d]+/g, "");

      if (cnpj == "") return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
      )
        return false;

      // Valida DVs
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += (numeros.charAt(tamanho - i) as any) * pos--;
        if (pos < 2) pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != (digitos.charAt(0) as any)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += (numeros.charAt(tamanho - i) as any) * pos--;
        if (pos < 2) pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != (digitos.charAt(1) as any)) return false;

      return true;
    },

    async getCEP(
      cep: string
    ): Promise<
      | string
      | { endereco: any; bairro: any; cidade: any; uf: any; codigo_ibge: any }
    > {
      const cepLimpo = cep
        .replaceAll("-", "")
        .replaceAll(".", "")
        .replaceAll(" ", "");
      return await axios
        .get(`/public/endereco/${cepLimpo}`)
        .then(response => {
          const data = {
            endereco: response.data.logradouro,
            bairro: response.data.bairro,
            cidade: response.data.localidade,
            uf: response.data.uf,
            codigo_ibge: response.data.ibge
          };

          return data;
        })
        .catch(error => {
          throw new Error("Não foi possível encontrar o cep informado");
        });
    },
    parseToFloat(value: any): number {
      if (!value) return 0.0;
      if (typeof value === "number") return value;
      let validNumber = value.replace(".", "");
      validNumber = validNumber.replace(",", ".");
      return parseFloat(validNumber);
    },
    parseFloatToMoney(value: any): string {
      if (!value) return "0,00";
      return new Intl.NumberFormat("pt-br", {
        minimumFractionDigits: 2
      }).format(value);
    },
    validaCPF(strCPF: string) {
      strCPF = strCPF.replace(/\D+/g, "");

      let Soma = 0;
      let Resto;

      if (strCPF == "00000000000") return false;

      for (let i = 1; i <= 9; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;

      Soma = 0;
      for (let i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if (Resto == 10 || Resto == 11) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    sanitizeString(str: string) {
      return str.replace(/[^0-9]/g, "");
    },
    toCSVeDownload(fileName: string, data: string[]) {
      if (!Array.isArray(data)) return;

      if (data.length === 0) return;

      const header = Object.keys(data[0]);
      const csv = [
        header.join(";"),
        ...data.map(linhas => {
            return Object.values(linhas).map(coluna => String(coluna).replace('#','')).join(';');
        })
      ].join("\r\n");

      const encodedUri = encodeURI(csv);
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/plain;charset=utf-8," + encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    isCartaoValido(numero: string) {
      const number = numero.replace(/ /g, "");
      return number.length >= 15;

      // var sum = 0
      // for (var i = 0; i < number.length; i++) {
      //   var intVal = parseInt(number.substr(i, 1))
      //   if (i % 2 === 0) {
      //     intVal *= 2
      //     if (intVal > 9) {
      //       intVal = 1 + (intVal % 10)
      //     }
      //   }
      //   sum += intVal
      // }
      // //3785 178417 34277  rever para amex

      // return sum % 10 !== 0;
    }
  }
};

Vue.mixin(mixins);
