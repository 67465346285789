<template>
  <v-container fluid>
    <v-stepper alt-labels v-model="step">
      <v-stepper-header>
        <v-stepper-step
          :complete="step > 1"
          step="1"
          :color="step > 1 ? 'green' : 'primary'"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-office-building</v-icon> Dados
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="step > 2"
          step="2"
          :color="step > 2 ? 'green' : 'primary'"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
            Plano
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 3"
          step="3"
          :color="step > 3 ? 'green' : 'primary'"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-call-split</v-icon>
            Split
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :complete="step > 4"
          step="4"
          :color="step > 4 ? 'green' : 'primary'"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-wallet</v-icon>
            Pagamento
          </div>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="5"
          :complete="step === 5"
          :color="step === 5 ? 'green' : 'primary'"
        >
          <div class="d-flex align-center">
            <v-icon class="mr-2">mdi-check-bold</v-icon>
            Concluído
          </div>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container>
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <span class="text-subtitle-2">Quem está vendendo?</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex">
                    <AutoComplete
                        class="autocomplete-input"
                        v-model="assinatura.empresa"
                        :suggestions="empresasFiltradas"
                        @complete="searchEmpresa"
                        forceSelection
                        :field="itemTextEmpresa"
                        placeholder="Pesquise aqui..."
                    >
                      <template #item="slotProps">
                        <div>
                          <div>{{ slotProps.item.nome }}</div>
                          <div>{{ slotProps.item.razao_social }}</div>
                          <div>
                            CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                            {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                            {{
                              $masks.telefone(
                                  slotProps.item.telefone_celular
                              )
                            }}
                            E-mail: {{ slotProps.item.email }}
                          </div>
                        </div>
                      </template>
                    </AutoComplete>
                    <div v-if="assinatura.empresa" class="div-clearable" @click="assinatura.empresa = null">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </v-col>
                </v-row>
<!--                <v-row dense>-->
<!--                  <v-col>-->
<!--                    <v-autocomplete-->
<!--                      @change="onEmpresaSelected"-->
<!--                      :items="empresasUsuario"-->
<!--                      item-value="id"-->
<!--                      item-text="razao_social"-->
<!--                      v-model="assinatura.empresa"-->
<!--                      dense-->
<!--                      return-object-->
<!--                      :filter="customFilter"-->
<!--                    >-->
<!--                      <template v-slot:selection="{ item }">-->
<!--                        <span>-->
<!--                          {{ $masks.cnpj(item.cnpj) }} - {{ item.razao_social }}-->
<!--                        </span>-->
<!--                      </template>-->
<!--                      <template v-slot:item="{ item }">-->
<!--                        <v-list-item-content>-->
<!--                          <v-list-item-title>{{-->
<!--                            item.nome_fantasia-->
<!--                          }}</v-list-item-title>-->
<!--                          <v-list-item-subtitle>-->
<!--                            {{ $masks.cnpj(item.cnpj) }} - -->
<!--                            {{ item.razao_social }}-->
<!--                          </v-list-item-subtitle>-->
<!--                        </v-list-item-content>-->
<!--                      </template>-->
<!--                    </v-autocomplete>-->
<!--                  </v-col>-->
<!--                </v-row>-->
                <v-row dense>
                  <v-col>
                    <div
                      v-if="assinatura.empresa && assinatura.empresa.razao_social"
                      class="d-flex flex-column custom-card"
                    >
                      <span>
                        {{ assinatura.empresa.nome_fantasia }}
                      </span>
                      <span>
                        {{ assinatura.empresa.razao_social }}
                      </span>
                      <span class="text-caption">
                        {{ assinatura.empresa.cnpj }}
                      </span>
                      <span class="text-caption">
                        {{ assinatura.empresa.endereco }},
                        {{ assinatura.empresa.numero }} -
                        {{ assinatura.empresa.cidade }} ,
                        {{ assinatura.empresa.uf }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical />
              <v-col>
                <v-row>
                  <v-col class="text-start">
                    <span class="text-subtitle-2"
                      >Quem vai pagar a asinatura?</span
                    >
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="d-flex">
                    <AutoComplete
                        :disabled="!assinatura.empresa"
                        class="autocomplete-input"
                        v-model="assinatura.titular"
                        :suggestions="clientesUsuario"
                        @complete="searchCliente"
                        forceSelection
                        :field="itemTextCliente"
                        placeholder="Pesquise aqui..."
                    >
                      <template #item="slotProps">
                        <div>
                          <div>{{ slotProps.item.nome }}</div>
                          <div>{{ slotProps.item.razao_social }}</div>
                          <div>
                            CPF/CNPJ: {{ $masks.cpf(slotProps.item.cpf) }}
                            {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                            {{
                              $masks.telefone(
                                  slotProps.item.telefone_celular
                              )
                            }}
                            E-mail: {{ slotProps.item.email }}
                          </div>
                        </div>
                      </template>
                    </AutoComplete>
                    <div v-if="assinatura.titular" class="div-clearable" @click="assinatura.titular = null">
                      <v-icon>mdi-close</v-icon>
                    </div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            fab
                            dark
                            small
                            color="primary"
                            :class="assinatura.titular ? 'ml-10' : 'ml-3'"
                            class="mt-1"
                            @click="openDialogCliente('create')"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Criar</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
<!--                <v-row dense>-->
<!--                  <v-col cols="10">-->
<!--                    <v-autocomplete-->
<!--                      :items="clientesUsuario"-->
<!--                      item-value="id"-->
<!--                      item-text="nome"-->
<!--                      v-model="assinatura.titular"-->
<!--                      dense-->
<!--                      clearable-->
<!--                      return-object-->
<!--                      :filter="customFilter"-->
<!--                    >-->
<!--                      <template v-slot:selection="{ item }">-->
<!--                        <span>-->
<!--                          {{ $masks.cpf(item.cpf)-->
<!--                          }}{{ $masks.cnpj(item.cnpj) }} - {{ item.nome }}-->
<!--                        </span>-->
<!--                      </template>-->
<!--                      <template v-slot:item="{ item }">-->
<!--                        <v-list-item-content>-->
<!--                          <v-list-item-title>{{ item.nome }}</v-list-item-title>-->
<!--                          <v-list-item-subtitle>-->
<!--                            CPF/CNPJ: {{ $masks.cpf(item.cpf)-->
<!--                            }}{{ $masks.cnpj(item.cnpj) }} Tel:-->
<!--                            {{ item.telefone_celular }} E-mail: {{ item.email }}-->
<!--                          </v-list-item-subtitle>-->
<!--                        </v-list-item-content>-->
<!--                      </template>-->
<!--                    </v-autocomplete>-->
<!--                  </v-col>-->
<!--                  <v-col>-->
<!--                    <v-tooltip-->
<!--                      bottom-->
<!--                      v-if="user.permissoes.adm_cliente && assinatura.empresa"-->
<!--                    >-->
<!--                      <template v-slot:activator="{ on, attrs }">-->
<!--                        <v-btn-->
<!--                          v-bind="attrs"-->
<!--                          v-on="on"-->
<!--                          fab-->
<!--                          dark-->
<!--                          small-->
<!--                          color="primary"-->
<!--                          :to="{-->
<!--                            name: 'AssinaturaNovoCliente',-->
<!--                            params: {-->
<!--                              backRoute: 'addAssinatura',-->
<!--                              idEmpresa: assinatura.empresa.id-->
<!--                            }-->
<!--                          }"-->
<!--                        >-->
<!--                          <v-icon>mdi-plus</v-icon>-->
<!--                        </v-btn>-->
<!--                      </template>-->
<!--                      <span>Adicionar Cliente</span>-->
<!--                    </v-tooltip>-->
<!--                  </v-col>-->
<!--                </v-row>-->

                <v-row dense v-if="assinatura.titular && assinatura.titular.nome">
                  <v-col>
                    <div class="d-flex flex-column custom-card">
                      <span>
                        {{ assinatura.titular.nome }}
                      </span>
                      <span class="text-caption">
                        {{
                          $masks.telefone(assinatura.titular.telefone_celular)
                        }}
                      </span>
                      <span class="text-caption">
                        {{ assinatura.titular.email }}
                      </span>
                      <span
                        class="text-caption"
                        v-if="assinatura.titular.endereco"
                      >
                        {{ assinatura.titular.endereco }},
                        {{ assinatura.titular.numero }} -
                        {{ assinatura.titular.cidade }} ,
                        {{ assinatura.titular.uf }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="d-flex align-end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          text
                          small
                          color="primary"
                          @click="
                                openDialogCliente('edit', assinatura.titular)
                              "
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar Cliente</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col class="text-end s">
                <v-btn
                  class="primary"
                  :disabled="!avancaStep1"
                  @click="() => this.step++"
                >
                  Avançar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-container>
            <v-row class="d-flex justify-center">
              <v-col cols="8">
                <v-row dense>
                  <v-col class="text-center text-subtitle-2">
                    Mensalidade
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="text-center">
                    <span class="text-caption">R$</span>
                    <span class="text-h3">{{
                      valorPlano | formatMoney({ minimumFractionDigits: 2 })
                    }}</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <span class="text-subtitle-2"> Plano escolhido </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <Dropdown
                    v-model="assinatura.plano"
                    :options="planos"
                    data-key="id"
                    :filter="true"
                    optionLabel="descricao"
                  >
                    <template #value="slot">
                      <div v-if="slot.value">
                        {{ slot.value.descricao }}
                      </div>
                      <span v-else>
                        Selecione um plano
                      </span>
                    </template>

                    <template #option="slot">
                      <div>
                        {{ slot.option.descricao }}
                      </div>
                      <div>
                        <span class="text-caption">{{
                          slot.option.descricao
                        }}</span>
                      </div>
                    </template>
                  </Dropdown>
                </v-row>
                <v-row dense v-if="assinatura.plano">
                  <v-col>
                    <span class="text-subtitle-2">Serviços do plano</span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <v-simple-table v-if="assinatura.plano">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">QTD</th>
                            <th class="text-left">SKU</th>
                            <th class="text-left">Serviço</th>
                            <th class="text-left">Valor</th>
                            <th class="text-left">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in assinatura.plano.itens"
                            :key="item.servico.id"
                          >
                            <td>{{ item.quantidade }}</td>
                            <td>{{ item.servico.sku }}</td>
                            <td>{{ item.servico.nome }}</td>
                            <td>
                              R$
                              {{
                                item.valor
                                  | formatMoney({ minimumFractionDigits: 2 })
                              }}
                            </td>
                            <td>
                              R$
                              {{
                                (
                                  parseFloat(item.quantidade * item.valor) -
                                  item.desconto
                                ).toFixed(2)
                                  | formatMoney({ minimumFractionDigits: 2 })
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col>
                <v-btn class="btn_cancela" @click="() => this.step--">
                  Voltar
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  class="primary"
                  :disabled="!avancaStep2"
                  @click="() => this.step++"
                >
                  Avançar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="3">
          <v-container fluid>
            <v-row class="d-flex justify-center">
              <v-col cols="10">
                <v-row dense>
                  <v-col class="text-center text-subtitle-2">
                    Valor da Cobrança
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="text-center">
                    <span class="text-caption" style="display:inline;">R$</span>
                    <span class="text-h3">{{
                      valorPlano | formatMoney({ minimumFractionDigits: 2 })
                    }}</span>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <span class="text-h5"> Splits </span>
                  </v-col>
                </v-row>
                <v-row dense v-if="!user.permissoes.adm_split">
                  <v-col>
                    <v-alert colored-border type="info" elevation="2">
                      Seu usuário não possui permissão para configurar splits,
                      clique em avançar para pular esta etapa.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row dense v-if="user.permissoes.adm_split">
                  <v-col cols="10">
                    <v-autocomplete
                      :items="empresasMarketPlace"
                      item-value="id"
                      item-text="razao_social"
                      v-model="destinatarioSplit"
                      label="Destinatarios"
                      dense
                      return-object
                    >
                      <template v-slot:selection="{ item }">
                        <span>
                          {{ $masks.cnpj(item.cnpj) }} -
                          {{ item.razao_social }}
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.nome_fantasia
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $masks.cnpj(item.cnpj) }} -
                            {{ item.razao_social }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          fab
                          dark
                          small
                          color="primary"
                          @click="addSplit"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Adicionar Split</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <v-row dense v-if="user.permissoes.adm_split">
                  <v-col>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left" width="450">Empresa</th>
                            <th class="text-left" width="120">
                              Percentual
                            </th>
                            <th class="text-left">Paga Taxa</th>
                            <th class="text-left">Responsavel Divisão</th>
                            <th class="text-left">Total</th>
                            <th class="text-left">Remover</th>
                          </tr>
                        </thead>
                        <tbody v-if="assinatura">
                          <tr
                            v-for="(split, index) in assinatura.splits"
                            :key="split.id"
                          >
                            <td>
                              <div>
                                <div>
                                  {{ split.recebedor.cnpj }} -
                                  <span class="nome-fantasia">{{
                                    split.recebedor.nome_fantasia
                                  }}</span>
                                </div>
                                <div>
                                  {{ split.recebedor.razao_social }}
                                </div>
                                <div>
                                  <span class="endereco">{{
                                    split.recebedor.enderecoCompleto
                                  }}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                mode="decimal"
                                :minFractionDigits="2"
                                :maxFractionDigits="2"
                                v-model="split.valor"
                                suffix=" %"
                                :max="100"
                                :min="0"
                                :allowEmpty="false"
                              />
                            </td>
                            <td>
                              <v-switch
                                v-model="split.paga_taxa_transacao"
                                :label="
                                  split.paga_taxa_transacao ? 'Sim' : 'Não'
                                "
                              />
                            </td>
                            <td>
                              <v-switch
                                v-model="split.paga_resto_divisao_taxa"
                                :label="
                                  split.paga_resto_divisao_taxa ? 'Sim' : 'Não'
                                "
                              />
                            </td>
                            <td>
                              <div>
                                R$
                                {{
                                  valorSplit(split)
                                    | formatMoney({
                                      minimumFractionDigits: 2
                                    })
                                }}
                              </div>
                            </td>
                            <td>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    text
                                    small
                                    @click="openModalRemoveSplit(split, index)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remover Item</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="2"
                class="d-flex align-self-center"
                v-if="user.permissoes.adm_split"
              >
                <v-progress-circular
                  :rotate="360"
                  :size="200"
                  :width="10"
                  :value="totalPercSplit"
                  :color="totalPercSplit <= 100 ? 'primary' : 'red'"
                >
                  <span class="text-h5 font-weight-bold">
                    {{ totalPercSplit }}%
                  </span>
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-row dense class="mt-4">
              <v-col>
                <v-btn class="btn_cancela" @click="() => this.step--">
                  Voltar
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  class="primary"
                  :disabled="!avancaStep3"
                  @click="() => this.step++"
                >
                  Avançar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-container>
            <v-row class="d-flex">
              <v-col cols="2">
                <span class="text-caption">Forma de pagamento</span>
                <v-select
                  v-model="assinatura.forma_pagamento"
                  :items="['BOLETO', 'CARTAO']"
                  required
                  @change="
                    (assinatura.juros_atraso_boleto = 0),
                      (assinatura.multa_atraso_boleto = 0)
                  "
                >
                </v-select>
              </v-col>
              <v-col class="d-flex flex-column" cols="2">
                <span class="text-caption">Juros a.m. %</span>
                <InputNumber
                  class="mt-3"
                  :disabled="assinatura.forma_pagamento != 'BOLETO'"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  v-model="assinatura.juros_atraso_boleto"
                  suffix=" %"
                  :max="100"
                  :min="0"
                  :allowEmpty="true"
                />
              </v-col>

              <v-col class="d-flex flex-column" cols="2">
                <span class="text-caption">Multa %</span>
                <InputNumber
                  class="mt-3"
                  :disabled="assinatura.forma_pagamento != 'BOLETO'"
                  mode="decimal"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  v-model="assinatura.multa_atraso_boleto"
                  suffix=" %"
                  :max="100"
                  :min="0"
                  :allowEmpty="true"
                />
              </v-col>

              <v-divider vertical />

              <v-col cols="2">
                <span class="text-caption">Forma de cobrança</span>
                <v-switch
                  v-model="recorrente"
                  :label="recorrente ? 'Recorrente' : 'Limitada'"
                  @change="
                    recorrente ? (assinatura.quantidade_cobranca = 0) : null
                  "
                />
              </v-col>

              <v-col cols="1" v-if="!recorrente">
                <span class="text-caption">Quantidade</span>
                <InputNumber
                  mode="decimal"
                  v-model="assinatura.quantidade_cobranca"
                  :max="60"
                  :min="1"
                  :allowEmpty="false"
                />
              </v-col>

              <v-col cols="2">
                <span class="text-caption">Emitir NFS-e</span>
                <v-switch
                  v-model="assinatura.emitir_nfse"
                  :label="assinatura.emitir_nfse ? 'Sim' : 'Não'"
                />
              </v-col>
            </v-row>
            <v-row class="d-flex">
              <v-col cols="4">
                <v-textarea
                  outlined
                  rows="3"
                  counter="70"
                  label="Observações"
                  v-model="assinatura.observacoes"
                >
                </v-textarea>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col>
                <v-btn class="btn_cancela" @click="() => this.step--">
                  Voltar
                </v-btn>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  class="primary"
                  :disabled="!avancaStep4"
                  @click="openModalConfirmCobranca"
                >
                  Gerar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="5">
          <v-container v-if="assinatura.id">
            <v-row class="d-flex">
              <v-col cols="4" class="d-flex flex-column py-3">
                <span class="headline font-weight-bold ">
                  Protocolo #{{ assinatura.protocolo }}
                </span>
                <span class="caption">
                  Recorrência
                  {{
                    assinatura.quantidade_cobranca == 0
                      ? "ilimitada"
                      : `${assinatura.quantidade_cobranca} cobranças`
                  }}
                </span>
                <span class="caption">
                  Criado
                  {{
                    assinatura.data_cadastro
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                  }}
                </span>
                <span class="caption">
                  Atualizado
                  {{
                    assinatura.data_atualizada
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                  }}
                </span>
                <span class="caption">
                  Válidade
                  {{
                    assinatura.data_validade
                      | formatDate({ dateStyle: "short" })
                  }}
                </span>
                <span class="caption" v-if="assinatura.data_fim_degustacao">
                  Termino degustação
                  {{
                    assinatura.data_fim_degustacao
                      | formatDate({ dateStyle: "short" })
                  }}
                </span>
              </v-col>

              <v-divider vertical></v-divider>
              <v-col cols="4" class="text-center pa-0">
                <p class="headline font-weight-bold">
                  {{ assinatura.plano.descricao }}
                </p>
                <span class="caption font-weight-bold green--text">R$</span>
                <span class="text-h3 font-weight-bold">
                  {{
                    valorPlano | formatMoney({ minimumFractionDigits: 2 })
                  }}</span
                >
              </v-col>

              <v-divider vertical></v-divider>

              <v-col class="text-center pa-0">
                <v-chip
                  class="ma-2 text-center"
                  :color="STATUS_ASSINATURA[assinatura.status].cor"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
                >
                  {{ STATUS_ASSINATURA[assinatura.status].texto }}
                </v-chip>
                <p class="text-h6 font-weight-bold">
                  <i
                    v-if="assinatura.forma_pagamento === 'CARTAO'"
                    class="far fa-credit-card"
                  ></i>
                  <i
                    v-if="assinatura.forma_pagamento === 'BOLETO'"
                    class="far fa-file-alt"
                  ></i>
                  {{
                    assinatura.forma_pagamento === "CARTAO"
                      ? "Cartão"
                      : "Boleto"
                  }}
                </p>
                <p v-if="assinatura.metodo === 'CARTAO'">
                  {{ assinatura.cartao.first_digits }} *** **** ****
                  {{ assinatura.cartao.last_digits }}
                </p>
              </v-col>
            </v-row>

            <v-row class="d-flex">
              <v-col cols="6" class="d-flex flex-column py-3">
                <p class="text-h6 font-weight-bold">Titular</p>
                <div
                  class="d-flex flex-column custom-card"
                  v-if="assinatura.titular"
                >
                  <span>
                    {{ assinatura.titular.nome }}
                  </span>
                  <span class="text-caption">
                    {{ $masks.telefone(assinatura.titular.telefone_celular) }}
                  </span>
                  <span class="text-caption">
                    {{ assinatura.titular.email }}
                  </span>
                  <span class="text-caption">
                    {{ assinatura.titular.endereco }},
                    {{ assinatura.titular.numero }} -
                    {{ assinatura.titular.cidade }} ,
                    {{ assinatura.titular.uf }}
                  </span>
                </div>

                <p class="text-h6 font-weight-bold" style="margin-top: 0.5rem">
                  Emissor
                </p>
                <div
                  v-if="assinatura.empresa"
                  class="d-flex flex-column custom-card mb-4"
                >
                  <span>
                    {{ assinatura.empresa.nome_fantasia }}
                  </span>
                  <span>
                    {{ assinatura.empresa.razao_social }}
                  </span>
                  <span class="text-caption">
                    {{ assinatura.empresa.cnpj }}
                  </span>
                  <span class="text-caption">
                    {{ assinatura.empresa.endereco }},
                    {{ assinatura.empresa.numero }} -
                    {{ assinatura.empresa.cidade }} ,
                    {{ assinatura.empresa.uf }}
                  </span>
                </div>
              </v-col>
              <v-col cols="6" class="text-center">
                <p class="text-h6 font-weight-bold">Cobrança</p>

                <v-card
                  outlined
                  color="green"
                  v-if="
                    assinatura.ultima_cobranca &&
                      assinatura.ultima_cobranca.tipo === 'BOLETO'
                  "
                >
                  <v-card-text class="grey lighten-4 pa-0">
                    <v-row dense class="text-start">
                      <v-col class="text-start px-4" cols="2">
                        <span class="text-subtitle-1 font-italic">Método</span>
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.tipo
                        }}</span>
                      </v-col>
                      <v-col class="text-start px-4" cols="3">
                        <span class="text-subtitle-1 font-italic">Status</span>
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.status
                        }}</span>
                      </v-col>
                      <v-col class="text-start px-4" cols="3">
                        <span class="text-subtitle-1 font-italic">Valor</span>
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold"
                          >R$
                          {{
                            assinatura.ultima_cobranca.valor
                              | formatMoney({ minimumFractionDigits: 2 })
                          }}</span
                        >
                      </v-col>
                      <v-col class="text-start px-4" cols="3">
                        <span class="text-subtitle-1 font-italic"
                          >Vencimento</span
                        >
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.vencimento_boleto
                            | formatDate({ dateStyle: "short" })
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row dense class="text-start">
                      <v-col class="text-start px-4" cols="5">
                        <span class="text-subtitle-1 font-italic">Cliente</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.cliente.nome
                        }}</span>
                      </v-col>
                      <v-col class="text-start px-4" cols="3">
                        <span class="text-subtitle-1 font-italic"
                          >Documento</span
                        >
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold"
                          >{{ assinatura.ultima_cobranca.cliente.cpf
                          }}{{ assinatura.ultima_cobranca.cliente.cnpj }}</span
                        >
                      </v-col>
                      <v-col class="text-start px-4" cols="3">
                        <span class="text-subtitle-1 font-italic"
                          >Telefone</span
                        >
                        <v-divider width="200px" class="mb-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.cliente.telefone_celular
                        }}</span>
                      </v-col>
                      <v-col class="text-start px-4" cols="8">
                        <span class="text-subtitle-1 font-italic">E-mail</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2 font-weight-bold">{{
                          assinatura.ultima_cobranca.cliente.email
                        }}</span>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <Button
                          icon="pi pi-external-link"
                          label="Link de pagamento"
                          @click="openUrl"
                          style="width:20rem;margin: 0.5rem;"
                          class="p-button-outlined"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col class="text-center px-4">
                        <span class="text-subtitle-1 font-weight-bold">{{
                          assinatura.ultima_cobranca.bar_code_boleto
                        }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  outlined
                  color="green"
                  class="cartao"
                  v-if="
                    assinatura.ultima_cobranca &&
                      assinatura.ultima_cobranca.tipo === 'CREDITO'
                  "
                >
                  <v-card-text class="grey lighten-4 pa-0">
                    <v-row dense>
                      <v-col class="text-start pl-6 py-2">
                        <i
                          class="far fa-credit-card green--text"
                          style="font-size: 2.5em"
                        ></i>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col cols="3" class="text-start px-4">
                        <span class="overline font-italic">Transação</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-1 font-weight-bold">{{
                          assinatura.ultima_cobranca.id
                        }}</span>
                      </v-col>

                      <v-col cols="3" class="text-start px-4">
                        <span class="overline font-italic">Status</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2">{{
                          assinatura.ultima_cobranca.status
                        }}</span>
                      </v-col>

                      <v-col cols="3" class="text-start px-4">
                        <span class="overline font-italic">Valor</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2"
                          >R$
                          {{
                            assinatura.ultima_cobranca.valor
                              | formatMoney({ minimumFractionDigits: 2 })
                          }}</span
                        >
                      </v-col>

                      <v-col class="text-start px-4">
                        <span class="overline font-italic">Data</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2">{{
                          assinatura.ultima_cobranca.data_criacao
                            | formatDate({
                              dateStyle: "short",
                              timeStyle: "short"
                            })
                        }}</span>
                      </v-col>
                    </v-row>

                    <v-row dense class="text-start">
                      <v-col cols="6" class="text-start px-4">
                        <span class="overline font-italic">Portador</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-1 font-weight-bold">{{
                          assinatura.ultima_cobranca.card_holder_name
                        }}</span>
                      </v-col>

                      <v-col class="text-start px-4">
                        <span class="overline font-italic">Número</span>
                        <v-divider class="mb-2 mr-2"></v-divider>
                        <span class="text-subtitle-2"
                          >{{
                            assinatura.ultima_cobranca.card_first_digits
                          }}
                          *** **** ****{{
                            assinatura.ultima_cobranca.card_last_digits
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

                <v-card
                  outlined
                  class="cartao"
                  v-if="!assinatura.ultima_cobranca"
                >
                  <v-row>
                    <v-col>
                      <Button
                        icon="pi pi-external-link"
                        label="Link de pagamento"
                        @click="openUrl"
                        style="width:20rem;margin: 0.5rem;"
                        class="p-button-outlined"
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col class="text-end">
                <v-btn class="primary" @click="criarNovaAssinatura">
                  Nova assinatura
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <DialogCliente
        :showDialog="clienteDialog.showDialog"
        :id-empresa="empresaAtual.id"
        :id="clienteDialog.id"
        :mode="clienteDialog.mode"
        @salva-dados="saveCliente"
        @cancela-dialog="clienteDialog.showDialog = false"
    />

  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import AutoComplete from "primevue/autocomplete";
import DialogCliente from "@/components/Dialogs/DialogCliente.vue";

export default {
  name: "CriaAssinatura",
  props: {
    cliente: Object
  },
  components: {
    Dropdown,
    InputNumber,
    Button,
    AutoComplete,
    DialogCliente
  },
  data() {
    return {
      step: 1,
      assinatura: {
        forma_pagamento: "BOLETO",
        quantidade_cobranca: 0,
        juros_atraso_boleto: 0,
        multa_atraso_boleto: 0,
        empresa: null,
        titular: null,
        plano: null,
        splits: [],
        observacoes: ""
      },
      empresasUsuario: [],
      empresasFiltradas: [],
      clientesUsuario: [],
      planos: [],
      recorrente: true,
      link: "",
      erros: [],
      empresasMarketPlace: [],
      destinatarioSplit: null,
      itens: [],
      clienteDialog: {
        id: null,
        showDialog: false,
        mode: ""
      },
    };
  },

  mounted() {
    this.getEmpresas();
    // if (this.cliente) {
    //   this.assinatura.titular = this.cliente;
    //   this.getEmpresa(this.assinatura.titular.empresa_id);
    // } else {
    //   this.getEmpresa(this.empresaAtual.id);
    // }
  },
  computed: {
    ...mapState(["user", "empresaAtual"]),
    avancaStep1() {
      return this.assinatura.empresa && this.assinatura.titular;
    },
    avancaStep2() {
      return this.assinatura.plano != null;
    },
    avancaStep3() {
      if (!this.user.permissoes.adm_split) return true;

      if (this.assinatura.splits == null || this.assinatura.splits.length == 0)
        return true;

      if (this.totalPercSplit > 100.0 || this.totalPercSplit < 100.0) {
        return false;
      }
      let pagaTaxa = false;
      let pagaDivisao = false;
      this.assinatura.splits.forEach(split => {
        pagaTaxa = split.paga_taxa_transacao ? true : pagaTaxa;
        pagaDivisao = split.paga_resto_divisao_taxa ? true : pagaDivisao;
      });

      if (!pagaTaxa || !pagaDivisao) return false;

      return true;
    },
    avancaStep4() {
      return (
        this.assinatura.plano != null &&
        this.assinatura.empresa != null &&
        this.assinatura.titular != null &&
        this.assinatura.forma_pagamento
      );
    },
    totalCobranca() {
      let totalCobranca = 0;
      this.itens.map(item => {
        if (!item.quantidade) {
          item.total = 0;
        } else {
          item.total = item.quantidade * this.parseToFloat(item.valor);
        }

        totalCobranca += item.total;

        return item;
      });

      return parseFloat(totalCobranca.toFixed(2));
    },
    totalPercSplit() {
      if (this.assinatura == null || !this.assinatura.splits) return 0;

      const total = this.assinatura.splits
        .reduce((accumulator, obj) => {
          return accumulator + this.parseToFloat(obj.valor ? obj.valor : 0);
        }, 0.0)
        .toFixed(2);

      return total == 100.0 ? 100 : total;
    },
    valorPlano() {
      let total = 0.0;
      if (this.assinatura.plano && this.assinatura.plano.itens) {
        this.assinatura.plano.itens.forEach(item => {
          total =
            parseFloat(total) +
            (parseFloat(item.quantidade * item.valor) - item.desconto);
        });
      }
      return total.toFixed(2);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    criarNovaAssinatura() {
      this.assinatura = {
        forma_pagamento: "BOLETO",
        quantidade_cobranca: 0,
        juros_atraso_boleto: 0,
        multa_atraso_boleto: 0,
        empresa: this.assinatura.empresa,
        titular: null,
        plano: null,
        observacoes: ""
      };
      this.step = 1;
    },
    customFilter(item, queryText) {
      if (!queryText) return false;

      const razaoSocial = item && item.razao_social ? item.razao_social.toLowerCase() : '';
      const cnpj = item && item.cnpj ? this.$masks.cnpj(item.cnpj).toLowerCase() : '';
      const nomeFantasia = item && item.nome_fantasia ? item.nome_fantasia.toLowerCase() : '';
      const nome = item && item.nome ? item.nome.toLowerCase() : '';
      const cpf = item && item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return (
          razaoSocial.includes(searchText) ||
          cnpj.includes(searchText) ||
          nomeFantasia.includes(searchText) ||
          nome.includes(searchText) ||
          cpf.includes(searchText)
      );
    },
    onEmpresaSelected() {
      //this.getClientes();
      this.getPlanos();
      this.getEmpresasMarketPlace();
      this.assinatura.titular = null;
      this.assinatura.plano = null;
    },
    async getEmpresa(id) {
      this.$axios
        .get(`/empresa/${id}`)
        .then(response => {
          this.assinatura.empresa = response.data;
          this.onEmpresaSelected();
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresa",
            text: error.response.data
          });
        });
    },
    async getEmpresas() {
      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresasUsuario = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });

          const empresaSelecionada = this.empresasUsuario.filter((item) => item.id === this.empresaAtual.id);
          this.empresasFiltradas = this.empresasUsuario;

          if(empresaSelecionada && empresaSelecionada.length > 0) {
            this.assinatura.empresa = empresaSelecionada[0];
            this.onEmpresaSelected();
          } else {
            this.getEmpresa(this.empresaAtual.id);
          }


        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },
    async getClientes(filtro) {
      return this.$axios
          .get(`/cliente/empresa/${this.assinatura.empresa.id}/listar`,{
            headers: {
              filtros: JSON.stringify({
                cliente: filtro ? [filtro.trim()]: [],
                status: ['ATIVO']
              }),
              noloading: true
            },
            params: {
              first: 0,
              limit: 20,
              ordem: "DESC"
            }
          })
        .then(response => {
          this.clientesUsuario = response.data.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Clientes",
            text: error.response.data
          });
        });
    },
    async getPlanos() {
      this.planos = [];
      if (this.assinatura.empresa == null) {
        return;
      }
      await this.$axios
        .get(`/plano/empresa/${this.assinatura.empresa.id}/listar`,
        {
          headers: {
            filtros: JSON.stringify({
              status: ['ATIVO']
            })
          }
        })
        .then(response => {
          this.planos = response.data.data;
          if(this.planos.length === 1) {
            this.assinatura.plano = this.planos[0];
          }
        });
    },
    async save() {
      this.closeDialogConfirm();
      const data = { ...this.assinatura };
      data.titular = { id: this.assinatura.titular.id };
      data.empresa = { id: this.assinatura.empresa.id };

      this.$axios
        .post(`/assinatura`, data)
        .then(async response => {
          this.assinatura = response.data;
          this.step++;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Criar assinatura",
            text: error.response.data
          });
        });
    },
    openModalConfirmCobranca() {
      this.openDialogConfirm({
        title: "Nova assinatura",
        text: `Confirma a criação da assinatura de ${this.assinatura.empresa.razao_social} para ${this.assinatura.titular.nome}
        no plano ${this.assinatura.plano.descricao} valor de R$ ${this.valorPlano}?`,
        confirma: this.save.bind(),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    openUrl() {
      const url = `http://${window.location.host}/assinaturas/${this.assinatura.id}/pagamento`;
      navigator.clipboard?.writeText(url);
      const win = window.open(url, "_blank");
      if (win) {
        win.focus();
      } else {
        alert("Desabilite o bloqueio de pop-ups para abrir o boleto");
      }
    },
    addSplit() {
      if (!this.destinatarioSplit || !this.destinatarioSplit.id) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Selecione a empresa de destino do split"
        });
        return;
      }

      const findSplit = this.assinatura.splits.some(
        split => split.recebedor.id === this.destinatarioSplit.id
      );
      if (findSplit) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Empresa já está adicionada à lista de splits."
        });
        return;
      }
      const splitData = {
        recebedor: {
          id: this.destinatarioSplit.id,
          razao_social: this.destinatarioSplit.razao_social,
          nome_fantasia: this.destinatarioSplit.nome_fantasia,
          cnpj: this.destinatarioSplit.cnpj,
          enderecoCompleto: this.destinatarioSplit.enderecoCompleto
        },
        valor: 0,
        metodo: "PERCENTUAL",
        paga_taxa_transacao: this.destinatarioSplit.id == this.empresaAtual.id,
        paga_resto_divisao_taxa:
          this.destinatarioSplit.id == this.empresaAtual.id
      };
      this.assinatura.splits.push(splitData);
      this.destinatarioSplit = null;
    },
    openModalRemoveSplit(split, index) {
      this.openDialogConfirm({
        title: "Remover Split",
        text: `Deseja realmente o split da empresa ${split.recebedor.razao_social}?`,
        confirma: this.removeSplit.bind(this, index),
        cancela: this.closeDialogConfirm.bind()
      });
    },
    async getEmpresasMarketPlace() {
      if (!this.assinatura.empresa || !this.assinatura.empresa.id) {
        this.assinatura.empresa = [];
      }

      return this.$axios
        .get(`/empresa/${this.assinatura.empresa.id}/marketplace?status=ATIVA`)
        .then(response => {
          this.empresasMarketPlace = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },
    valorSplit(split) {
      if (!split || !split.valor) return 0.0;
      return ((split.valor * this.totalCobranca) / 100).toFixed(2);
    },
    removeSplit(index) {
      this.assinatura.splits.splice(index, 1);
      this.closeDialogConfirm();
    },

    searchCliente(event) {
      const search = event.query.trim();

      if (search) {
        this.getClientes(search);
      }

    },

    itemTextCliente(item) {
      if (item.cpf) {
        return `${item.nome} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.nome} - ${this.$masks.cnpj(item.cnpj)}`;
      }

    },

    searchEmpresa(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradas = this.empresasUsuario.filter(item => {
          const razaoSocial = item.razao_social
              ? item.razao_social.toLowerCase()
              : "";
          const cnpj = item.cnpj
              ? this.$masks.cnpj(item.cnpj).toLowerCase()
              : "";
          const nomeFantasia = item.nome_fantasia
              ? item.nome_fantasia.toLowerCase()
              : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
              razaoSocial.includes(queryText) ||
              cnpj.includes(queryText) ||
              nomeFantasia.includes(queryText) ||
              nome.includes(queryText) ||
              cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradas = [...this.empresasUsuario];
      }

      // const search = event.query.trim();
      //
      // if(search) {
      //   this.getEmpresas(search);
      // }

    },

    itemTextEmpresa(item) {
      if (item.cpf) {
        return `${item.razao_social} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.razao_social} - ${this.$masks.cnpj(item.cnpj)}`;
      }

    },

    openDialogCliente(mode, item) {
      this.clienteDialog = {
        id: item ? item.id : null,
        showDialog: true,
        mode: mode
      };
    },

    saveCliente(cliente) {
      this.assinatura.titular = cliente;
      this.clienteDialog.showDialog = false;
    }

  }
};
</script>

<style scoped>
.container {
  min-height: 30rem;
}

.fa-menu-icon {
  font-size: 1.2em;
  margin-right: 10px;
}

.custom-card {
  border-left: 2px solid black;
  padding: 10px;
}

.autocomplete-input {
  width: 100%;
  height: 60px;
}

.div-clearable {
  margin-left: -50px;
  margin-top: 18px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
