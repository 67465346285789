<template>
  <v-dialog v-model="appLoading" persistent width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="secondary"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    appLoading: Boolean
  }
};
</script>

<style></style>
